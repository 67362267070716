import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { getKeyDocumentRequest } from 'api/SignatureAPI';

import {
  getKeyDocumentSuccess,
  getKeyDocumentFailed,
  getKeyDocument,
  saveUserToken
} from './actions';
import { getDocumentDataToVerify } from 'redux/activeDocument/actions';
import { getDocumentListToSelect } from 'redux/documentList/actions';
import { showMessage, showNotification } from 'redux/messages/actions';
import { getDocumentData, resetApp } from 'redux/app/actions';
import { getUserDataToVerify } from 'redux/verifyUser/actions';

import {
  selectAgreementsVerified,
  selectDataResumeVerified
} from 'redux/agreements/selectors';
import { selectCurrentToken, selectUserToken } from './selectors';
import { selectEntryWithToken } from 'redux/app/selectors';

import {
  GET_KEY_DOCUMENT_REQUEST,
  GET_KEY_DOCUMENT_FAILED,
  SAVE_CURRENT_TOKEN
} from './constants';
import { ROUTES } from 'consts/routes';
import {
  FAILED_NOTIFICATION_MSG_DESCRIPTION_BY_DEFAULT,
  FAILED_NOTIFICATION_MSG_TITLE_BY_DEFAULT
} from 'redux/messages/constants';

function* getKeyDocumentSaga() {
  yield takeLatest(GET_KEY_DOCUMENT_REQUEST, function* () {
    const agreementsVerified = yield select(selectAgreementsVerified);
    const dataResumeVerified = yield select(selectDataResumeVerified);

    try {
      const response = yield call(getKeyDocumentRequest);
      // yield delay(30000);

      if (!response.isSuccess) {
        yield put(getKeyDocumentFailed('No token'));
        return;
      }

      const data = response.data;

      yield put(getKeyDocumentSuccess(data));

      if (data.signatoryTypeId) {
        // If have signatory id, but is not verified, then go to verify user
        if (!data.isSignatoryVerified) {
          yield put(getUserDataToVerify(data.signatoryTypeId));
          return;
        }

        // If have signatory id and is verified, then save entry currentToken as userToken too
        else {
          const currentToken = yield select(selectCurrentToken);
          // const userToken = yield select(selectUserToken);

          if (!data.isDocumentVerified) {
            yield put(saveUserToken(currentToken));
          }
        }
      }

      if (!data.id) {
        // If no document id present, get documents to select
        yield put(getDocumentListToSelect({ init: true }));
        return;
      }

      if (!data.existDocument) {
        // If document does not exist, then show error message to the user
        const payload = {
          title: 'Document cannot be signed.',
          message: 'Please contact us.',
          status: 'error',
          retryOption: true
        };
        yield put(showMessage(payload));

        return;
      }

      if (!data.isDocumentVerified) {
        const entryWithToken = yield select(selectEntryWithToken);

        // If the document is not verified, then go to verify it
        yield put(
          getDocumentDataToVerify({
            documentId: data.id,
            documentType: data.typeId
          })
        );

        if (entryWithToken) {
          yield put(replace(ROUTES.VERIFY_DOCUMENT));
        }
        return;
      }

      yield put(getDocumentData({ loadDocuments: false }));

      if (!dataResumeVerified || !agreementsVerified) {
        // if the user has not signed the agreements yet, show them
        yield put(replace(ROUTES.AGREEMENTS));
        return;
      }
      yield put(replace(ROUTES.MAIN));
    } catch (error) {
      yield put(getKeyDocumentFailed(error));
    }
  });
}

function* getKeyDocumentFailedSaga() {
  yield takeLatest(GET_KEY_DOCUMENT_FAILED, function* ({ error }) {
    const isEntryWithToken = yield select(selectEntryWithToken);

    let notification = {
      title: FAILED_NOTIFICATION_MSG_TITLE_BY_DEFAULT,
      message: FAILED_NOTIFICATION_MSG_DESCRIPTION_BY_DEFAULT,
      status: 'error',
      retryOption: true
    };

    if (error.isNotAuthorized) {
      notification = {
        message: "We're sorry, you are not authorized to access this resource."
      };

      if (isEntryWithToken) {
        notification.message =
          "We're sorry, the link you followed has expired.";
        yield put(resetApp());
      }

      yield put(replace(ROUTES.HOME));
      yield put(showNotification(notification));
      return;
    }

    yield put(showMessage(notification));
  });
}

function* setBearerTokenSaga() {
  yield takeLatest(SAVE_CURRENT_TOKEN, function* ({ token }) {
    yield put(getKeyDocument(token));
  });
}

export default [
  getKeyDocumentSaga,
  getKeyDocumentFailedSaga,
  setBearerTokenSaga
];
