import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useIsMobile } from 'hooks/responsive';

import { selectDataInfo } from 'redux/app/selectors';

import SiderInfo from './SiderInfo';
import DrawerInfo from './DrawerInfo';

function LoanInfo() {
  const isMobile = useIsMobile();
  const dataInfo = useSelector(selectDataInfo);
  const Component = isMobile ? DrawerInfo : SiderInfo;

  if (!dataInfo) {
    return null;
  }

  return <Component />;
}
export default memo(LoanInfo);
