// Signer type constants
export const SIGNER_TYPE_NAME = 1;
export const SIGNER_TYPE_PAD = 2;
export const SIGNER_TYPE_IMAGE = 3;

export const SIGN_TEXT = 'Sign';
export const SIGN_DOCUMENT_TEXT = 'Sign documents';

// Signer steps constants
export const SIGNER_STEP_AGREEMENT = 0;
export const SIGNER_STEP_SIGN = 1;

// By Name Form constants
export const BY_NAME_STYLE_CLASSIC = 'classic';
export const ID_CLASSIC = 1;
export const BY_NAME_STYLE_ELEGANT = 'elegant';
export const ID_ELEGANT = 2;
export const BY_NAME_STYLE_FORMAL = 'formal';
export const ID_FORMAL = 3;

export const BY_NAME_STYLES_PER_ID = {
  [ID_CLASSIC]: BY_NAME_STYLE_CLASSIC,
  [ID_ELEGANT]: BY_NAME_STYLE_ELEGANT,
  [ID_FORMAL]: BY_NAME_STYLE_FORMAL,
};

// Action constants
export const MOVE_TO_SIGN_STEP = 'esign/DocumentSigner/MOVE_TO_SIGN_STEP';
export const ON_CHECK_AGREEMENTS = 'esign/DocumentSigner/ON_CHECK_AGREEMENTS';
export const ACCEPT_SIGNATURE = 'esign/DocumentSigner/ACCEPT_SIGNATURE';
export const ACTIVE_SIGNER_METHOD_TAB =
  'esign/DocumentSigner/ACTIVE_SIGNER_METHOD_TAB';
