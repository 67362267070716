import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import styled from 'styled-components';

import Centered from 'components/layouts/Centered';
import Spin from 'components/common/Spin';
import TwoColumns from 'components/common/Content/TwoColumns';
import { BackToHomeButton } from '../Main/utils';

import {
  selectIsFetchingUserDataToVerify,
  selectIsUserLogged
} from 'redux/verifyUser/selectors';

import UserTypeList from './UserTypeList';
import homeImg from 'images/background/home.jpg';
import { ROUTES } from 'consts/routes';

const HomeLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

function ClientSelection() {
  const dispatch = useDispatch();
  const isFetchingUserDataToVerify = useSelector(
    selectIsFetchingUserDataToVerify
  );

  const isUserLogged = useSelector(selectIsUserLogged);

  useEffect(() => {
    if (isUserLogged) {
      dispatch(replace(ROUTES.HOME));
    }
  }, [isUserLogged]);

  return (
    <Centered>
      <TwoColumns
        imageSrc={homeImg}
        imageDirection="left"
        md={14}
        actions={<BackToHomeButton text="Back" type="secondary" />}
      >
        <HomeLayout>
          <Spin
            wrapperClassName="dynamic-sized"
            spinning={isFetchingUserDataToVerify}
          >
            <UserTypeList />
          </Spin>
        </HomeLayout>
      </TwoColumns>
    </Centered>
  );
}

export default memo(ClientSelection);
