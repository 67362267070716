import React from 'react';
import styled from 'styled-components';

import { BORDER_RADIUS } from 'theme/variables';

const DivWithFullBackground = styled.div`
  width: 100%;
  height: 100%;
  background: url(${props => props.backgroundSrc}) no-repeat;
  background-size: cover;
  border-radius: ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0;
  background-position: ${props =>
    props.backgroundDirection ? props.backgroundDirection : 'left'};
`;

function Background({ backgroundSrc, backgroundDirection, children }) {
  return (
    <DivWithFullBackground
      backgroundSrc={backgroundSrc}
      backgroundDirection={backgroundDirection}
    >
      {children}
    </DivWithFullBackground>
  );
}

export default Background;
