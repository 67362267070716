import { selectIsFetchingDocumentDataToVerify, selectIsFetchingPutDocumentDataToVerify } from 'redux/activeDocument/selectors';
import {
  selectIsLoadingData,
  selectIsLoadingDocumentsContent
} from 'redux/app/selectors';
import { selectIsFetchingDocumentListToSelect } from 'redux/documentList/selectors';
import {
  selectIsFetchingLoanDataToVerify,
  selectIsFetchingPutProvidedLoanWithDataToVerify,
  selectIsFetchingPutUserDataToVerify,
  selectIsFetchingUserDataToVerify
} from 'redux/verifyUser/selectors';
import { createSelector } from 'reselect';

const selectMessagesDomain = () => state => state.get('messages');
const selectMessages = createSelector(selectMessagesDomain(), tk => tk.toJS());

const selectShowMessage = createSelector(
  selectMessages,
  message => message.showMessage
);

const selectShowNotification = createSelector(
  selectMessages,
  message => message.showNotification
);

const selectRetryFailuresFetching = createSelector(
  selectIsLoadingData,
  selectIsLoadingDocumentsContent,
  selectIsFetchingUserDataToVerify,
  selectIsFetchingPutUserDataToVerify,
  selectIsFetchingLoanDataToVerify,
  selectIsFetchingPutProvidedLoanWithDataToVerify,
  selectIsFetchingDocumentListToSelect,
  selectIsFetchingDocumentDataToVerify,
  selectIsFetchingPutDocumentDataToVerify,
  (
    isLoadingData,
    isLoadingDocumentsContent,
    isFetchingUserDataToVerify,
    isFetchingPutUserDataToVerify,
    isFetchingLoanDataToVerify,
    isFetchingPutProvidedLoanWithDataToVerify,
    isFetchingDocumentListToSelect,
    isFetchingDocumentDataToVerify,
    isFetchingPutDocumentDataToVerify
  ) =>
    isLoadingData ||
    isLoadingDocumentsContent ||
    isFetchingUserDataToVerify ||
    isFetchingPutUserDataToVerify ||
    isFetchingLoanDataToVerify ||
    isFetchingPutProvidedLoanWithDataToVerify ||
    isFetchingDocumentListToSelect ||
    isFetchingDocumentDataToVerify ||
    isFetchingPutDocumentDataToVerify
);

export default selectMessages;
export {
  selectShowMessage,
  selectShowNotification,
  selectRetryFailuresFetching
};
