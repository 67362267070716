import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';

import { Avatar } from 'antd';
import { Button, SelectFocusList } from 'components/common/Buttons';
import List, { ListItem } from 'components/common/List';
import { UserListContainer } from 'components/common/Content';
import { SubTitle } from 'components/common/Text';

import {
  getUserDataToVerify,
  getUserTypes,
  setUserTypeSelected
} from 'redux/verifyUser/actions';

import {
  selectIsfetchingUserTypesData,
  selectUserTypesData
} from 'redux/verifyUser/selectors';

const CHOOSE_USER_TEXT =
  'In order to access your pending documents to be signed, select the type of client that you are with our company.';
const RETRY_TEXT =
  'A problem has occurred obtaining the types of users in which you can access our system. To retry this operation press the following button.';

function UserCards({ userTypesData, handleSelectUserTypeClick }) {
  return (
    <List
      itemLayout="horizontal"
      dataSource={userTypesData}
      renderItem={user => (
        <ListItem
          onClick={handleSelectUserTypeClick}
          params={{ userId: user.id, userName: user.name }}
        >
          <List.Item.Meta
            avatar={<Avatar shape="square" icon={<UserOutlined />} />}
            title={user.name}
            description={<SubTitle>{user.description}</SubTitle>}
          />
        </ListItem>
      )}
    />
  );
}

function UserSelection({ handleToggle }) {
  const dispatch = useDispatch();

  const userTypesData = useSelector(selectUserTypesData);
  const isfetchingUserTypesData = useSelector(selectIsfetchingUserTypesData);

  const handleSelectUserTypeClick = (e, userSelected) => {
    dispatch(setUserTypeSelected(userSelected.userId));
    dispatch(getUserDataToVerify(userSelected.userId));
  };

  const handleRetryGetUserTypes = e => {
    dispatch(getUserTypes());
  };

  return (
    <UserListContainer>
      {userTypesData ? <SubTitle>{CHOOSE_USER_TEXT}</SubTitle> : RETRY_TEXT}

      <div style={{ marginTop: '20px' }}>
        {userTypesData ? (
          <UserCards
            userTypesData={userTypesData}
            handleSelectUserTypeClick={handleSelectUserTypeClick}
          />
        ) : (
          <Button
            type="primary"
            onClick={handleRetryGetUserTypes}
            loading={isfetchingUserTypesData}
          >
            Get users types
          </Button>
        )}
      </div>
    </UserListContainer>
  );
}

export default memo(UserSelection);
