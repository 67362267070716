export const NO_EFFECTS = [];
export const PROP_KEYS = [
  'autoFocus',
  'length',
  'validate',
  'format',
  'formatAriaLabel',
  'debug'
];
export const HANDLER_KEYS = [
  'onResolveKey',
  'onRejectKey',
  'onChange',
  'onComplete'
];
export const IGNORED_META_KEYS = [
  'Alt',
  'Control',
  'Enter',
  'Meta',
  'Shift',
  'Tab'
];

export const DISABLE_CHAR_IN_MASK = 'X';
export const TEXT_CHAR_IN_MASK = 'A';
export const NUMBER_CHAR_IN_MASK = '0';
export const NO_SEPARATORS_CHAR_MASK = [
  DISABLE_CHAR_IN_MASK,
  TEXT_CHAR_IN_MASK,
  NUMBER_CHAR_IN_MASK
];
