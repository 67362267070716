import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import documentsImg from 'images/background/documents.jpg';
import isEmpty from 'lodash/isEmpty';

import Spin from 'components/common/Spin';
import { LogOutUserButton } from 'components/views/Main/utils';
import MessageDetails from 'components/views/Messages/MessageDetails';
import TwoColumns from 'components/common/Content/TwoColumns';

import { putDocumentDataToVerify } from 'redux/activeDocument/actions';

import {
  selectDocumentDataToVerifyError,
  selectDocumentDataToVerifyInOrder,
  selectIsFetchingDocumentDataToVerify,
  selectIsFetchingPutDocumentDataToVerify
} from 'redux/activeDocument/selectors';
import {
  VERIFYING_ERROR_DESCRIPTION,
  VERIFYING_ERROR_MSG
} from 'redux/app/constants';
import VerifyDataForm from '../VerifyDataForm';

function VerifyDocument() {
  const dispatch = useDispatch();

  const documentDataToVerifyInOrder = useSelector(
    selectDocumentDataToVerifyInOrder
  );

  const documentDataToVerifyWithError = useSelector(
    selectDocumentDataToVerifyError
  );
  const isFetchingDocumentDataToVerify = useSelector(
    selectIsFetchingDocumentDataToVerify
  );
  const isFetchingPutDocumentDataToVerify = useSelector(
    selectIsFetchingPutDocumentDataToVerify
  );

  const handleSubmitDataToVerify = useCallback(values => {
    dispatch(putDocumentDataToVerify(values));
  }, []);

  const isFetching =
    isFetchingDocumentDataToVerify || isFetchingPutDocumentDataToVerify;

  let imageSetting = {
    source: documentsImg,
    position: '30% 30%'
  };

  const renderTitle = () => {
    if (isFetching) {
      return 'Verifying document...';
    }

    if (!isEmpty(documentDataToVerifyInOrder)) {
      return 'Certain information in the document needs to be verified.';
    }

    return null;
  };

  const renderActions = () => {
    if (isFetching) {
      return false;
    }

    if (!isEmpty(documentDataToVerifyInOrder)) {
      return true;
    }

    return <LogOutUserButton />;
  };

  const renderContent = () => {
    if (documentDataToVerifyWithError) {
      return (
        <MessageDetails
          status="error"
          title={VERIFYING_ERROR_MSG}
          subTitle={VERIFYING_ERROR_DESCRIPTION}
        />
      );
    }

    if (!isEmpty(documentDataToVerifyInOrder)) {
      return (
        <VerifyDataForm
          data={documentDataToVerifyInOrder}
          checkDataFunction={handleSubmitDataToVerify}
          loading={isFetchingPutDocumentDataToVerify}
        />
      );
    }

    return <Spin spinning />;
  };

  return (
    <TwoColumns
      md={18}
      title={renderTitle()}
      imageSrc={imageSetting.source}
      imageDirection={imageSetting.position}
      actions={renderActions()}
    >
      {renderContent()}
    </TwoColumns>
  );
}

export default memo(VerifyDocument);
