import { makeActionCreator } from 'redux/utils';

import {
  SET_AGREEMENTS_VERIFIED,
  SET_DATA_RESUME_VERIFIED,
  READED_CONSENT_STATUS,
  GET_RESOURCE,
  GET_RESOURCE_SUCCESS,
  GET_RESOURCE_FAILED
} from './constants';

export const setAgreementsVerified = makeActionCreator(
  SET_AGREEMENTS_VERIFIED,
  'payload'
);

export const setDataResumeVerified = makeActionCreator(
  SET_DATA_RESUME_VERIFIED
);

export const setConsentReadStatus = makeActionCreator(
  READED_CONSENT_STATUS,
  'payload'
);

export const getResource = makeActionCreator(GET_RESOURCE, 'resourceName');
export const getResourceSuccess = makeActionCreator(
  GET_RESOURCE_SUCCESS,
  'payload'
);
export const getResourceFailed = makeActionCreator(
  GET_RESOURCE_FAILED,
  'error'
);
