import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  RESET_APP,
  GET_DOCUMENT_DATA_REQUEST,
  GET_DOCUMENT_DATA_SUCCESS,
  GET_DOCUMENT_DATA_FAILED,
  GET_DOCUMENT_CONTENT_REQUEST,
  GET_DOCUMENT_CONTENT_SUCCESS,
  GET_DOCUMENT_CONTENT_FAILED,
  SELECT_NEXT_DOCUMENT,
  SELECT_PREV_DOCUMENT,
  SIGN_DOCUMENT_REQUEST,
  SIGN_DOCUMENT_SUCCESS,
  SIGN_DOCUMENT_FAILED,
  SHOW_SIGNER_INFORMATION_SIDER,
  GET_DOCUMENTS_CONTENT_FAILED,
  GET_DOCUMENTS_CONTENT_FINISHED,
  GET_DOCUMENTS_CONTENT,
  LOGOUT_USER,
  TOGGLE_SHOW_SIGNED_DOCUMENTS_AFTER_SIGN,
  RESET_JUST_SIGNED,
  ENTRY_WITH_TOKEN,
  ACCEPT_DOCUMENTS_TO_SIGN,
  MOBILE_ALERT_MESSAGE_SHOWN,
  GET_DOCUMENT_CONTENT_READED,
  TOGGLE_SELECT_DOCUMENTS_CONTAINER
} from './constants';
import { MOVE_TO_SIGN_STEP, ACCEPT_SIGNATURE } from 'redux/signer/constants';
import {
  GET_KEY_DOCUMENT_FAILED,
  GET_KEY_DOCUMENT_REQUEST,
  GET_KEY_DOCUMENT_SUCCESS
} from 'redux/tokens/constants';
import {
  PUT_USER_DATA_TO_VERIFY_SUCCESS,
  PUT_USER_DATA_TO_VERIFY_REQUEST,
  PUT_USER_DATA_TO_VERIFY_FAILED,
  GET_USER_DATA_TO_VERIFY_REQUEST,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_FAILED,
  GET_LOAN_DATA_TO_VERIFY_REQUEST,
  SET_USER_TYPE_SELECTED
} from 'redux/verifyUser/constants';
import {
  PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS,
  PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST,
  PUT_DOCUMENT_DATA_TO_VERIFY_FAILED,
  RESET_DOCUMENT_ID_SELECTED
} from 'redux/activeDocument/constants';
import {
  GET_RESOURCE,
  GET_RESOURCE_FAILED,
  GET_RESOURCE_SUCCESS
} from 'redux/agreements/constants';

const documentDataReducer = (state = null, action) => {
  switch (action.type) {
    case GET_DOCUMENT_DATA_SUCCESS:
      return fromJS(action.payload);
    case GET_DOCUMENT_CONTENT_REQUEST:
      return state.setIn(
        ['documents', action.payload.index, 'isLoading'],
        true
      );
    case GET_DOCUMENT_CONTENT_SUCCESS:
      return state.setIn(
        ['documents', action.payload.index, 'isLoading'],
        false
      );
    case GET_DOCUMENT_CONTENT_READED:
      return state.setIn(['documents', action.payload.index, 'readed'], true);
    case GET_DOCUMENT_CONTENT_FAILED:
      return state
        .setIn(['documents', action.error.index, 'isLoading'], false)
        .setIn(['documents', action.error.index, 'hasError'], true);
    case GET_DOCUMENT_DATA_REQUEST:
    case GET_USER_DATA_TO_VERIFY_REQUEST:
    case GET_KEY_DOCUMENT_REQUEST:
    case RESET_DOCUMENT_ID_SELECTED:
    case RESET_APP:
    case LOGOUT_USER:
      return null;
    default:
      return state;
  }
};

const isLoadingDocumentsContentReducer = (state = false, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_CONTENT:
      return true;
    case GET_DOCUMENT_DATA_REQUEST:
    case GET_DOCUMENTS_CONTENT_FAILED:
    case GET_DOCUMENTS_CONTENT_FINISHED:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const isLoadingDataReducer = (state = false, action) => {
  switch (action.type) {
    case GET_DOCUMENT_DATA_REQUEST:
      return true;
    case GET_DOCUMENT_DATA_SUCCESS:
    case GET_DOCUMENT_DATA_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      // case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const activeDocumentIndexReducer = (state = 0, action) => {
  switch (action.type) {
    case SELECT_NEXT_DOCUMENT:
      return state + 1;
    case SELECT_PREV_DOCUMENT:
      return state - 1;
    case LOGOUT_USER:
    case RESET_APP:
      return 0;
    default:
      return state;
  }
};

const currentStepReducer = (state = 0, action) => {
  switch (action.type) {
    case MOVE_TO_SIGN_STEP:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return 0;
    default:
      return state;
  }
};

const signatureDataReducer = (state = null, action) => {
  switch (action.type) {
    case ACCEPT_SIGNATURE:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
};

const verifiedUserDataReducer = (state = null, action) => {
  switch (action.type) {
    case PUT_USER_DATA_TO_VERIFY_SUCCESS:
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS:
      return action.payload;
    case PUT_USER_DATA_TO_VERIFY_REQUEST:
    case PUT_USER_DATA_TO_VERIFY_FAILED:
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST:
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
    case LOGOUT_USER:
      return null;
    default:
      return state;
  }
};

const verifiedDocumentDataReducer = (state = null, action) => {
  switch (action.type) {
    case PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS:
      return action.payload;
    case PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST:
    case PUT_DOCUMENT_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const isSigningLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case SIGN_DOCUMENT_REQUEST:
      return true;
    case SIGN_DOCUMENT_SUCCESS:
    case SIGN_DOCUMENT_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const viewSignerInformationSiderReducer = (state = false, action) => {
  switch (action.type) {
    case SHOW_SIGNER_INFORMATION_SIDER:
      return action.payload;
    case GET_KEY_DOCUMENT_REQUEST:
    case RESET_DOCUMENT_ID_SELECTED:
    case LOGOUT_USER:
    case RESET_APP:
    case LOGOUT_USER:
      return false;
    default:
      return state;
  }
};

const showSignedDocumentsAfterSignReducer = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_SHOW_SIGNED_DOCUMENTS_AFTER_SIGN:
      return !state;
    case GET_KEY_DOCUMENT_REQUEST:
    case RESET_DOCUMENT_ID_SELECTED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const justSignedReducer = (state = false, action) => {
  switch (action.type) {
    case SIGN_DOCUMENT_SUCCESS:
      return true;
    case GET_KEY_DOCUMENT_REQUEST:
    case RESET_DOCUMENT_ID_SELECTED:
    case RESET_JUST_SIGNED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const entryWithTokenReducer = (state = false, action) => {
  switch (action.type) {
    case ENTRY_WITH_TOKEN:
      return true;
    case GET_LOAN_DATA_TO_VERIFY_REQUEST:
    case SET_USER_TYPE_SELECTED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const isFetchingGettingKeyReducer = (state = false, action) => {
  switch (action.type) {
    case GET_KEY_DOCUMENT_REQUEST:
      return true;
    case GET_KEY_DOCUMENT_SUCCESS:
    case GET_KEY_DOCUMENT_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const documentsToSignAcceptedReducer = (state = false, action) => {
  switch (action.type) {
    case ACCEPT_DOCUMENTS_TO_SIGN:
      return action.payload;
    case GET_KEY_DOCUMENT_SUCCESS:
    case GET_KEY_DOCUMENT_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const mobileAlertMessageShownReducer = (state = false, action) => {
  switch (action.type) {
    case MOBILE_ALERT_MESSAGE_SHOWN:
      return true;
    case GET_KEY_DOCUMENT_SUCCESS:
    case GET_KEY_DOCUMENT_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const getResourceIsFetchingReducer = (state = false, action) => {
  switch (action.type) {
    case GET_RESOURCE:
      return true;
    case GET_RESOURCE_SUCCESS:
    case GET_RESOURCE_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const chooseDocumentsVisibilityReducer = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_SELECT_DOCUMENTS_CONTAINER:
      return action.payload;
    case LOCATION_CHANGE:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  documentData: documentDataReducer,
  isLoadingData: isLoadingDataReducer,
  activeDocumentIndex: activeDocumentIndexReducer,
  currentStep: currentStepReducer,
  signatureData: signatureDataReducer,
  verifiedUserData: verifiedUserDataReducer,
  verifiedDocumentData: verifiedDocumentDataReducer,
  isSigningLoading: isSigningLoadingReducer,
  viewSignerInformationSider: viewSignerInformationSiderReducer,
  isLoadingDocumentsContent: isLoadingDocumentsContentReducer,
  showSignedDocumentsAfterSign: showSignedDocumentsAfterSignReducer,
  justSigned: justSignedReducer,
  entryWithToken: entryWithTokenReducer,
  isFetchingGettingKey: isFetchingGettingKeyReducer,
  documentsToSignAccepted: documentsToSignAcceptedReducer,
  mobileAlertMessageShown: mobileAlertMessageShownReducer,
  getResourceIsFetching: getResourceIsFetchingReducer,
  chooseDocumentsVisibility: chooseDocumentsVisibilityReducer
});
