export const SET_DOCUMENT_SELECTED =
  'esign/Verify/Document/SET_DOCUMENT_SELECTED';

export const GET_DOCUMENT_DATA_TO_VERIFY_REQUEST =
  'esign/Verify/Document/GET_DOCUMENT_DATA_TO_VERIFY_REQUEST';
export const GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS =
  'esign/Verify/Document/GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS';
export const GET_DOCUMENT_DATA_TO_VERIFY_FAILED =
  'esign/Verify/Document/GET_DOCUMENT_DATA_TO_VERIFY_FAILED';

export const PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST =
  'esign/Verify/Document/PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST';
export const PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS =
  'esign/Verify/Document/PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS';
export const PUT_DOCUMENT_DATA_TO_VERIFY_FAILED =
  'esign/Verify/Document/PUT_DOCUMENT_DATA_TO_VERIFY_FAILED';

export const RESET_DOCUMENT_ID_SELECTED =
  'esign/Verify/Document/RESET_DOCUMENT_ID_SELECTED';
