import { takeLatest, put } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import { SAVE_AND_SHOW_MESSAGE_PAGE } from './constants';
import { ROUTES } from 'consts/routes';

function* showMessagePageSaga() {
  yield takeLatest(SAVE_AND_SHOW_MESSAGE_PAGE, function*() {
    yield put(replace(ROUTES.MESSAGE));
  });
}

export default [showMessagePageSaga];
