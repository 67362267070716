export const USER_AUTH_ERROR_TYPE_CODES = [1, 4];

export const INPUT_TEXT_TYPE_NOMENCLATURE = 1;
export const SECURITY_NUMBER_TYPE_NOMENCLATURE = 2;
export const FEIN_TYPE_NOMENCLATURE = 3;
export const RANDOM_SSN_TYPE_NOMENCLATURE = 4;
export const CHECK_TYPE_NOMENCLATURE = 6;
export const DECIMAL_TYPE_NOMENCLATURE = 7;
export const GROUP_TYPE_NOMENCLATURE = 8;
export const SIMPLE_CHOICE_AS_SELECT_TYPE_NOMENCLATURE = 9;
export const SIMPLE_CHOICE_AS_RADIO_TYPE_NOMENCLATURE = 10;

export const SIMPLE_CHOICE_GROUP = [
  SIMPLE_CHOICE_AS_SELECT_TYPE_NOMENCLATURE,
  SIMPLE_CHOICE_AS_RADIO_TYPE_NOMENCLATURE
];

export const TOTAL_VERIFICATION_DATA_ATTEMPTS = 3;

// Loan Number
export const GET_LOAN_DATA_TO_VERIFY_REQUEST =
  'esign/Verify/User/GET_LOAN_DATA_TO_VERIFY_REQUEST';
export const GET_LOAN_DATA_TO_VERIFY_SUCCESS =
  'esign/Verify/User/GET_LOAN_DATA_TO_VERIFY_SUCCESS';
export const GET_LOAN_DATA_TO_VERIFY_FAILED =
  'esign/Verify/User/GET_LOAN_DATA_TO_VERIFY_FAILED';
export const SAVE_PROVIDED_LOAN = 'esign/Verify/User/SAVE_PROVIDED_LOAN';

// User types
export const USER_TYPE_BORROWER = '18';
export const USER_TYPE_COBORROWER = '28';
export const USER_TYPE_DEALER = '1';
export const USER_TYPE_COMPANY = '22';

export const USER_TYPE_LABELS = {
  [USER_TYPE_BORROWER]: 'Borrower',
  [USER_TYPE_DEALER]: 'Dealer'
};

export const GET_USER_TYPES_REQUEST =
  'esign/Verify/User/GET_USER_TYPES_REQUEST';
export const GET_USER_TYPES_SUCCESS =
  'esign/Verify/User/GET_USER_TYPES_SUCCESS';
export const GET_USER_TYPES_FAILED = 'esign/Verify/User/GET_USER_TYPES_FAILED';

export const GET_USER_DATA_TO_VERIFY_REQUEST =
  'esign/Verify/User/GET_USER_DATA_TO_VERIFY_REQUEST';
export const GET_USER_DATA_TO_VERIFY_SUCCESS =
  'esign/Verify/User/GET_USER_DATA_TO_VERIFY_SUCCESS';
export const GET_USER_DATA_TO_VERIFY_FAILED =
  'esign/Verify/User/GET_USER_DATA_TO_VERIFY_FAILED';

export const PUT_USER_DATA_TO_VERIFY_REQUEST =
  'esign/Verify/User/PUT_USER_DATA_TO_VERIFY_REQUEST';
export const PUT_USER_DATA_TO_VERIFY_SUCCESS =
  'esign/Verify/User/PUT_USER_DATA_TO_VERIFY_SUCCESS';
export const PUT_USER_DATA_TO_VERIFY_FAILED =
  'esign/Verify/User/PUT_USER_DATA_TO_VERIFY_FAILED';

export const PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST =
  'esign/Verify/User/PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST';
export const PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS =
  'esign/Verify/User/PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS';
export const PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_FAILED =
  'esign/Verify/User/PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_FAILED';

export const GET_VERIFY_DATA_REQUEST =
  'esign/Verify/User/GET_VERIFY_DATA_REQUEST';
export const GET_VERIFY_DATA_SUCCESS =
  'esign/Verify/User/GET_VERIFY_DATA_SUCCESS';
export const GET_VERIFY_DATA_FAILED =
  'esign/Verify/User/GET_VERIFY_DATA_FAILED';

export const SET_USER_TYPE_SELECTED =
  'esign/Verify/User/SET_USER_TYPE_SELECTED';

export const PUT_VERIFY_DATA_REQUEST =
  'esign/Verify/User/PUT_VERIFY_DATA_REQUEST';
export const PUT_VERIFY_DATA_SUCCESS =
  'esign/Verify/User/PUT_VERIFY_DATA_SUCCESS';
export const PUT_VERIFY_DATA_FAILED =
  'esign/Verify/User/PUT_VERIFY_DATA_FAILED';

export const VERIFICATION_DATA_ATTEMPTS_COMPLETED =
  'esign/Verify/User/VERIFICATION_DATA_ATTEMPTS_COMPLETED';
