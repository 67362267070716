import React from 'react';
import styled from 'styled-components';

import { Spin as SpinAntd } from 'antd';
import { FONT_COLOR_GRAY } from 'theme/variables';

const Fill = styled.div`
  width: 100%;
  min-height: 320px;
  height: 100%;
`;

const Spin = styled(SpinAntd)`
  &.ant-spin {
    height: auto;

    &.compact {
      min-height: 0;
    }

    .ant-spin-text {
      color: ${FONT_COLOR_GRAY};
    }
  }

  .ant-spin-dot {
    font-size: 26px;
    .ant-spin-dot-item {
      border-radius: 0;
    }
  }

  .ant-spin-nested-loading {
    height: auto;

    .ant-spin-container {
      height: auto;
    }
  }
`;

const CustomSpin = ({ spinning, tip, children, ...props }) => {
  return (
    <Spin size="large" spinning={spinning} tip={tip} {...props}>
      {spinning ? children ? children : <Fill /> : children}
    </Spin>
  );
};

export default CustomSpin;
