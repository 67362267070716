import { useMediaQuery } from 'react-responsive';
import { RESPONSIVE_BREAKPOINTS, XXS, SM, MD, LG, XL, XXL } from 'theme/variables';

const OFFSET_MAX_WIDTH = 0.02;
export const useIsExtraSmallDevice = () =>
  useMediaQuery({
    maxDeviceWidth: RESPONSIVE_BREAKPOINTS[XXS] - OFFSET_MAX_WIDTH
  });
  
  export const useIsVerySmallDevice = () =>
  useMediaQuery({
    maxDeviceWidth: RESPONSIVE_BREAKPOINTS[SM] - OFFSET_MAX_WIDTH
  });

export const useIsMobile = () =>
  useMediaQuery({
    maxDeviceWidth: RESPONSIVE_BREAKPOINTS[MD] - OFFSET_MAX_WIDTH
  });
export const useIsTablet = () =>
  useMediaQuery({
    minDeviceWidth: RESPONSIVE_BREAKPOINTS[MD]
  });
export const useIsDesktop = () =>
  useMediaQuery({
    minDeviceWidth: RESPONSIVE_BREAKPOINTS[LG]
  });
export const useIsExtraLargeDesktop = () =>
  useMediaQuery({
    minDeviceWidth: RESPONSIVE_BREAKPOINTS[XL]
  });

export const useIsVeryLargeDesktop = () =>
  useMediaQuery({
    minDeviceWidth: RESPONSIVE_BREAKPOINTS[XXL]
  });
export const useIsPortrait = () => useMediaQuery({ orientation: 'portrait' });
export const useIsLandscape = () => useMediaQuery({ orientation: 'landscape' });
