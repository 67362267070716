import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isBlank } from 'utils/functions';
import styled from 'styled-components';

import { Form, Input } from 'antd';
import ButtonLink, {
  Button,
  Button as ButtonAnt,
  Linked as LinkedAnt
} from 'components/common/Buttons';
import { FormErrorsMessage, FormItem } from 'components/common/Form';
import { Container } from 'components/common/Content';
import { SubTitle, Title } from 'components/common/Text';

import { getLoanDataToVerify, getUserTypes } from 'redux/verifyUser/actions';

import {
  selectIsFetchingLoanDataToVerify,
  selectIsfetchingUserTypesData,
  selectVerifyLoanErrors
} from 'redux/verifyUser/selectors';

import { ROUTES } from 'consts/routes';

const LinkedStyled = styled(LinkedAnt)`
  &:focus {
    outline: auto;
    outline-color: black;
  }
`;

const ButtonFocus = styled(ButtonAnt)`
  &:focus {
    outline: auto;
    outline-color: black;
  }
`;

function LoanNumberForm() {
  const [form] = Form.useForm();
  const loanInput = useRef();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);

  const isFetchingLoanDataToVerify = useSelector(
    selectIsFetchingLoanDataToVerify
  );
  const isfetchingUserTypesData = useSelector(selectIsfetchingUserTypesData);
  const hasError = useSelector(selectVerifyLoanErrors);

  useEffect(() => {
    if (loanInput.current) {
      loanInput.current.focus();
    }
  }, []);

  const handleGetUserTypes = () => {
    dispatch(getUserTypes());
  };

  const handleOnFinish = useCallback(values => {
    dispatch(getLoanDataToVerify(values.loanNumber));
  }, []);

  const handleOnChange = e => {
    setDisable(isBlank(form.getFieldValue('loanNumber')));
  };

  return (
    <>
      <div>
        <Title>Starting with your account</Title>
        <SubTitle>
          Please, enter your account number and we will show you the documents you
          have pending to sign.
        </SubTitle>
      </div>
      {hasError && <FormErrorsMessage description={hasError.description} />}
      <Form
        style={{ marginTop: '10px' }}
        layout="inline"
        form={form}
        scrollToFirstError
        onFinish={handleOnFinish}
      >
        <FormItem name="loanNumber">
          <Input
            autoComplete="off"
            disabled={isFetchingLoanDataToVerify}
            placeholder={'Provide an account number'}
            onChange={handleOnChange}
            ref={loanInput}
          />
        </FormItem>
        <FormItem>
          <ButtonFocus
            htmlType="submit"
            type="primary"
            loading={isFetchingLoanDataToVerify}
            disabled={disable}
          >
            Next
          </ButtonFocus>
        </FormItem>
      </Form>
      <SubTitle style={{ marginTop: '10px' }}>
        Other ways to access your documents.{' '}
        <ButtonLink
          onClick={handleGetUserTypes}
          loading={isfetchingUserTypesData}
        >
          Get started
        </ButtonLink>
        {/* <LinkedStyled to={ROUTES.CLIENT_SELECTION}>Get started</LinkedStyled>. */}
      </SubTitle>
    </>
  );
}

export default memo(LoanNumberForm);
