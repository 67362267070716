import { createSelector } from 'reselect';

const selectSignerDomain = () => state => state.get('signer');

const selectSigner = createSelector(selectSignerDomain(), signer =>
  signer.toJS()
);

const selectActiveSignerMethod = createSelector(selectSigner, signer => {
  return signer.activeSignerMethod.toString();
});

export default selectSigner;
export { selectActiveSignerMethod };
