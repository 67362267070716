import React from 'react';
import styled from 'styled-components';
import { Form as FormAnt } from 'antd';

import { ERROR_COLOR, FONT_COLOR_SOFT, FONT_SIZE_SMALL } from 'theme/variables';
import { WarningOutlined } from '@ant-design/icons';

export const Form = styled(FormAnt)``;

export const FormItem = styled(FormAnt.Item)`
  & .ant-form-item-extra {
    font-size: ${FONT_SIZE_SMALL};
    color: ${FONT_COLOR_SOFT};
    font-weight: normal;
    text-align: left;
    display: block;
    white-space: normal;
  }

  &.ant-form-item-has-error .ant-form-item-explain {
    font-size: ${FONT_SIZE_SMALL};
  }

  &.ant-form-item.items-group {
    margin-left: 10px;

    &:not(.last-one) {
      margin-bottom: 0;
    }
  }

  &.ant-form-item.group {
    margin-bottom: 0;

    .ant-form-item-label {
      padding: 0;
    }

    .ant-form-item-control-input {
      display: none;
    }
  }
`;

const FormErrorsMessageContainer = styled.div`
  color: ${ERROR_COLOR};
  margin-bottom: 10px;
  border-bottom: 1px solid ${ERROR_COLOR};
`;

export const FormErrorsMessage = ({ title = false, description }) => {
  return (
    <FormErrorsMessageContainer>
      <WarningOutlined style={{ color: ERROR_COLOR, marginRight: '10px' }} />
      <span>{description}</span>
    </FormErrorsMessageContainer>
  );
};
