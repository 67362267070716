export const APP_NAME = 'eSIGN';

export const RESET_APP = 'esign/Main/RESET_APP';

// Document signer constants
export const DOCUMENT_STATUS_PENDING = 1;
export const DOCUMENT_STATUS_SIGNED = 2;
export const DOCUMENT_STATUS_ERROR = 3;
export const DOCUMENT_STATUS_ERROR_MESSAGE =
  'Sorry, the document cannot be signed at this time.';

export const BACK_TO_HOME = 'Go back to home';

// Messages
export const VERIFYING_ERROR_MSG = 'Sorry, something has gone wrong.';
export const VERIFYING_ERROR_DESCRIPTION = 'Please, contact us.';

// Action constants

export const GET_DOCUMENTS_CONTENT = 'esign/Main/GET_DOCUMENTS_CONTENT';
export const GET_DOCUMENTS_CONTENT_FAILED =
  'esign/Main/GET_DOCUMENTS_CONTENT_FAILED';
export const GET_DOCUMENTS_CONTENT_FINISHED =
  'esign/Main/GET_DOCUMENTS_CONTENT_FINISHED';
export const GET_DOCUMENT_DATA_REQUEST = 'esign/Main/GET_DOCUMENT_DATA_REQUEST';
export const GET_DOCUMENT_DATA_SUCCESS = 'esign/Main/GET_DOCUMENT_DATA_SUCCESS';
export const GET_DOCUMENT_DATA_FAILED = 'esign/Main/GET_DOCUMENT_DATA_FAILED';

export const SELECT_NEXT_DOCUMENT = 'esign/Main/NEXT_DOCUMENT';
export const SELECT_PREV_DOCUMENT = 'esign/Main/PREVIOUS_DOCUMENT';

export const SIGN_DOCUMENT_REQUEST = 'esign/Main/SIGN_DOCUMENT_REQUEST';
export const SIGN_DOCUMENT_SUCCESS = 'esign/Main/SIGN_DOCUMENT_SUCCESS';
export const SIGN_DOCUMENT_FAILED = 'esign/Main/SIGN_DOCUMENT_FAILED';

export const TOGGLE_SHOW_SIGNED_DOCUMENTS_AFTER_SIGN =
  'esign/Main/TOGGLE_SHOW_SIGNED_DOCUMENTS_AFTER_SIGN';

export const APP_DOCUMENT_PDF_OPEN_NEW_WINDOWS_REQUEST =
  'esign/Main/DOCUMENT_PDF_OPEN_NEW_WINDOWS_REQUEST';
export const APP_DOCUMENT_PDF_NEW_WINDOW_OPENED =
  'esign/Main/DOCUMENT_PDF_NEW_WINDOW_OPENED';

export const SET_PREVIEW_PROPERTY = 'esign/Main/SET_PREVIEW_PROPERTY';
export const CLOSE_SIGNER = 'esign/Main/CLOSE_SIGNER';

export const APP_CLOSE_MODAL = 'esign/Main/CLOSE_MODAL';

export const GET_DOCUMENT_CONTENT_REQUEST =
  'esign/Main/GET_DOCUMENT_CONTENT_REQUEST';
export const GET_DOCUMENT_CONTENT_SUCCESS =
  'esign/Main/GET_DOCUMENT_CONTENT_SUCCESS';
export const GET_DOCUMENT_CONTENT_FAILED =
  'esign/Main/GET_DOCUMENT_CONTENT_FAILED';
export const GET_DOCUMENT_CONTENT_READED =
  'esign/Main/GET_DOCUMENT_CONTENT_READED';

export const SHOW_SIGNER_INFORMATION_SIDER =
  'esign/Main/SHOW_SIGNER_INFORMATION_SIDER';

export const LOGOUT_USER = 'esign/Main/LOGOUT_USER';
export const RESET_JUST_SIGNED = 'esign/Main/RESET_JUST_SIGNED';
export const ENTRY_WITH_TOKEN = 'esign/Main/ENTRY_WITH_TOKEN';
export const GO_TO_PREVIOUS_VERIFICATION_STEP =
  'esign/Main/GO_TO_PREVIOUS_VERIFICATION_STEP';

export const ACCEPT_DOCUMENTS_TO_SIGN = 'esign/Main/ACCEPT_DOCUMENTS_TO_SIGN';
export const MOBILE_ALERT_MESSAGE_SHOWN =
  'esign/Main/MOBILE_ALERT_MESSAGE_SHOWN';
export const SMART_ACTION_RUNNING = 'esign/Main/SMART_ACTION_RUNNING';

export const TOGGLE_SELECT_DOCUMENTS_CONTAINER =
  'esign/Main/TOGGLE_SELECT_DOCUMENTS_CONTAINER';
