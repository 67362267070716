export const RESOURCE_DISCLOSURE = 'disclosure';

export const SET_AGREEMENTS_VERIFIED =
  'esign/Agreements/SET_AGREEMENTS_VERIFIED';

export const SET_DATA_RESUME_VERIFIED =
  'esign/Agreements/SET_DATA_RESUME_VERIFIED';

export const READED_CONSENT_STATUS = 'esign/Agreements/READED_CONSENT_STATUS';

export const GET_RESOURCE = 'esign/Agreements/GET_RESOURCE';
export const GET_RESOURCE_SUCCESS = 'esign/Agreements/GET_RESOURCE_SUCCESS';
export const GET_RESOURCE_FAILED = 'esign/Agreements/GET_RESOURCE_FAILED';
