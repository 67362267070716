export function makeActionCreator(type, ...argNames) {
  return (...args) => {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
}

export function sleep(ms = 2000) {
  console.log('Kindly remember to remove `sleep`');
  return new Promise(resolve => setTimeout(resolve, ms));
}
