import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spin from 'components/common/Spin';
import TwoColumns from 'components/common/Content/TwoColumns';

import { putUserDataToVerify } from 'redux/verifyUser/actions';

import {
  selectUserDataToVerify,
  selectIsFetchingUserDataToVerify,
  selectIsFetchingPutUserDataToVerify
} from 'redux/verifyUser/selectors';
import {
  selectIsFetchingDocumentDataToVerify,
  selectIsFetchingPutDocumentDataToVerify
} from 'redux/activeDocument/selectors';

import MessageDetails from 'components/views/Messages/MessageDetails';

import { LogOutUserButton } from 'components/views/Main/utils';
import {
  VERIFYING_ERROR_DESCRIPTION,
  VERIFYING_ERROR_MSG
} from 'redux/app/constants';
import VerifyDataForm from '../VerifyDataForm';

function UserVerification({ imageSetting }) {
  const dispatch = useDispatch();
  const isFetchingUserDataToVerify = useSelector(
    selectIsFetchingUserDataToVerify
  );
  const userDataToVerify = useSelector(selectUserDataToVerify);

  const isFetchingPutUserDataToVerify = useSelector(
    selectIsFetchingPutUserDataToVerify
  );

  const isFetchingDocumentDataToVerify = useSelector(
    selectIsFetchingDocumentDataToVerify
  );

  const isFetchingPutDocumentDataToVerify = useSelector(
    selectIsFetchingPutDocumentDataToVerify
  );

  const isFetchingVerifyingDocument =
    isFetchingDocumentDataToVerify || isFetchingPutDocumentDataToVerify;

  const handleSubmitUserDataToVerify = useCallback(values => {
    dispatch(putUserDataToVerify(values));
  }, []);

  const renderTitle = () => {
    if (isFetchingUserDataToVerify) {
      return 'Verifying User...';
    }

    if (isFetchingVerifyingDocument) {
      return 'Verifying document...';
    }

    if (userDataToVerify) {
      return (
        <h4>
          <strong>
            Certain information needs to be validated. Please complete the
            following data.
          </strong>
        </h4>
      );
    }

    return null;
  };

  const renderActions = () => {
    if (isFetchingUserDataToVerify || isFetchingVerifyingDocument) {
      return false;
    }

    if (userDataToVerify) {
      return true;
    }

    return <LogOutUserButton />;
  };

  const renderContent = () => {
    if (isFetchingUserDataToVerify) {
      return <Spin spinning />;
    }

    if (isFetchingVerifyingDocument) {
      return null;
    }

    if (userDataToVerify) {
      return (
        <VerifyDataForm
          data={userDataToVerify}
          checkDataFunction={handleSubmitUserDataToVerify}
          loading={isFetchingPutUserDataToVerify}
        />
      );
    }

    return (
      <MessageDetails
        status="error"
        title={VERIFYING_ERROR_MSG}
        subTitle={VERIFYING_ERROR_DESCRIPTION}
      />
    );
  };

  return (
    <TwoColumns
      title={renderTitle()}
      md={18}
      imageSrc={imageSetting.source}
      imageDirection={imageSetting.position}
      actions={renderActions()}
    >
      {renderContent()}
    </TwoColumns>
  );
}

export default memo(UserVerification);
