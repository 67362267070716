import { createGlobalStyle } from 'styled-components';
import {
  FONT_BASE,
  FONT_WEIGHT,
  ERROR_COLOR,
  SUCCESS_COLOR,
  INFO_COLOR,
  FONT_SIZE_SMALL,
  ACTION_COLOR,
  WIDTH_BREAKPOINT_SM,
  FONT_BASE_XXL,
  FONT_BASE_X1,
  FONT_BASE_X2,
  BREAKPOINT_X1,
  BREAKPOINT_X2,
  BREAKPOINT_XXL,
  opacityColor
} from './variables';
import frutigerWoff2 from 'assets/fonts/FrutigerLTStd-BoldCn.woff2';
import frutigerWoff from 'assets/fonts/FrutigerLTStd-BoldCn.woff';
import frutigerTtf from 'assets/fonts/FrutigerLTStd-BoldCn.ttf';

/* eslint no-unused-expressions: 0 */
const GlobalStyles = createGlobalStyle`

@font-face {
    font-family: 'Frutiger Bold Cn';
    src: local('Frutiger Bold Cn'), local('Frutiger--Bold-Cn'),
        url(${frutigerWoff2}) format('woff2'),
        url(${frutigerWoff}) format('woff'),
        url(${frutigerTtf}) format('truetype');
    font-weight: ${FONT_WEIGHT};
    font-style: normal;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  .rpv-default-layout__container {
    border: none;
  }

  .rpv-default-layout__toolbar,
  .rpv-default-layout__body {
    background-color: transparent;
  }

  .rpv-full-screen__exit-button.rpv-full-screen__exit-button--ltr > .rpv-core__minimal-button {
    background: ${opacityColor(ACTION_COLOR, 0.8)} ;
    display: flex;
    align-items: center;
    border-radius: 20px;
    height: 40px;
    width: 40px;
    justify-content: center;
    margin: 20px;

    &:hover {
      background: ${opacityColor(ACTION_COLOR, 1)} ;
    }

    .rpv-core__icon {
      color: white;
      height: 20px;
      width: 20px;
    }
  }


  body {
    background: #ededed;
    font-size: ${FONT_BASE};
    
    @media only screen and (min-width: ${BREAKPOINT_XXL}) {
      & {
        font-size: ${FONT_BASE_XXL};
      }
    }

    @media only screen and (min-width: ${BREAKPOINT_X1}) {
      & {
        font-size: ${FONT_BASE_X1};
      }
    }
    
    @media only screen and (min-width: ${BREAKPOINT_X2}) {
      & {
        font-size: ${FONT_BASE_X2};
      }
    }
    
    &.font-loading, 
    &.font-loading *,
    &.font-failed, 
    &.fonts-failed *  {
      font-display: swap;
      font-family: 'Open sans', Helvetica, Arial, sans-serif;
    }
    
    &.font-loaded, 
    &.font-loaded *  {
      font-display: swap;
      font-family: 'Roboto', 'Open sans', Helvetica, Arial, sans-serif;
    }
  }

  #root {
    height: 100%;
  }

  .ant-modal-mask {
    background: rgba(0,0,0,0.25) !important;
  }

/* NOTIFICATIONS */
.ant-notification {
  
  .ant-notification-notice{
    padding: 16px;
    border-left-width: 4px;
    border-left-style: solid;

    &.notification-error {
      border-left-color: ${ERROR_COLOR};

      .ant-notification-notice-icon {
        color: ${ERROR_COLOR}
      }
    }
    &.notification-success {
      border-left-color: ${SUCCESS_COLOR};
      .ant-notification-notice-icon {
        color: ${SUCCESS_COLOR}
      }
    }
    &.notification-info {
      border-left-color: ${INFO_COLOR};
      .ant-notification-notice-icon {
        color: ${INFO_COLOR};
      }
    }
  }
}

/* BOX SHADOWS */
.box1 {
	-webkit-box-shadow: 0 10px 6px -6px #777;
	-moz-box-shadow: 0 10px 6px -6px #777;
	box-shadow: 0 10px 6px -6px #777;
}
.box2 {
	-webkit-box-shadow: 0 1px 2px #777;
	-moz-box-shadow: 0 2px 1px #777;
	box-shadow: 0 2px 1px #777;
}
.box3 {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.box4 {
	box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}
.box5 {
	box-shadow: 0 0 1px rgba(34, 25, 25, 0.4);
}
.box6 {
	box-shadow: 0 1px #FFFFFF inset, 0 1px 3px rgba(34, 25, 25, 0.4);
}
.box7 {
	box-shadow: 0 4px 2px -3px;
}
.box8 {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}
.box9 {
	border-bottom: 0 none;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.46);
}
.box10 {
	-webkit-box-shadow: 0 7px 4px #777;
	-moz-box-shadow: 0 7px 4px #777;
	box-shadow: 0 7px 4px #777;
}
.box11 {
	-webkit-box-shadow: 0 3px 2px #777;
	-moz-box-shadow: 0 3px 2px #777;
	box-shadow: 0 3px 2px #777;
}
.box12 {
	box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 2px 0 #B3B3B3;
}
.box13 {
	-webkit-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
	-moz-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
	box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
}
.box14 {
	-webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
	-moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
	box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.box15 {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
	border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
	border-style: none solid solid none;
	border-width: medium 1px 1px medium;
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
	-moz-border-bottom-colors: none;
	-moz-border-image: none;
	-moz-border-left-colors: none;
	-moz-border-right-colors: none;
	-moz-border-top-colors: none;
}
.box16 {
	box-shadow: 0 1px 1px 0 #C7C7C7 inset;
	background: none repeat scroll 0 0 #E9E9E9;
}

@keyframes blink {
    from { opacity: 1.0; }
    50% { opacity: 0.5; }
    to { opacity:0; }
}                                                                                         

@-webkit-keyframes blink {
    from { opacity: 1.0; }
    50% { opacity: 0.5; }
    to { opacity: 0; }
}

.blink_child .ant-checkbox-inner{
  border-color: ${ACTION_COLOR};
}

.blink_child {
  animation: blink 300ms infinite;
  -webkit-animation:blink 300ms infinite;
  animation-iteration-count: 15;
}

.mobile-alert {
  .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 0;
  }
  
  .ant-modal-body {
    padding: 10px;

  }

  .ant-modal-confirm-btns {
    margin-top: 0;

    .ant-btn.ant-btn-primary {
      font-size: 0.9em;
      color: white;
      border-color:${ACTION_COLOR};
      background: ${ACTION_COLOR};
    }
  }
}

.info-popover {
  .ant-popover-inner {
    margin: 0 5px;
  }

  .ant-popover-inner-content {
    padding: 5px;
    font-size: ${FONT_SIZE_SMALL};
  }
}

/* Tooltip */
  &.ant-tooltip .ant-tooltip-inner {
    font-size: ${FONT_SIZE_SMALL};
  }
 
  img.not-found-image {
    @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
      width: 100%;
      height: 100%;
    }
  }
`;

export default GlobalStyles;
