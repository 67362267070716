import { makeActionCreator } from 'redux/utils';

import {
  SET_DOCUMENT_SELECTED,
  GET_DOCUMENT_DATA_TO_VERIFY_REQUEST,
  GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS,
  GET_DOCUMENT_DATA_TO_VERIFY_FAILED,
  PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST,
  PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS,
  PUT_DOCUMENT_DATA_TO_VERIFY_FAILED,
  RESET_DOCUMENT_ID_SELECTED
} from './constants';

export const setDocumentSelected = makeActionCreator(
  SET_DOCUMENT_SELECTED,
  'payload'
);

export const getDocumentDataToVerify = makeActionCreator(
  GET_DOCUMENT_DATA_TO_VERIFY_REQUEST,
  'payload'
);
export const getDocumentDataToVerifySuccess = makeActionCreator(
  GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS,
  'payload'
);
export const getDocumentDataToVerifyFailed = makeActionCreator(
  GET_DOCUMENT_DATA_TO_VERIFY_FAILED,
  'error'
);

export const putDocumentDataToVerify = makeActionCreator(
  PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST,
  'payload'
);
export const putDocumentDataToVerifySuccess = makeActionCreator(
  PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS,
  'payload'
);
export const putDocumentDataToVerifyFailed = makeActionCreator(
  PUT_DOCUMENT_DATA_TO_VERIFY_FAILED,
  'error'
);

export const resetDocumentSelected = makeActionCreator(RESET_DOCUMENT_ID_SELECTED);
