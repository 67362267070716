import React from 'react';
import styled from 'styled-components';

import { Layout as AntLayout } from 'antd';
import Divider from 'components/common/Divider';
import Navbar from 'components/layouts/Navbar';

import {
  BORDER_DEFAULT,
  FONT_SIZE_SMALL,
  BORDER_RADIUS,
  PADDING_DESKTOP,
  PADDING_MOBILE,
  WIDTH_BREAKPOINT_SM,
  FONT_COLOR_GRAY,
  GRAY_BACKGROUND
} from 'theme/variables';
import { Copyright } from 'components/common/Text';

// FOOTER
const CustomFooter = styled(AntLayout.Footer)`
  background: ${GRAY_BACKGROUND};
  color: ${FONT_COLOR_GRAY};
  text-align: center;
  font-size: ${FONT_SIZE_SMALL};
  border-top: ${BORDER_DEFAULT};
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};

  &.ant-layout-footer {
    padding: 10px;
  }
`;

const renderCopyright = () => {
  const year = new Date().getFullYear();
  return (
    <Copyright>
      {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION} | &#xa9;
      Service Finance Company, LLC | Copyright {year}
    </Copyright>
  );
};

function Footer({ footerLinks = true, ...props }) {
  return (
    <CustomFooter {...props}>
      {footerLinks ? (
        <>
          <Navbar />
          <Divider margin={'4px 0'} />
        </>
      ) : null}
      {renderCopyright()}
    </CustomFooter>
  );
}

export default Footer;
