import { takeLatest, call, put, select } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { replace } from 'connected-react-router';

import {
  getUserTypesRequest,
  getUserDataToVerifyRequest,
  putUserDataToVerifyRequest,
  getLoanDataToVerifyRequest
} from 'api/SignatureAPI';

import {
  getUserTypesFailed,
  getUserTypesSuccess,
  getUserDataToVerifySuccess,
  getUserDataToVerifyFailed,
  putUserDataToVerifySuccess,
  putUserDataToVerifyFailed,
  putUserDataToVerify,
  getLoanDataToVerifySuccess,
  getLoanDataToVerifyFailed,
  saveProvidedLoan,
  putProvidedLoanWithDataToVerifySuccess,
  putProvidedLoanWithDataToVerifyFailed
} from './actions';
import { showMessage } from 'redux/messages/actions';
import { saveCurrentToken, saveUserToken } from 'redux/tokens/actions';

import {
  selectAttemptsProvidedLoanWithDataToVerify,
  selectAttemptsUserDataToVerify,
  selectUserTypeSelected
} from './selectors';

import {
  GET_USER_TYPES_REQUEST,
  GET_USER_DATA_TO_VERIFY_REQUEST,
  PUT_USER_DATA_TO_VERIFY_REQUEST,
  PUT_USER_DATA_TO_VERIFY_FAILED,
  USER_AUTH_ERROR_TYPE_CODES,
  GET_LOAN_DATA_TO_VERIFY_REQUEST,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST
} from './constants';
import { ROUTES } from 'consts/routes';
import { resetDocumentSelected } from 'redux/activeDocument/actions';

function* getUserTypesSaga() {
  yield takeLatest(GET_USER_TYPES_REQUEST, function* () {
    try {
      const response = yield call(getUserTypesRequest);

      if (response.isSuccess) {
        yield put(getUserTypesSuccess(response.data));
        yield put(replace(ROUTES.CLIENT_SELECTION));
      } else {
        yield put(getUserTypesFailed(response.data));
      }
    } catch (error) {
      if (error.message) {
        yield put(getUserTypesFailed(error.message));
      }
    }
  });
}

function* getUserDataToVerifySaga() {
  yield takeLatest(GET_USER_DATA_TO_VERIFY_REQUEST, function* ({ payload }) {
    try {
      const response = yield call(getUserDataToVerifyRequest, payload);

      if (response.isSuccess) {
        if (!isEmpty(response.data)) {
          yield put(getUserDataToVerifySuccess(response.data));
          yield put(replace(ROUTES.VERIFY_USER));
        } else {
          yield put(putUserDataToVerify(null));
        }
      } else {
        yield put(getUserDataToVerifyFailed(response.data));
        yield put(replace(ROUTES.VERIFY_USER));
      }
    } catch (error) {
      if (error.message) {
        yield put(getUserDataToVerifyFailed(error.message));
      }
    }
  });
}

function* putUserDataToVerifySaga() {
  yield takeLatest(PUT_USER_DATA_TO_VERIFY_REQUEST, function* ({ payload }) {
    try {
      const attemptsUserDataToVerify = yield select(
        selectAttemptsUserDataToVerify
      );
      const userTypeSelected = yield select(selectUserTypeSelected);
      const data = {
        identify: {
          userType: userTypeSelected
        },
        data: payload,
        attempt: attemptsUserDataToVerify + 1
      };
      const response = yield call(putUserDataToVerifyRequest, data);

      if (response.isSuccess) {
        // Save user token
        yield put(putUserDataToVerifySuccess(payload));
        yield put(saveUserToken(response.data));
        yield put(saveCurrentToken(response.data));
      }
    } catch (error) {
      yield put(putUserDataToVerifyFailed(error.message));
    }
  });
}

function* putUserDataToVerifyFailedSaga() {
  yield takeLatest(PUT_USER_DATA_TO_VERIFY_FAILED, function* ({ error }) {
    let payload = {
      title: "We're sorry, an error has occurred.",
      message: 'Impossible to identify the user with the data provided.',
      status: 'error',
      retryOption: false
    };
    if (error.type && !USER_AUTH_ERROR_TYPE_CODES.includes(error.type)) {
      payload = {
        ...payload,
        title: "We're sorry, unable to access the system."
      };
    }
    yield put(showMessage(payload));
  });
}

function* getLoanDataToVerifySaga() {
  yield takeLatest(GET_LOAN_DATA_TO_VERIFY_REQUEST, function* ({ payload }) {
    try {
      const response = yield call(getLoanDataToVerifyRequest, payload);

      if (response.isSuccess) {
        if (!isEmpty(response.data)) {
          yield put(getLoanDataToVerifySuccess(response.data));
          yield put(saveProvidedLoan(payload));
          yield put(replace(ROUTES.VERIFY_LOAN));
        } else {
          yield put(putUserDataToVerify(null));
        }
      } else {
        yield put(getLoanDataToVerifyFailed(response.data));
        // showErrorNotification(
        //   `Loan Number verification cannot be performed at this moment.`
        // );
      }
    } catch (error) {
      let errorData = {
        description: 'Sorry, the data provided could not be validated.'
      };

      // -- Don't show error from server
      // if (error.showToUser) {
      //   errorData = error.message;
      // }
      yield put(getLoanDataToVerifyFailed(errorData));
    }
  });
}

function* putProvidedLoanWithDataToVerifySaga() {
  yield takeLatest(PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST, function* ({
    payload
  }) {
    try {
      const attemptsProvidedLoanWithDataToVerify = yield select(
        selectAttemptsProvidedLoanWithDataToVerify
      );
      const userTypeSelected = yield select(selectUserTypeSelected);
      const data = {
        identify: {
          userType: userTypeSelected
        },
        data: payload,
        attempt: attemptsProvidedLoanWithDataToVerify + 1
      };

      // yield put(resetDocumentSelected());
      const response = yield call(putUserDataToVerifyRequest, data);

      if (response.isSuccess) {
        // Save user token
        yield put(putProvidedLoanWithDataToVerifySuccess(payload));
        yield put(saveUserToken(response.data));
        yield put(saveCurrentToken(response.data));
      }
    } catch (error) {
      let errorData = {
        description: 'Sorry, the data provided could not be validated.'
      };

      // -- Don't show error from server
      // if (error.showToUser) {
      //   errorData = error.message;
      // }
      yield put(putProvidedLoanWithDataToVerifyFailed(errorData));
    }
  });
}

export default [
  getUserTypesSaga,
  getUserDataToVerifySaga,
  putUserDataToVerifySaga,
  putUserDataToVerifyFailedSaga,
  getLoanDataToVerifySaga,
  putProvidedLoanWithDataToVerifySaga
];
