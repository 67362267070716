import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ToSignInterface from './PendingToSign';
import SignedDocuments from './SignedDocuments';
import { selectShowSignedDocumentsAfterSign } from 'redux/app/selectors';
import { toggleShowSignedDocumentsAfterSign } from 'redux/app/actions';
import isNil from 'lodash/isNil';
import { setDocumentCurrentStep } from 'redux/assistant/actions';

function Main() {
  const dispatch = useDispatch();
  const showSignedDocumentsAfterSign = useSelector(
    selectShowSignedDocumentsAfterSign
  );

  const toggleShowSignedDocuments = documentIndex => {
    dispatch(toggleShowSignedDocumentsAfterSign());

    if (!isNil(documentIndex)) {
      dispatch(setDocumentCurrentStep(documentIndex));
    }
  };

  return showSignedDocumentsAfterSign ? (
    <SignedDocuments onToggleView={toggleShowSignedDocuments} />
  ) : (
    <ToSignInterface onToggleView={toggleShowSignedDocuments} />
  );
}

export default memo(Main);
