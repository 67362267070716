import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import {
  SAVE_AND_SHOW_MESSAGE_PAGE,
  SAVE_NOTIFICATION_TO_SHOW
} from 'redux/messages/constants';
import {
  LOGOUT_USER,
  RESET_APP,
  SIGN_DOCUMENT_REQUEST
} from 'redux/app/constants';
import { LOCATION_CHANGE } from 'connected-react-router';

const showMessageReducer = (state = null, action) => {
  switch (action.type) {
    case SAVE_AND_SHOW_MESSAGE_PAGE:
      return fromJS(action.payload);
    case LOGOUT_USER:
    case SIGN_DOCUMENT_REQUEST:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const showNotificationReducer = (state = null, action) => {
  switch (action.type) {
    case SAVE_NOTIFICATION_TO_SHOW:
      return fromJS(action.payload);
    case LOGOUT_USER:
    case LOCATION_CHANGE:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  showMessage: showMessageReducer,
  showNotification: showNotificationReducer
});
