import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import {
  SET_DOCUMENT_SELECTED,
  GET_DOCUMENT_DATA_TO_VERIFY_REQUEST,
  GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS,
  GET_DOCUMENT_DATA_TO_VERIFY_FAILED,
  PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST,
  PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS,
  PUT_DOCUMENT_DATA_TO_VERIFY_FAILED,
  RESET_DOCUMENT_ID_SELECTED
} from './constants';
import {
  GET_KEY_DOCUMENT_REQUEST,
  GET_KEY_DOCUMENT_FAILED,
  GET_KEY_DOCUMENT_SUCCESS
} from 'redux/tokens/constants';
import { LOGOUT_USER, RESET_APP } from 'redux/app/constants';
import {
  GET_DOCUMENT_LIST_TO_SELECT_SUCCESS,
  GET_DOCUMENT_LIST_TO_SELECT_FAILED,
  GET_DOCUMENT_LIST_TO_SELECT_REQUEST
} from 'redux/documentList/constants';
import { LOCATION_CHANGE } from 'connected-react-router';
import { isEmpty } from 'lodash';

const documentIdSelectedReducer = (state = null, action) => {
  switch (action.type) {
    case GET_KEY_DOCUMENT_SUCCESS:
      return fromJS(action.payload.id);
    case SET_DOCUMENT_SELECTED:
      return fromJS(action.payload.document.documentId);
    case RESET_DOCUMENT_ID_SELECTED:
    case GET_KEY_DOCUMENT_REQUEST:
    case GET_KEY_DOCUMENT_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const documentTypeSelectedReducer = (state = null, action) => {
  switch (action.type) {
    case GET_KEY_DOCUMENT_SUCCESS:
      return fromJS(action.payload.typeId);
    case SET_DOCUMENT_SELECTED:
      return fromJS(action.payload.document.documentType);
    case RESET_DOCUMENT_ID_SELECTED:
    case GET_KEY_DOCUMENT_REQUEST:
    case GET_KEY_DOCUMENT_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const documentVerifiedReducer = (state = false, action) => {
  switch (action.type) {
    case GET_KEY_DOCUMENT_SUCCESS:
      return fromJS(action.payload.isDocumentVerified);
    case GET_DOCUMENT_DATA_TO_VERIFY_REQUEST:
    case GET_DOCUMENT_DATA_TO_VERIFY_FAILED:
    case PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST:
    case PUT_DOCUMENT_DATA_TO_VERIFY_FAILED:
    case RESET_DOCUMENT_ID_SELECTED:
    case GET_KEY_DOCUMENT_REQUEST:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const documentDataToVerifyReducer = (state = null, action) => {
  switch (action.type) {
    case GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS:
      return fromJS(action.payload);
    case GET_DOCUMENT_DATA_TO_VERIFY_REQUEST:
    case GET_DOCUMENT_DATA_TO_VERIFY_FAILED:
    case RESET_DOCUMENT_ID_SELECTED:
    case RESET_APP:
    case LOGOUT_USER:
      return null;
    default:
      return state;
  }
};

const documentDataToVerifyErrorReducer = (state = false, action) => {
  switch (action.type) {
    case GET_DOCUMENT_DATA_TO_VERIFY_FAILED:
      return true;
    case GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS:
    case GET_DOCUMENT_DATA_TO_VERIFY_REQUEST:
    case RESET_DOCUMENT_ID_SELECTED:
    case RESET_APP:
    case LOGOUT_USER:
      return null;
    default:
      return state;
  }
};

const isFetchingDocumentDataToVerifyReducer = (state = false, action) => {
  switch (action.type) {
    case GET_DOCUMENT_LIST_TO_SELECT_REQUEST:
    case GET_DOCUMENT_DATA_TO_VERIFY_REQUEST:
      return true;
    case GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS: {
      if (!isEmpty(action.payload)) {
        return false;
      }
    }
    case GET_DOCUMENT_LIST_TO_SELECT_SUCCESS:
    case GET_DOCUMENT_LIST_TO_SELECT_FAILED:
    case GET_DOCUMENT_DATA_TO_VERIFY_FAILED:
    case RESET_DOCUMENT_ID_SELECTED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const isFetchingPutDocumentDataToVerifyReducer = (state = false, action) => {
  switch (action.type) {
    case PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST:
      return true;
    case PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS:
    case PUT_DOCUMENT_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const attemptsDocumentDataToVerifyReducer = (state = 0, action) => {
  switch (action.type) {
    case PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST:
      return state + 1;
    case PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS:
    case GET_KEY_DOCUMENT_REQUEST:
    case LOGOUT_USER:
    case RESET_APP:
      return 0;
    default:
      return state;
  }
};

const verifyDocumentDataErrorsReducer = (state = null, action) => {
  switch (action.type) {
    case PUT_DOCUMENT_DATA_TO_VERIFY_FAILED:
      return fromJS(action.error);
    case PUT_DOCUMENT_DATA_TO_VERIFY_SUCCESS:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  documentIdSelected: documentIdSelectedReducer,
  documentTypeSelected: documentTypeSelectedReducer,
  documentVerified: documentVerifiedReducer,
  documentDataToVerify: documentDataToVerifyReducer,
  documentDataToVerifyError: documentDataToVerifyErrorReducer,
  isFetchingDocumentDataToVerify: isFetchingDocumentDataToVerifyReducer,
  isFetchingPutDocumentDataToVerify: isFetchingPutDocumentDataToVerifyReducer,
  attemptsDocumentDataToVerify: attemptsDocumentDataToVerifyReducer,
  verifyDocumentDataErrors: verifyDocumentDataErrorsReducer
});
