import { createSelector } from 'reselect';

const selectAssitantDomain = () => state => state.get('assistant');

const selectAssistant = createSelector(selectAssitantDomain(), assistant => {
  if (assistant) {
    return assistant.toJS();
  }
  return null;
});

const selectAssistantCurrentStep = createSelector(selectAssistant, assistant =>
  assistant ? assistant.currentStep : 0
);

const selectAssistantCurrentDocumentStep = createSelector(
  selectAssistant,
  assistant => (assistant ? assistant.currentDocumentStep : 0)
);

const selectIsSignerOpen = createSelector(selectAssistant, assistant =>
  assistant ? assistant.isSignerOpen : false
);

export default selectAssistant;
export {
  selectAssistantCurrentStep,
  selectAssistantCurrentDocumentStep,
  selectIsSignerOpen
};
