import { takeLatest, call, put } from 'redux-saga/effects';

import { getDocumentListToSelectRequest } from 'api/SignatureAPI';

import {
  getDocumentListToSelectSuccess,
  getDocumentListToSelectFailed
} from './actions';

import { GET_DOCUMENT_LIST_TO_SELECT_REQUEST } from './constants';
import { replace } from 'connected-react-router';
import { ROUTES } from 'consts/routes';
import { setDocumentSelected } from 'redux/activeDocument/actions';
import { DOCUMENT_STATUS_PENDING } from 'redux/app/constants';
import { element } from 'prop-types';

function* getDocumentListToSelectSaga() {
  yield takeLatest(GET_DOCUMENT_LIST_TO_SELECT_REQUEST, function* ({
    payload
  }) {
    try {
      const response = yield call(getDocumentListToSelectRequest, payload);

      if (response.isSuccess) {
        const data = response.data;
        yield put(getDocumentListToSelectSuccess(data));

        // If the list contains only one document, set the document as selected document directly
        if (payload.init && data.length === 1) {
          const documentItem = data[0];
          const canSignDocument = documentItem.flags?.SignDocument;
          const documentId = documentItem.id.toString();
          const documentType = parseInt(documentItem.type, 10);
          const documentStatus = documentItem.status?.id;
          const document = {
            documentId,
            documentType
          };
          const showInformedConsent =
            canSignDocument && documentStatus === DOCUMENT_STATUS_PENDING;
          yield put(
            setDocumentSelected({
              showInformedConsent,
              document
            })
          );
          return;
        }
      } else {
        yield put(getDocumentListToSelectFailed(response));
      }
      if (payload.init) {
        yield put(replace(ROUTES.DOCUMENTS_LIST));
      }
    } catch (error) {
      yield put(getDocumentListToSelectFailed(error));
      if (error.message) {
        yield put(getDocumentListToSelectFailed(error.message));
      }
    }
  });
}
export default [getDocumentListToSelectSaga];
