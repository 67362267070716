import React, { memo } from 'react';

import { NavLink as NavLinkAnt } from 'react-router-dom';
import styled from 'styled-components';

import Menu from 'components/common/Menu';

import { ROUTES } from 'consts/routes';
import { selectRequireFAQ } from 'redux/settings/selectors';
import { useSelector } from 'react-redux';
import { ACTION_COLOR } from 'theme/variables';

const NavLinkStyled = styled(NavLinkAnt)`
  &:focus {
    outline: auto;
  }
`;

function Navbar() {
  const requireFAQ = useSelector(selectRequireFAQ);

  const FAQlink = {
    label: (
      <NavLinkStyled
        to={ROUTES.FAQ}
        activeClassName="selected"
        style={{ color: ACTION_COLOR }}
      >
        FAQs
      </NavLinkStyled>
    ),
    key: 'faq'
  };

  let items = [
    {
      label: (
        <a
          href="https://www.svcfin.com/pages/privacy.notice/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: ACTION_COLOR }}
        >
          Privacy notices
        </a>
      ),
      key: 'privacyNotice'
    },
    {
      label: (
        <NavLinkStyled
          to={ROUTES.ABOUTUS}
          activeClassName="selected"
          style={{ color: ACTION_COLOR }}
        >
          About us
        </NavLinkStyled>
      ),
      key: 'aboutUs'
    }
  ];

  if (requireFAQ) {
    items.unshift(FAQlink);
  }

  return <Menu className="navbar-links" items={items} mode="inline" />;
}

export default memo(Navbar);
