import {
  takeLatest,
  call,
  put,
  select,
  all,
  takeEvery
} from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import {
  getDocumentDataRequest,
  getDocumentContent,
  signDocuments
} from 'api/SignatureAPI';

import {
  showAlertWithConfirm,
  showErrorNotificationFromResponse,
  showErrorNotification
} from 'utils/notifications';

import { setAssistantCurrentStep } from 'redux/assistant/actions';
import {
  getDocumentData,
  getDocumentDataSuccess,
  getDocumentDataFailed,
  getDocumentContentRequest,
  getDocumentContentSuccess,
  getDocumentContentFailed,
  signDocumentSuccess,
  signDocumentFailed,
  getDocumentsContentFailed,
  getDocumentsContentFinished,
  getDocumentsContent,
  logoutUser,
  resetApp
} from './actions';
import { showMessage, showNotification } from 'redux/messages/actions';
import { getDocumentListToSelect } from 'redux/documentList/actions';

import {
  selectDocumentData,
  selectEntryWithToken,
  selectErrorDescription,
  selectIsStatusError,
  selectSignatoryId,
  selectVerifiedData
} from './selectors';
import { selectActiveSignerMethod } from 'redux/signer/selectors';
import {
  selectHaveLoanDataToVerify,
  selectHaveUserDataToVerify,
  selectLoanDataToVerify,
  selectUserDataToVerify,
  selectUserTypeSelected,
  selectUserVerified
} from 'redux/verifyUser/selectors';
import {
  selectAgreementsVerified,
  selectDataResumeVerified
} from 'redux/agreements/selectors';

import {
  GET_DOCUMENT_DATA_REQUEST,
  SIGN_DOCUMENT_REQUEST,
  SIGN_DOCUMENT_SUCCESS,
  GET_DOCUMENTS_CONTENT,
  GET_DOCUMENT_DATA_FAILED,
  GET_DOCUMENT_DATA_SUCCESS,
  LOGOUT_USER,
  DOCUMENT_STATUS_SIGNED,
  GO_TO_PREVIOUS_VERIFICATION_STEP,
  GET_DOCUMENT_CONTENT_FAILED,
  SMART_ACTION_RUNNING,
  RESET_APP
} from './constants';
import { ASSISTANT_STEP_DONE } from 'redux/assistant/constants';
import {
  SIGNER_TYPE_NAME,
  SIGNER_TYPE_PAD,
  SIGNER_TYPE_IMAGE
} from 'redux/signer/constants';

import * as documentModel from 'models/document';
import { ROUTES } from 'consts/routes';
import { selectDocumentListToSelect } from 'redux/documentList/selectors';
import {
  selectDocumentDataToVerify,
  selectDocumentIdSelected,
  selectDocumentTypeSelected,
  selectDocumentVerified,
  selectHaveDocumentDataToVerify
} from 'redux/activeDocument/selectors';
import {
  FAILED_NOTIFICATION_MSG_DESCRIPTION_BY_DEFAULT,
  FAILED_NOTIFICATION_MSG_TITLE_BY_DEFAULT
} from 'redux/messages/constants';
import {
  GET_LOAN_DATA_TO_VERIFY_FAILED,
  GET_USER_DATA_TO_VERIFY_FAILED,
  GET_USER_TYPES_FAILED
} from 'redux/verifyUser/constants';
import {
  GET_DOCUMENT_DATA_TO_VERIFY_FAILED,
  PUT_DOCUMENT_DATA_TO_VERIFY_FAILED
} from 'redux/activeDocument/constants';
import { selectCurrentToken } from 'redux/tokens/selectors';
import { getKeyDocument } from 'redux/tokens/actions';
import { getUserDataToVerify } from 'redux/verifyUser/actions';
import {
  getDocumentDataToVerify,
  resetDocumentSelected
} from 'redux/activeDocument/actions';
import { isEmpty } from 'lodash';
import {
  saveSettings,
  setAfterSigningMessage,
  setCertifyMessage,
  setDisclosureAlert,
  setDisclosureResourceName,
  setInformationAlert,
  setRequireFAQ,
  setSignatureAlert
} from 'redux/settings/actions';
import { SAVE_SETTINGS } from 'redux/settings/constants';
import { getResource } from 'redux/agreements/actions';

// Action called when the some request has failed
function* saveSettingsSaga() {
  yield takeLatest(SAVE_SETTINGS, function* ({
    config: { agreement, signature, FAQ }
  }) {
    if (agreement?.disclosure?.alert) {
      yield put(setDisclosureAlert(agreement.disclosure.alert));
    }

    if (agreement?.disclosure?.resource) {
      yield put(setDisclosureResourceName(agreement.disclosure.resource));
    }

    if (agreement?.information?.alert) {
      yield put(setInformationAlert(agreement.information.alert));
    }

    if (signature?.certifyMessage) {
      yield put(setCertifyMessage(signature.certifyMessage));
    }

    if (signature?.alert) {
      yield put(setSignatureAlert(signature.alert));
    }

    if (signature?.afterSigning?.message) {
      yield put(setAfterSigningMessage(signature.afterSigning.message));
    }

    if (FAQ?.hasOwnProperty('hideLink')) {
      yield put(setRequireFAQ(!FAQ.hideLink));
    }

    yield put(getResource());
  });
}

function* getDocumentDataSaga() {
  yield takeLatest(
    GET_DOCUMENT_DATA_REQUEST,

    function* ({ payload }) {
      try {
        const response = yield call(getDocumentDataRequest);
        if (response && response.isSuccess && response.data) {
          const data = response.data;

          data.canSaveDocuments = data.hasOwnProperty('canSaveDocuments')
            ? response.data.canSaveDocuments
            : true;

          if (!data.dataResume) {
            data.dataResume = data.data;
          }

          if (data.config) {
            yield put(saveSettings(data.config));
          } else {
            yield put(getResource());
          }

          yield put(getDocumentDataSuccess(data));

          /**
           * !important
           */
          if (payload.loadDocuments) {
            yield put(getDocumentsContent());
          }
        } else {
          yield put(getDocumentDataFailed('No token'));
        }
      } catch (error) {
        yield put(getDocumentDataFailed(error));
      }
    }
  );
}

function* getDocumentDataSuccessSaga() {
  yield takeLatest(GET_DOCUMENT_DATA_SUCCESS, function* ({ payload }) {
    if (payload) {
      const dataResumeVerified = yield select(selectDataResumeVerified);
      const agreementsVerified = yield select(selectAgreementsVerified);

      // if the document data is already signed go directly to pdf viewer
      if (payload.documentStatus === DOCUMENT_STATUS_SIGNED) {
        yield put(replace(ROUTES.MAIN));
        return;
      }

      if (!dataResumeVerified || !agreementsVerified) {
        yield put(replace(ROUTES.AGREEMENTS));
      }
    }
  });
}

function* getDocumentsContentSaga() {
  yield takeLatest(GET_DOCUMENTS_CONTENT, function* () {
    try {
      const isDocumentStatusError = yield select(selectIsStatusError);
      const errorDescription = yield select(selectErrorDescription);
      const documentData = yield select(selectDocumentData);

      if (isDocumentStatusError) {
        showAlertWithConfirm(errorDescription);
      }

      yield call(documentModel.clearDocuments); //Clear previous documents.
      yield all(
        documentData.documents.map((document, index) =>
          call(loadDocument, index, document.documentId)
        )
      );
      yield put(getDocumentsContentFinished());
    } catch (error) {
      yield put(getDocumentsContentFailed());
      showErrorNotificationFromResponse(error);
    }
  });
}

// ----- Get document content ---------------------------

function* loadDocument(index, documentId) {
  try {
    yield put(getDocumentContentRequest({ index, documentId }));
    const response = yield call(getDocumentContent, documentId);

    if (response.isSuccess) {
      const pdfDocument = new Blob([response.data], {
        type: 'application/pdf'
      });

      const arrayBuffer = yield pdfDocument.arrayBuffer();
      const data = new Uint8Array(arrayBuffer);
      documentModel.setContent(documentId, data);

      // Save in memory the document content
      // yield call(documentModel.setContent, documentId, pdfDocument);
      yield put(getDocumentContentSuccess({ index, documentId }));
    } else {
      yield call(documentModel.setError, documentId, response.error);
      yield put(
        getDocumentContentFailed({ index, documentId, error: response.error })
      );
      // showErrorNotificationFromResponse(response);
    }
  } catch (error) {
    yield call(documentModel.setError, documentId, error);
    // showErrorNotificationFromResponse(error);
    yield put(getDocumentContentFailed({ index, documentId, error }));
  }
}

// ------------------------------------------------

function* signDocumentRequestSaga() {
  yield takeLatest(SIGN_DOCUMENT_REQUEST, function* ({ payload }) {
    try {
      const activeSignerMethod = yield select(selectActiveSignerMethod);
      const verifiedData = yield select(selectVerifiedData);
      const userTypeSelected = yield select(selectUserTypeSelected);

      const data = {
        signatory: [
          {
            signatoryTypeId: userTypeSelected
          }
        ],
        data: verifiedData
      };
      switch (parseInt(activeSignerMethod)) {
        case SIGNER_TYPE_NAME:
          data.signatory[0].signature = {
            text: payload.signatoryFullName,
            textStyleId: payload.styleId
          };
          break;
        case SIGNER_TYPE_PAD:
          data.signatory[0].signature = {
            img: payload.signaturePad.replace('data:image/png;base64,', '')
          };
          break;
        case SIGNER_TYPE_IMAGE:
          data.signatory[0].signature = {
            img: payload.signatureImage.replace('data:image/png;base64,', '')
          };
          break;
        default:
          break;
      }
      const response = yield call(signDocuments, data);

      if (response.isSuccess) {
        documentModel.clearDocuments();
        yield put(signDocumentSuccess());
        yield put(getDocumentListToSelect());
      } else {
        yield put(signDocumentFailed(response.data));
        showErrorNotificationFromResponse(response);
      }
    } catch (error) {
      yield put(signDocumentFailed(error));
      if (error.notFound) {
        showErrorNotification(
          'The document could not be signed. For more information contact us.',
          `We're sorry, there was a problem.`,
          ''
        );
        return;
      }
      showErrorNotificationFromResponse(error);
    }
  });
}

function* signDocumentSuccessSaga() {
  yield takeLatest(SIGN_DOCUMENT_SUCCESS, function* () {
    const documentListToSelect = yield select(selectDocumentListToSelect);

    yield put(setAssistantCurrentStep(ASSISTANT_STEP_DONE));
    yield put(getDocumentData({ loadDocuments: true }));
    if (documentListToSelect) {
      yield put(getDocumentListToSelect({}));
    }
  });
}

function* logoutUserSaga() {
  yield takeEvery(LOGOUT_USER, function* () {
    yield put(resetApp());
    yield put(replace(ROUTES.HOME));
  });
}

function* goToPreviousVerificationStepSaga() {
  yield takeLatest(GO_TO_PREVIOUS_VERIFICATION_STEP, function* () {
    const haveLoanDataToVerify = yield select(selectHaveLoanDataToVerify);
    const haveUserDataToVerify = yield select(selectHaveUserDataToVerify);
    const haveDocumentDataToVerify = yield select(
      selectHaveDocumentDataToVerify
    );

    if (haveLoanDataToVerify) {
      yield put(replace(ROUTES.VERIFY_LOAN));
      return;
    }

    if (haveUserDataToVerify) {
      yield put(replace(ROUTES.VERIFY_USER));
      return;
    }

    if (haveDocumentDataToVerify) {
      yield put(replace(ROUTES.VERIFY_DOCUMENT));
      return;
    }

    yield put(logoutUser());
  });
}

function* failedCasesShowingErrorInHomeSaga() {
  yield takeLatest(
    [GET_LOAN_DATA_TO_VERIFY_FAILED, GET_USER_TYPES_FAILED],
    function* ({ error }) {
      let notification = {
        message: `${FAILED_NOTIFICATION_MSG_TITLE_BY_DEFAULT} ${FAILED_NOTIFICATION_MSG_DESCRIPTION_BY_DEFAULT}`
      };

      if (error && error.description) {
        notification.message = error.description;
      }

      yield put(replace(ROUTES.HOME));
      yield put(resetApp());
      yield put(showNotification(notification));
    }
  );
}

function* failedCasesShowingErrorInErrorPageSaga() {
  yield takeLatest(
    [
      GET_USER_DATA_TO_VERIFY_FAILED,
      GET_DOCUMENT_DATA_TO_VERIFY_FAILED,
      GET_DOCUMENT_DATA_FAILED,
      GET_DOCUMENT_CONTENT_FAILED
    ],
    function* ({ error }) {
      let notification = {
        title: FAILED_NOTIFICATION_MSG_TITLE_BY_DEFAULT,
        message: FAILED_NOTIFICATION_MSG_DESCRIPTION_BY_DEFAULT,
        status: 'error',
        retryOption: true
      };

      yield put(showMessage(notification));
    }
  );
}

function* putDocumentDataToVerifyErrorSaga() {
  yield takeLatest(PUT_DOCUMENT_DATA_TO_VERIFY_FAILED, function* ({ error }) {
    const haveDataToVerify = yield select(selectDocumentDataToVerify);

    if (isEmpty(haveDataToVerify)) {
      let notification = {
        title: FAILED_NOTIFICATION_MSG_TITLE_BY_DEFAULT,
        message: FAILED_NOTIFICATION_MSG_DESCRIPTION_BY_DEFAULT,
        status: 'error',
        retryOption: true
      };

      yield put(showMessage(notification));
    }
  });
}

// Action called when the some request has failed
function* smartActionRunningSaga() {
  yield takeLatest(SMART_ACTION_RUNNING, function* () {
    const isUserVerified = yield select(selectUserVerified);
    const currentToken = yield select(selectCurrentToken);
    const signatoryTypeId = yield select(selectUserTypeSelected);
    const entryWithToken = yield select(selectEntryWithToken);

    //Entry with TOKEN and getting key document
    if (entryWithToken && currentToken && !signatoryTypeId) {
      yield put(getKeyDocument(currentToken));
      return;
    }

    // Getting user data
    if (signatoryTypeId) {
      // If have signatory id, but is not verified, then go to verify user.
      if (!isUserVerified) {
        yield put(getUserDataToVerify(signatoryTypeId));
        return;
      }
    }

    const isDocumentVerified = yield select(selectDocumentVerified);
    const documentIdSelected = yield select(selectDocumentIdSelected);
    const documentTypeSelected = yield select(selectDocumentTypeSelected);

    if (isUserVerified && !isDocumentVerified) {
      if (documentIdSelected && documentTypeSelected) {
        yield put(resetDocumentSelected());
      }
      yield put(getDocumentListToSelect({ init: true }));
      return;
    }

    const documentData = yield select(selectDocumentData);

    if (isUserVerified && isDocumentVerified && !documentData) {
      yield put(getDocumentData({ loadDocuments: false }));
      return;
    }

    if (documentData) {
      yield put(getDocumentsContent());
      // yield put(replace(ROUTES.MAIN));
    }
  });
}

function* resetAppSaga() {
  yield takeLatest(RESET_APP, function* () {
    sessionStorage.clear();
  });
}

export default [
  resetAppSaga,
  saveSettingsSaga,
  getDocumentDataSaga,
  getDocumentDataSuccessSaga,
  getDocumentsContentSaga,
  signDocumentRequestSaga,
  signDocumentSuccessSaga,
  logoutUserSaga,
  goToPreviousVerificationStepSaga,
  failedCasesShowingErrorInHomeSaga,
  failedCasesShowingErrorInErrorPageSaga,
  putDocumentDataToVerifyErrorSaga,
  smartActionRunningSaga
];
