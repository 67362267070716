import React, { memo } from 'react';
import Helmet from 'react-helmet';

import { DocumentsContainer } from 'components/layouts/Centered';
import HeaderContentFooter from 'components/layouts/HeaderContentFooter';
import { Content } from 'components/layouts';

import termsAndConditions from 'assets/eSign_TermsAndConditions.pdf';

import { APP_NAME } from 'redux/app/constants';
import Visor from 'components/common/PDFVisor/Visor';
const PAGE_TITLE = 'Disclosure and Consent';

function TermsAndConditions() {
  return (
    <>
      <Helmet title={`${APP_NAME} - ${PAGE_TITLE}`} />
      <HeaderContentFooter>
        <Content>
          <DocumentsContainer>
            <Visor fileUrl={termsAndConditions} />
            {/* <PdfViewer
              packageTitle={PAGE_TITLE}
              data={{
                file: termsAndConditions,
              }}
              isLocalFile={true}
            /> */}
          </DocumentsContainer>
        </Content>
      </HeaderContentFooter>
    </>
  );
}

export default memo(TermsAndConditions);
