import { makeActionCreator } from 'redux/utils';

import {
  GET_DOCUMENT_LIST_TO_SELECT_REQUEST,
  GET_DOCUMENT_LIST_TO_SELECT_SUCCESS,
  GET_DOCUMENT_LIST_TO_SELECT_FAILED,
} from './constants';

export const getDocumentListToSelect = makeActionCreator(
  GET_DOCUMENT_LIST_TO_SELECT_REQUEST,
  'payload'
);
export const getDocumentListToSelectSuccess = makeActionCreator(
  GET_DOCUMENT_LIST_TO_SELECT_SUCCESS,
  'payload'
);
export const getDocumentListToSelectFailed = makeActionCreator(
  GET_DOCUMENT_LIST_TO_SELECT_FAILED,
  'error'
);
