import styled from 'styled-components';
import { Input, Radio as RadioAnt, Checkbox as CheckboxAnt } from 'antd';
import MaskedInput from 'antd-mask-input';

import {
  MAIN_COLOR,
  FONT_SIZE,
  FONT_SIZE_SMALL,
  FONT_COLOR,
  FONT_FAMILY,
  FONT_FORMAL,
  FONT_ELEGANT,
  FONT_CLASSIC,
  FONT_WEIGHT,
  PLACEHOLDER_COLOR,
  WIDTH_BREAKPOINT_XS,
  ACTION_COLOR_HOVER,
  ACTION_COLOR
} from 'theme/variables';

export function getUnMaskedCharacters(str, pattern) {
  return str.match(pattern) || 0;
} 


export const SpacedMaskedInput = styled(MaskedInput)`
  letter-spacing: 0.15em;
`;

// Input
export const InputText = styled(Input)`
  &.ant-input {
    font-size: 30pt;
    height: 100px;
    padding: 2px 10px;
    text-align: center;
    border-top: none;
    border-left: none;
    border-right: none;
    background: white;
    color: #1069d8;

    @media only screen and (max-width: ${WIDTH_BREAKPOINT_XS}) {
      font-size: 16pt;
    }
  }

  &.${props => props.className} {
    ${props => (props.fontFamily ? `font-family: ${props.fontFamily};` : '')}
    ${props =>
      props.fontSize ? `font-size: ${props.fontSize}pt;` : ''}
    ${props =>
      props.color ? `color: ${props.color};` : ''}
  }

  /* Both size and colour work in IE 11 */
  input:-ms-input-placeholder {
    font-size: 2em;
    color: #0f0;
  }

  ::placeholder {
    /* Most modern browsers support this now. */
    font-size: ${FONT_SIZE_SMALL};
    color: ${PLACEHOLDER_COLOR};
    font-family: ${FONT_FAMILY};
  }

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    font-size: ${FONT_SIZE_SMALL};
    color: ${PLACEHOLDER_COLOR};
    font-family: ${FONT_FAMILY};
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-size: ${FONT_SIZE_SMALL};
    color: ${PLACEHOLDER_COLOR};
    font-family: ${FONT_FAMILY};
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-size: ${FONT_SIZE_SMALL};
    color: ${PLACEHOLDER_COLOR};
    font-family: ${FONT_FAMILY};
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: ${FONT_SIZE_SMALL};
    color: ${PLACEHOLDER_COLOR};
    font-family: ${FONT_FAMILY};
  }
  ::-ms-input-placeholder,
  &::-webkit-input-placeholder,
  &::-webkit-input-placeholder {
    /* Microsoft Edge */
    font-size: ${FONT_SIZE_SMALL};
    color: ${PLACEHOLDER_COLOR};
    font-family: ${FONT_FAMILY};
  }

  &.ant-input:focus {
    border-bottom: 2px solid ${MAIN_COLOR};
    outline: 0;
    box-shadow: none;
  }
`;

export const Radio = styled(RadioAnt)`
  &.ant-radio-wrapper {
    font-size: ${FONT_SIZE};
  }
`;

export const Checkbox = styled(CheckboxAnt)`
  .ant-checkbox-inner {
    width: 22px;
    height: 22px;

    &:after {
      top: 45%;
      width: 7px;
      height: 14px;
    }
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  & .ant-checkbox:hover .ant-checkbox-inner,
  & .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after,
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${ACTION_COLOR_HOVER};
  }
`;

export const RadioGroup = styled(RadioAnt.Group)`
  &.ant-radio-group {
    display: flex;
  }
`;

export const RadioButton = styled(RadioAnt.Button)`
  width: 100%;
  text-align: center;

  &.ant-radio-button-wrapper:first-child,
  &.ant-radio-button-wrapper,
  &.ant-radio-button-wrapper:last-child {
    border-radius: 0;
    border: none;
    color: ${FONT_COLOR};
    font-family: ${FONT_FAMILY};
    outline: none;
    background: transparent;
  }

  &.ant-radio-button-wrapper:hover,
  &.ant-radio-button-wrapper-focused,
  &.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    color: ${MAIN_COLOR};
  }

  &.ant-radio-button-wrapper {
    &:before,
    &:not(:first-child)::before {
      content: none;
    }
    &.ant-radio-button-wrapper-checked {
      border-bottom: 2px solid ${MAIN_COLOR};
      box-shadow: none;
      outline: none;
      background: transparent;
    }

    &.classic span {
      font-family: ${FONT_CLASSIC};
    }
    &.elegant span {
      font-family: ${FONT_ELEGANT};
    }
    &.formal span {
      font-family: ${FONT_FORMAL};
    }
  }
`;
