/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
// import { configurePublicAxiosTk, configurePrivateAxiosTk } from 'lib/axios';

import pick from 'lodash/pick';
import history from 'utils/history';

// Import state persistance utilities
import { loadState, saveState } from 'utils/storage';
import appSaga from './app/sagas';
import messagesSaga from './messages/sagas';
import verifyUserSaga from './verifyUser/sagas';
import activeDocumentSaga from './activeDocument/sagas';
import documentListSaga from './documentList/sagas';
import agreementsSaga from './agreements/sagas';
import tokensSaga from './tokens/sagas';
import createReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState = {}, history) => {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;

  // Async reducer registry
  store.asyncReducers = {};
  // Saga registry
  store.asyncSagas = {
    appSaga,
    messagesSaga,
    verifyUserSaga,
    activeDocumentSaga,
    documentListSaga,
    verifyAgreementsSaga: agreementsSaga,
    tokensSaga,
  };

  appSaga.map(store.runSaga);
  messagesSaga.map(store.runSaga);
  verifyUserSaga.map(store.runSaga);
  activeDocumentSaga.map(store.runSaga);
  documentListSaga.map(store.runSaga);
  agreementsSaga.map(store.runSaga);
  tokensSaga.map(store.runSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then(reducerModule => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers(store.asyncReducers);

        store.replaceReducer(nextReducers);
      });
    });
  }

  return store;
};

// Create redux store with history
const initialState = loadState();

const store = configureStore(initialState, history);

// Sync state and session storage
store.subscribe(() => {
  const storeState = store.getState().toJS();

  saveState(
    pick(storeState, [
      'router',
      'app',
      'assistant',
      'signer',
      'agreements',
      'verifyUser',
      'activeDocument',
      'documentList',
      'tokens',
      'messages',
      'settings'
    ])
  );
});

export default store;
