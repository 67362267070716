import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';

import { Layout as AntLayout } from 'antd';

import {
  BACKGROUND_DEFAULT,
  BORDER_DEFAULT,
  COMPANY_NAME_GREEN,
  COMPANY_NAME_BLACK,
  WIDTH_BREAKPOINT_MD,
  GRAY_BACKGROUND,
  PADDING_DESKTOP,
  WIDTH_BREAKPOINT_SM
} from 'theme/variables';
import logo from 'images/logo.png';
import logo_name from 'images/logo-name.png';

export const ContentActions = styled.div`
  border-top: ${BORDER_DEFAULT};
  padding: 20px 0 0;

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    padding: 10px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${WIDTH_BREAKPOINT_MD}) {
    .logo {
      display: none;
    }
  }
  @media only screen and (max-width: ${WIDTH_BREAKPOINT_MD}) {
    .logo-name {
      display: none;
    }
  }
`;

const CustomName = styled.span`
  font-family: 'Frutiger Bold Cn' !important;
  color: ${props => (props.color ? props.color : COMPANY_NAME_BLACK)};
  display: inline;
`;

export const Logo = ({ ...props }) => {
  return (
    <LogoContainer>
      <img className="logo" src={logo} alt="logo" width={30} />
      <img className="logo-name" src={logo_name} alt="logo" width={160} />
    </LogoContainer>
  );
};

export const AppName = ({ ...props }) => {
  return (
    <span>
      <CustomName color={COMPANY_NAME_GREEN}>e</CustomName>
      <CustomName>SIGN</CustomName>
    </span>
  );
};

const Layout = ({ ...props }) => {
  const CustomLayout = styled(AntLayout)``;
  return <CustomLayout {...props} />;
};

// HEADER
const CustomHeader = styled(AntLayout.Header)`
  &.ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${BACKGROUND_DEFAULT};
    padding: 0 20px;
    border-bottom: ${BORDER_DEFAULT};
    height: 40px;
    font-size: 20px;
  }
`;

export const Header = ({ ...props }) => {
  return (
    <CustomHeader {...props}>
      <AppName />
      <Logo />
    </CustomHeader>
  );
};

// SIDER
export const CustomSider = styled(AntLayout.Sider)`
  background: ${BACKGROUND_DEFAULT};

  &.pdf-viewer-sider {
    background: ${GRAY_BACKGROUND};
  }

  .ant-layout-sider-zero-width-trigger {
    top: 0;
    border-radius: 0;
  }
`;

export const Sider = ({ show, ...props }) => {
  return (
    <CustomSider
      {...props}
      collapsible
      collapsedWidth="0"
      collapsed={!show}
      trigger={null}
    >
      {props.children}
    </CustomSider>
  );
};

export const MainContainer = styled.div`
  flex: 1;
  height: 100%;
`;

// CONTENT
const CustomContent = styled(AntLayout.Content)`
  overflow: hidden;
  box-shadow: 0 5px 15px -10px #000000 inset;
`;

export class Content extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    return <CustomContent>{this.props.children}</CustomContent>;
  }
}

Layout.propTypes = {
  isContentLayout: PropTypes.bool,
  expanded: PropTypes.bool
};

export default Layout;
