import React from 'react';
import { CustomSider } from 'components/layouts';
import { Drawer } from 'antd';
import { Title } from '../Text';

export function SiderComponent({ children }) {
  return (
    <CustomSider
      className="pdf-viewer-sider"
      width={250}
      collapsible
      collapsedWidth={0}
      collapsed={false}
      trigger={null}
    >
      {children}
    </CustomSider>
  );
}

export function DrawerComponent({ title, children, visible, onClose }) {
  return (
    <Drawer
      title={<Title>{title}</Title>}
      placement="left"
      onClose={onClose}
      open={visible}
      bodyStyle={{ padding: 0 }}
    >
      {children}
    </Drawer>
  );
}
