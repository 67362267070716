import { makeActionCreator } from 'redux/utils';

import {
  GET_USER_TYPES_REQUEST,
  GET_USER_TYPES_SUCCESS,
  GET_USER_TYPES_FAILED,
  SET_USER_TYPE_SELECTED,
  GET_VERIFY_DATA_REQUEST,
  GET_VERIFY_DATA_SUCCESS,
  GET_VERIFY_DATA_FAILED,
  PUT_VERIFY_DATA_REQUEST,
  PUT_VERIFY_DATA_SUCCESS,
  PUT_VERIFY_DATA_FAILED,
  VERIFICATION_DATA_ATTEMPTS_COMPLETED,
  GET_USER_DATA_TO_VERIFY_REQUEST,
  GET_USER_DATA_TO_VERIFY_SUCCESS,
  GET_USER_DATA_TO_VERIFY_FAILED,
  PUT_USER_DATA_TO_VERIFY_SUCCESS,
  PUT_USER_DATA_TO_VERIFY_FAILED,
  PUT_USER_DATA_TO_VERIFY_REQUEST,
  GET_LOAN_DATA_TO_VERIFY_REQUEST,
  GET_LOAN_DATA_TO_VERIFY_SUCCESS,
  GET_LOAN_DATA_TO_VERIFY_FAILED,
  SAVE_PROVIDED_LOAN,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_FAILED,
} from './constants';

// Loan Number

export const getLoanDataToVerify = makeActionCreator(
  GET_LOAN_DATA_TO_VERIFY_REQUEST,
  'payload'
);
export const saveProvidedLoan = makeActionCreator(
  SAVE_PROVIDED_LOAN,
  'payload'
);
export const getLoanDataToVerifySuccess = makeActionCreator(
  GET_LOAN_DATA_TO_VERIFY_SUCCESS,
  'payload'
);
export const getLoanDataToVerifyFailed = makeActionCreator(
  GET_LOAN_DATA_TO_VERIFY_FAILED,
  'error'
);

// User Types

export const getUserTypes = makeActionCreator(GET_USER_TYPES_REQUEST);
export const getUserTypesSuccess = makeActionCreator(
  GET_USER_TYPES_SUCCESS,
  'payload'
);
export const getUserTypesFailed = makeActionCreator(
  GET_USER_TYPES_FAILED,
  'error'
);

export const setUserTypeSelected = makeActionCreator(
  SET_USER_TYPE_SELECTED,
  'payload'
);

export const getUserDataToVerify = makeActionCreator(
  GET_USER_DATA_TO_VERIFY_REQUEST,
  'payload'
);
export const getUserDataToVerifySuccess = makeActionCreator(
  GET_USER_DATA_TO_VERIFY_SUCCESS,
  'payload'
);
export const getUserDataToVerifyFailed = makeActionCreator(
  GET_USER_DATA_TO_VERIFY_FAILED,
  'error'
);

export const putUserDataToVerify = makeActionCreator(
  PUT_USER_DATA_TO_VERIFY_REQUEST,
  'payload'
);
export const putUserDataToVerifySuccess = makeActionCreator(
  PUT_USER_DATA_TO_VERIFY_SUCCESS,
  'payload'
);
export const putUserDataToVerifyFailed = makeActionCreator(
  PUT_USER_DATA_TO_VERIFY_FAILED,
  'error'
);

export const putProvidedLoanWithDataToVerify = makeActionCreator(
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST,
  'payload'
);
export const putProvidedLoanWithDataToVerifySuccess = makeActionCreator(
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS,
  'payload'
);
export const putProvidedLoanWithDataToVerifyFailed = makeActionCreator(
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_FAILED,
  'error'
);

export const getVerifyData = makeActionCreator(
  GET_VERIFY_DATA_REQUEST,
  'token'
);
export const getVerifyDataSuccess = makeActionCreator(
  GET_VERIFY_DATA_SUCCESS,
  'payload'
);
export const getVerifyDataFailed = makeActionCreator(
  GET_VERIFY_DATA_FAILED,
  'error'
);

export const putVerifyData = makeActionCreator(
  PUT_VERIFY_DATA_REQUEST,
  'payload'
);
export const putVerifyDataSuccess = makeActionCreator(
  PUT_VERIFY_DATA_SUCCESS,
  'payload'
);
export const putVerifyDataFailed = makeActionCreator(
  PUT_VERIFY_DATA_FAILED,
  'payload'
);

export const verificationDataCompleted = makeActionCreator(
  VERIFICATION_DATA_ATTEMPTS_COMPLETED
);
