import Loadable from 'components/common/Loadable';

const importModules = () => {
  return new Promise((resolve, reject) =>
    Promise.all([import('./Signer')])
      .then(([component, reducer, sagas]) => {
        resolve(component.default);
      })
      .catch(error => reject(error))
  );
};

export default Loadable(importModules);
