import React from 'react';
import styled from 'styled-components';

import { Alert as AlertAnt } from 'antd';

import {
  FONT_COLOR,
  FONT_WEIGHT,
  FONT_SIZE,
  FONT_SIZE_SMALL,
  FONT_SIZE_SMALLEST,
  WIDTH_BREAKPOINT_SM
} from 'theme/variables';

export const NoticeContainer = styled(AlertAnt)`
  margin-bottom: 20px;
  padding: 10px;

  .ant-alert-message {
    font-size: ${FONT_SIZE_SMALL};
  }
  .ant-alert-description {
    font-size: ${FONT_SIZE_SMALLEST};
  }
  .anticon {
    top: 15px;
    left: 18px;
    font-size: 18px;
  }

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    margin-bottom: 10px;
  }
`;
export const Alert = styled(AlertAnt)`
  &.ant-alert {
    font-size: ${FONT_SIZE};
    color: ${FONT_COLOR};
  }
  &.ant-alert.ant-alert-with-description {
    padding: 5px 5px 5px 24px;

    .ant-alert-message {
      display: none;
    }

    .ant-alert-icon {
      top: 8px;
      left: 0px;
      font-size: 18px;
    }
  }

  &.ant-alert-info {
    background-color: transparent;
    border: none;
    font-weight: ${FONT_WEIGHT};
  }

  &.ant-alert-error {
    background: #fff2f0;
    border: none;
    border-left: 4px solid #ffccc7;
    font-weight: ${FONT_WEIGHT};
  }

  &.ant-alert-info.highlighted {
    background: #fffbe6;
    border-left: 4px solid #ffc153;
    font-weight: ${FONT_WEIGHT};
  }
`;

export function PlainNotification({ notification, type = 'warning' }) {
  if (!notification || !notification.message) {
    return null;
  }

  return (
    <NoticeContainer
      message={notification.message}
      description={notification.description}
      showIcon
      type={type}
    />
  );
}

export default Alert;
