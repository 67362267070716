import React, { memo } from 'react';
import styled from 'styled-components';

import { List } from 'antd';
import { SubTitle } from 'components/common/Text';

import homeImg from 'images/background/home.jpg';

import { WIDTH_BREAKPOINT_MD } from 'theme/variables';

const ImageContainer = styled.div`
  width: 100%;
  height: 300px;

  background: url(${homeImg}) no-repeat;
  background-size: cover;
  background-position: ${props =>
    props.backgroundDirection ? props.backgroundDirection : 'left'};

  @media only screen and (min-width: ${WIDTH_BREAKPOINT_MD}) {
    display: none;
  }
`;

const data = [
  {
    title: 'More secure than paper',
    description:
      'Documents are encrypted and a complete audit trail is maintained.'
  },
  {
    title: 'No overnighting, faxing or waiting',
    description: 'Email documents for instant signature and return.'
  },
  {
    title: 'Sign documents anywhere from any device',
    description: 'A couple of taps on your smartphone or tablet and you’re done.'
  }
];

export function Properties() {
  return (
    <>
      <ImageContainer />
      <List
        bordered={false}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={
                <SubTitle>
                  <strong>{item.title}</strong>
                </SubTitle>
              }
              description={<SubTitle>{item.description}</SubTitle>}
            />
          </List.Item>
        )}
      />
    </>
  );
}

export default memo(Properties);
