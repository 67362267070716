import { makeActionCreator } from 'redux/utils';
import {
  SAVE_SETTINGS,
  REQUIRE_FAQ,
  SET_AFTER_SIGNING_MESSAGE,
  SET_CERTIFY_MESSAGE,
  SET_DISCLOSURE_ALERT,
  SET_DISCLOSURE_RESOURCE_NAME,
  SET_INFORMATION_ALERT,
  SET_SIGNATURE_ALERT,
  SET_AFTER_SIGNING_CONTACT_INFORMATION
} from './constants';

export const saveSettings = makeActionCreator(SAVE_SETTINGS, 'config');

export const setInformationAlert = makeActionCreator(
  SET_INFORMATION_ALERT,
  'payload'
);

export const setDisclosureAlert = makeActionCreator(
  SET_DISCLOSURE_ALERT,
  'payload'
);

export const setRequireFAQ = makeActionCreator(REQUIRE_FAQ, 'payload');

export const setDisclosureResourceName = makeActionCreator(
  SET_DISCLOSURE_RESOURCE_NAME,
  'payload'
);

export const setAfterSigningMessage = makeActionCreator(
  SET_AFTER_SIGNING_MESSAGE,
  'payload'
);

export const setAfterSigningContactInformation = makeActionCreator(
  SET_AFTER_SIGNING_CONTACT_INFORMATION,
  'payload'
);

export const setCertifyMessage = makeActionCreator(
  SET_CERTIFY_MESSAGE,
  'payload'
);

export const setSignatureAlert = makeActionCreator(
  SET_SIGNATURE_ALERT,
  'payload'
);
