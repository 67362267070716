import { makeActionCreator } from 'redux/utils';

import {
  SAVE_AND_SHOW_MESSAGE_PAGE,
  SAVE_NOTIFICATION_TO_SHOW
} from './constants';

export const showMessage = makeActionCreator(
  SAVE_AND_SHOW_MESSAGE_PAGE,
  'payload'
);
export const showNotification = makeActionCreator(
  SAVE_NOTIFICATION_TO_SHOW,
  'payload'
);
