import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, Radio } from 'antd';
import { Form, FormItem, FormErrorsMessage } from 'components/common/Form';
import { Title } from 'components/common/Text';
import FormButtons from '../FormButtons';
import Portal from 'components/common/Portal';
import uuidv4 from 'uuid/v4';

import { setUserTypeSelected } from 'redux/verifyUser/actions';

import {
  selectUserTypeSelected,
  selectVerifyLoanDataErrors
} from 'redux/verifyUser/selectors';

import {
  SECURITY_NUMBER_TYPE_NOMENCLATURE,
  FEIN_TYPE_NOMENCLATURE,
  USER_TYPE_BORROWER,
  USER_TYPE_COBORROWER,
  USER_TYPE_COMPANY
} from 'redux/verifyUser/constants';
import { getUnMaskedCharacters } from 'components/common/Inputs';
import { RenderCodeComponent } from '../VerifyDataForm/components';

function LoanFormItem({
  item,
  showLabel = false,
  handleOnFinish = null,
  reference = null,
  form,
  ...props
}) {
  //Get all unmascked characters annotated by 0.
  // const unMaskedLength = getUnMaskedCharacters(item.mask, /[^X-]+/g).join('')
  //   .length;

  //Get all unmascked characters annotated by 0.
  const unMaskedLength = getUnMaskedCharacters(item.mask, /[0]+/g).join('')
    .length;

  const MAX_LENGTH =
    (item.typeId && item.typeId === SECURITY_NUMBER_TYPE_NOMENCLATURE) ||
    (item.typeId && item.typeId === FEIN_TYPE_NOMENCLATURE)
      ? 4
      : null;
  let onValidation = 'onChange';
  let rules = [
    { required: item.isRequire, message: 'This field is required.' }
  ];

  const handleOnChange = e => {
    if (typeof handleOnFinish !== 'function') {
      return;
    }

    handleOnFinish();

    // if (
    //   !unMaskedLength ||
    //   (unMaskedLength && unMaskedLength === e.unmaskedValue.length)
    // ) {
    //   handleOnFinish();
    // }
  };

  if (item.regex) {
    onValidation = 'onBlur';
    rules.push({
      pattern: item.regex,
      message: 'There are validation problems.'
    });
  }

  if (item.mask) {
    return (
      <FormItem
        label={item.title}
        name={item.propertyPath}
        rules={rules}
        validateTrigger={onValidation}
        extra={item.description}
      >
        <RenderCodeComponent
          item={item}
          form={form}
          handleFinish={handleOnChange}
        />

        {/* <SpacedMaskedInput
          autoComplete="off"
          mask={item.mask.replaceAll('1', '0')}
          name={item.name}
          onChange={handleOnChange}
          ref={reference}
        /> */}
      </FormItem>
    );
  }

  return (
    <FormItem
      label={showLabel ? item.title : null}
      name={item.propertyPath}
      rules={rules}
      validateTrigger={onValidation}
    >
      <Input
        autoComplete="off"
        prefix={item.prefix ? item.prefix : null}
        placeholder={item.description}
        maxLength={MAX_LENGTH}
        ref={reference}
      />
    </FormItem>
  );
}

function LoanRadioButton({ item }) {
  return (
    <Radio value={item?.signatoryTypeId ? item.signatoryTypeId : null}>
      {item?.title ? item.title : 'Unknown field'}
    </Radio>
  );
}

function VerifyLoanData({
  loanNumber,
  data,
  checkDataFunction,
  loading,
  title
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const firstFieldRef = useRef();
  const userTypeSelected = useSelector(selectUserTypeSelected);
  const hasError = useSelector(selectVerifyLoanDataErrors);

  useEffect(() => {
    if (firstFieldRef.current || (hasError && firstFieldRef.current)) {
      firstFieldRef.current.focus();
    }
  }, [hasError]);

  const handleOnFinish = useCallback(values => {
    if (typeof checkDataFunction === 'function') {
      delete values.signatoryTypeId;
      checkDataFunction(values);
    }
  }, []);

  const handleOnSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  const handleTypeChange = useCallback(e => {
    dispatch(setUserTypeSelected(e.target.value));
  });

  const isMultipleFields = data.length === 3;
  const loanOption = data[0];
  const ssnOption = data[1];
  const feinOption = isMultipleFields ? data[2] : null;
  let formItems = [];
  let radioOptions = [
    <LoanRadioButton item={ssnOption} key={`radio-${uuidv4()}`} />
  ];

  switch (userTypeSelected) {
    case USER_TYPE_BORROWER:
    case USER_TYPE_COBORROWER:
      formItems = [
        <LoanFormItem
          item={ssnOption}
          key={uuidv4()}
          showLabel={!isMultipleFields}
          handleOnFinish={!isMultipleFields ? handleOnSubmit : null}
          reference={firstFieldRef}
          form={form}
        />
      ];
      break;
    case USER_TYPE_COMPANY:
      formItems = [
        <LoanFormItem
          item={feinOption}
          key={uuidv4()}
          showLabel={!isMultipleFields}
          handleOnFinish={!isMultipleFields ? handleOnSubmit : null}
          reference={firstFieldRef}
          form={form}
        />
      ];
      break;
    default:
      break;
  }

  if (isMultipleFields) {
    radioOptions.push(
      <LoanRadioButton item={feinOption} key={`radio-${uuidv4()}`} />
    );
  }

  // Render Form with option to select SSN or FEIN number
  return (
    <>
      {title && <Title style={{ marginBottom: '20px' }}>{title}</Title>}
      {hasError && <FormErrorsMessage description={hasError.description} />}
      <Form
        layout="vertical"
        form={form}
        onFinish={handleOnFinish}
        initialValues={{
          signatoryTypeId: userTypeSelected,
          [loanOption.propertyPath]: loanNumber
        }}
      >
        <FormItem name={loanOption.propertyPath} hidden>
          <Input />
        </FormItem>
        <FormItem
          name="signatoryTypeId"
          hidden={!isMultipleFields}
          onChange={handleTypeChange}
        >
          <Radio.Group>{radioOptions}</Radio.Group>
        </FormItem>
        {formItems}
        <Portal id="navigation-actions">
          <FormButtons loading={loading} nextOnClick={handleOnSubmit} />
        </Portal>
      </Form>
    </>
  );
}

export default memo(VerifyLoanData);
