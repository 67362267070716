import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useGetData(
  dataSelector,
  dataIsFetchingSelector,
  dataAction,
  dataActionParams = [],
  forced = false,
  condition = true
) {
  const dispatch = useDispatch();
  const [actionCalled, setActionCalled] = useState(false);

  const data = useSelector(dataSelector);
  const isFetching = useSelector(dataIsFetchingSelector);

  const canDispatchAction =
    (!data || forced) && !isFetching && condition && !actionCalled;

  useEffect(() => {
    if (canDispatchAction) {
      dispatch(dataAction(...dataActionParams));
      setActionCalled(true);
    }
  }, [canDispatchAction, dataAction, dataActionParams, dispatch]);

  return [data, isFetching, actionCalled];
}
