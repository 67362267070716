import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { USER_TYPE_DEALER } from './constants';

const selectVerifyUserDomain = () => state => state.get('verifyUser');
const selectVerifyUser = createSelector(selectVerifyUserDomain(), verifyUser =>
  verifyUser.toJS()
);

const selectUserTypeSelected = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.userTypeSelected
);

const selectIsDealer = createSelector(
  selectUserTypeSelected,
  userTypeSelected => userTypeSelected === USER_TYPE_DEALER
);

const selectUserVerified = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.userVerified
);

const selectIsUserLogged = createSelector(
  selectUserTypeSelected,
  selectUserVerified,
  (userTypeSelected, userVerified) => userTypeSelected && userVerified
);

const selectUserTypesData = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.userTypesData
);

const selectIsfetchingUserTypesData = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.isfetchingUserTypesData
);

const selectUserDataToVerify = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.userDataToVerify
);

const selectHaveUserDataToVerify = createSelector(
  selectUserDataToVerify,
  userDataToVerify => !isEmpty(userDataToVerify)
);

const selectIsFetchingUserDataToVerify = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.isFetchingUserDataToVerify
);

const selectIsFetchingPutUserDataToVerify = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.isFetchingPutUserDataToVerify
);

const selectAttemptsUserDataToVerify = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.attemptsUserDataToVerify
);

const selectIsFetchingLoanDataToVerify = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.isFetchingLoanDataToVerify
);

const selectVerifyLoanErrors = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.verifyLoanErrors
);

const selectLoanDataToVerify = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.loanDataToVerify
);

const selectHaveLoanDataToVerify = createSelector(
  selectLoanDataToVerify,
  loanDataToVerify => !isEmpty(loanDataToVerify)
);

const selectProvidedLoan = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.providedLoan
);

const selectVerifyLoanDataErrors = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.verifyLoanDataErrors
);

const selectAttemptsProvidedLoanWithDataToVerify = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.attemptsUserDataToVerify
);

const selectIsFetchingPutProvidedLoanWithDataToVerify = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.isFetchingPutProvidedLoanWithDataToVerify
);

const selectVerifyUserDataErrors = createSelector(
  selectVerifyUser,
  verifyUser => verifyUser.verifyUserDataErrors
);

export default selectVerifyUser;
export {
  selectUserTypesData,
  selectUserVerified,
  selectIsUserLogged,
  selectIsfetchingUserTypesData,
  selectUserTypeSelected,
  selectIsDealer,
  selectUserDataToVerify,
  selectIsFetchingUserDataToVerify,
  selectIsFetchingPutUserDataToVerify,
  selectAttemptsUserDataToVerify,
  selectIsFetchingLoanDataToVerify,
  selectLoanDataToVerify,
  selectProvidedLoan,
  selectAttemptsProvidedLoanWithDataToVerify,
  selectIsFetchingPutProvidedLoanWithDataToVerify,
  selectVerifyLoanDataErrors,
  selectVerifyLoanErrors,
  selectVerifyUserDataErrors,
  selectHaveUserDataToVerify,
  selectHaveLoanDataToVerify
};
