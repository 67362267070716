import { makeActionCreator } from 'redux/utils';

import {
  GET_KEY_DOCUMENT_REQUEST,
  GET_KEY_DOCUMENT_SUCCESS,
  GET_KEY_DOCUMENT_FAILED,
  SAVE_CURRENT_TOKEN,
  SAVE_USER_TOKEN,
} from './constants';

export const getKeyDocument = makeActionCreator(
  GET_KEY_DOCUMENT_REQUEST,
  'token'
);
export const getKeyDocumentSuccess = makeActionCreator(
  GET_KEY_DOCUMENT_SUCCESS,
  'payload'
);
export const getKeyDocumentFailed = makeActionCreator(
  GET_KEY_DOCUMENT_FAILED,
  'error'
);

export const saveUserToken = makeActionCreator(SAVE_USER_TOKEN, 'token');
export const saveCurrentToken = makeActionCreator(SAVE_CURRENT_TOKEN, 'token');
