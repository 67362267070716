import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'antd';
import NotFound from 'components/common/NotFound';

import {
  FONT_SIZE,
  BACKGROUND_DARK,
  LAYER_1,
  WIDTH_CENTER_CONTENT,
  WIDTH_BREAKPOINT_MD,
  WIDTH_BREAKPOINT_XL,
  WIDTH_BREAKPOINT_SM,
  WIDTH_BREAKPOINT_XS,
  LINE_HEIGHT
} from 'theme/variables';

export const Container = styled.div`
  padding: 20px;
  border: 1px solid #f0f0f0;
`;

export const LogoContainer = styled.div`
  &:hover img {
    cursor: pointer;
  }
  & .logo-subtitle {
    margin-top: 5px;
    line-height: ${LINE_HEIGHT};

    @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_MD}) {
    & {
      align-self: flex-start;
    }
  }
`;

export const LoanContainer = styled.div`
  & > * + * {
    margin-top: 15px !important;
  }
`;
export const Actions = styled.div`
  width: auto;
  text-align: right;
  display: inline-block;

  .ant-btn + .ant-btn {
    margin-left: 10px;
  }
`;

const ContentComponent = styled.div`
  background: transparent;
  padding: 20px;

  &.transparent {
    border: none;
    background: none;
    padding: 0;
    height: 100%;
  }

  &.only-title {
    height: auto;
  }

  &.content-signer {
    display: flex;
    position: fixed;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;

    width: ${WIDTH_CENTER_CONTENT};
    height: calc(100% - 200px);

    background: rgba(243, 243, 243, 1);
    padding: 0;
    border-top: 10px solid ${BACKGROUND_DARK};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    -ms-filter: 'progid:DXImageTransform.Microsoft.Shadow(Strength=12, Direction=0, Color=#000000)'; /*IE 8*/
    -moz-box-shadow: 0 -3px 12px -6px #000000; /*FF 3.5+*/
    -webkit-box-shadow: 0 -3px 12px -6px #000000; /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
    box-shadow: 0 -3px 12px -6px #000000; /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
    filter: progid:DXImageTransform.Microsoft.Shadow(Strength=12, Direction=135, Color=#000000); /*IE 5.5-7*/
    -webkit-transition: height 1s;
    -moz-transition: height 1s;
    -ms-transition: height 1s;
    -o-transition: height 1s;
    transition: height 1s;

    &.small {
      padding: 20px;
      height: 120px;
      display: flex;
      justify-content: center;
    }

    &.collapsed {
      height: 100px;
      -webkit-transition: height 1s;
      -moz-transition: height 1s;
      -ms-transition: height 1s;
      -o-transition: height 1s;
      transition: height 1s;
    }
    z-index: ${LAYER_1};
  }

  &.signer-yield {
    padding: 0;
    height: calc(100% - 100px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 20px;

    & > div:first-child {
      width: 500px;
      margin: 0 auto;
    }

    @media only screen and (max-width: ${WIDTH_BREAKPOINT_MD}) {
      height: calc(100vh - 700px);
    }
  }

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_XL}) {
    &.content-signer {
      margin: 0;
      width: 100%;
    }
  }
  @media only screen and (max-width: ${WIDTH_BREAKPOINT_MD}) {
    &.content-signer {
      height: calc(100% - 80px);

      &.collapsed {
        height: 45px;
      }
    }
  }
  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    &.content-signer.collapsed {
      height: 55px;
    }
  }
  @media only screen and (max-width: ${WIDTH_BREAKPOINT_XS}) {
    &.content-signer.collapsed {
      height: 75px;
    }
  }
`;

export const UserListContainer = styled.div``;

const ContentTitle = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & > span {
    font-size: ${FONT_SIZE};
    font-weight: normal;
    text-align: left;
  }

  &.with-divisor,
  &.only-title {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  & > span.subtitle {
    font-weight: normal;
  }
`;
const Title = styled.span`
  max-width: 75%;
`;

export class Content extends PureComponent {
  renderTitle = () => {
    const { title, subtitle, actions, content } = this.props;
    return (
      <ContentTitle
        className={
          content
            ? `${this.props.className} with-divisor`
            : `${this.props.className} without-divisor`
        }
      >
        <Title>
          <span>{title}</span>
          {subtitle ? <span className="subtitle">{subtitle}</span> : null}
        </Title>
        {actions ? <Actions>{actions}</Actions> : null}
      </ContentTitle>
    );
  };

  render() {
    const { title, content, highlight, actions } = this.props;

    return (
      <ContentComponent
        className={`${this.props.className} ${
          highlight ? 'hightlight' : 'normal'
        }`}
      >
        {title || actions ? this.renderTitle() : null}
        {content ? content : null}
      </ContentComponent>
    );
  }
}

Content.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  content: PropTypes.any,
  highlight: PropTypes.bool,
  actions: PropTypes.any
};

Content.defaultProps = {
  highlight: false,
  className: 'content'
};

export class ContentNotFound extends PureComponent {
  render() {
    const { text, icon } = this.props;

    return (
      <Content
        content={
          <NotFound>
            {icon ? <Icon type={icon} /> : null}
            <span>{text}</span>
            {this.props.children}
          </NotFound>
        }
      />
    );
  }
}

ContentNotFound.propTypes = {
  text: PropTypes.any,
  icon: PropTypes.string
};

export default Content;
