import React, { memo, useCallback } from 'react';

import { Space } from 'antd';
import { Button } from 'components/common/Buttons';

import { LogOutUserButton } from 'components/views/Main/utils';
import { useSelector } from 'react-redux';
import { selectEntryWithToken } from 'redux/app/selectors';
import { isFunction } from 'lodash';
import { SIZE_BETWEEN_ELEMENTS } from 'theme/variables';

function FormButtons({ loading, nextOnClick }) {
  const isEntryWithToken = useSelector(selectEntryWithToken);

  const handleNextOnClick = useCallback(
    e => {
      if (isFunction(nextOnClick)) {
        nextOnClick(e);
      }
    },
    [nextOnClick]
  );

  return (
    <Space size={SIZE_BETWEEN_ELEMENTS}>
      {!isEntryWithToken && <LogOutUserButton type="secondary" text="Back" />}
      <Button
        key="submit"
        htmlType="button"
        onClick={handleNextOnClick}
        type="primary"
        loading={loading}
      >
        Next
      </Button>
    </Space>
  );
}

export default memo(FormButtons);
