import React, { memo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { PhoneFilled } from '@ant-design/icons';

import { CloseButton } from 'components/common/Buttons';
import { InformationTitle, Item } from 'components/common/Text';
import DocumentData from 'components/common/DocumentData';

import { selectDataInfo, selectIsLoadingData } from 'redux/app/selectors';

import {
  FONT_SIZE_SMALL,
  BORDER_DEFAULT,
  FONT_COLOR,
  GRAY_BACKGROUND,
  BACKGROUND_DEFAULT,
  LINE_HEIGHT,
  MAIN_COLOR
} from 'theme/variables';
import { showSignerInformationSider } from 'redux/app/actions';
import { selectInformationAlert } from 'redux/settings/selectors';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  padding: 10px 10px 10px 20px;
  background: ${MAIN_COLOR};
  display: flex;
  justify-content: space-between;
  /* border: ${BORDER_DEFAULT}; */
`;

export const Note = styled.div`
  border-left: ${BORDER_DEFAULT};
  border-top: ${BORDER_DEFAULT};
  padding: 5px 10px 5px 20px;
  background: ${GRAY_BACKGROUND};
  line-height: ${LINE_HEIGHT};
  opacity: 0.7;
`;

export const Content = styled.div`
  padding: 10px 20px;
  color: ${FONT_COLOR};
  background: ${BACKGROUND_DEFAULT};
`;

function ShowData({ loading }) {
  const dispatch = useDispatch();

  const dataInfo = useSelector(selectDataInfo);
  const isLoadingData = useSelector(selectIsLoadingData);
  const informationAlert = useSelector(selectInformationAlert);

  const handleOnCloseClick = () => {
    dispatch(showSignerInformationSider(false));
  };

  return (
    <Container>
      <TitleContainer>
        <InformationTitle color="#FFFFFF">Information</InformationTitle>
        <CloseButton onClick={handleOnCloseClick} />
      </TitleContainer>
      <Scrollbars style={{ borderLeft: BORDER_DEFAULT }}>
        <Content>
          <DocumentData data={dataInfo} loading={isLoadingData} />
        </Content>
      </Scrollbars>
      <Note>
        <Item
          flexDirection="column"
          style={{ marginTop: '5px', padding: 0 }}
          size={FONT_SIZE_SMALL}
          title={informationAlert}
        />
      </Note>
    </Container>
  );
}
export default memo(ShowData);
