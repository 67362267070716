import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  FileTextOutlined,
  FormOutlined,
  FileDoneOutlined
} from '@ant-design/icons';

import { Steps, SignerDecoration } from 'components/common/Steps';

import { selectIsStatusSigned } from 'redux/app/selectors';
import { selectAssistantCurrentStep } from 'redux/assistant/selectors';

import { DOCUMENT_STATUS_SIGNED } from 'redux/app/constants';
import { WIDTH_BREAKPOINT_MD, WIDTH_BREAKPOINT_SM } from 'theme/variables';
import { useIsVerySmallDevice } from 'hooks/responsive';

const Step = Steps.Step;

const SignerContainer = styled.div`
  width: 30%;

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    & {
      width: 100%;
      /* margin-top: 10px; */
    }
  }
  @media only screen and (max-width: ${WIDTH_BREAKPOINT_MD}) and (min-width: ${WIDTH_BREAKPOINT_SM}) {
    & {
      width: 25%;
    }
  }
`;

function SignerSteps() {
  const isDocumentStatusSigned = useSelector(selectIsStatusSigned);
  const currentStep = useSelector(selectAssistantCurrentStep);
  const isVerySmallDevice = useIsVerySmallDevice();

  const step = isDocumentStatusSigned ? DOCUMENT_STATUS_SIGNED : currentStep;

  if (isDocumentStatusSigned) {
    return (
      <SignerContainer>
        <Steps className="main-steps center-aligned success" current={0}>
          <Step
            title="The document is already signed."
            icon={<FileDoneOutlined />}
          />
        </Steps>
      </SignerContainer>
    );
  }

  if (isVerySmallDevice) {
    return null;
  }

  return (
    <SignerContainer>
      <Steps className="main-steps" current={step}>
        <Step title="Review" icon={<FileTextOutlined />} />
        <Step title="Sign" icon={<FormOutlined />} />
        <Step title="Done" icon={<FileDoneOutlined />} />
      </Steps>
    </SignerContainer>
  );
}

export default memo(SignerSteps);
