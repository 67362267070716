import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { DOCUMENT_STATUS_PENDING } from 'redux/app/constants';

const selectDocumentListDomain = () => state => state.get('documentList');
const selectDocumentList = createSelector(
  selectDocumentListDomain(),
  documentList => documentList.toJS()
);

const selectDocumentListToSelect = createSelector(
  selectDocumentList,
  documentList => documentList.documentListToSelect
);

const selectDocumentListToSign = createSelector(
  selectDocumentListToSelect,
  documentListToSelect => {
    return (
      documentListToSelect?.length > 0 &&
      documentListToSelect.filter(
        document =>
          document.status.id === DOCUMENT_STATUS_PENDING &&
          document.flags.SignDocument
      )
    );
  }
);

const selectHaveDocumentPendingToSign = createSelector(
  selectDocumentListToSign,
  documentListToSign => {
    return !isEmpty(documentListToSign);
  }
);

const selectDocumentListToView = createSelector(
  selectDocumentListToSelect,
  documentListToSelect => {
    return (
      documentListToSelect &&
      documentListToSelect.length > 0 &&
      documentListToSelect.filter(
        document =>
          document.status.id !== DOCUMENT_STATUS_PENDING &&
          document.flags.ViewDocument
      )
    );
  }
);

const selectIsFetchingDocumentListToSelect = createSelector(
  selectDocumentList,
  documentList => documentList.isFetchingDocumentListToSelect
);

export default selectDocumentList;
export {
  selectDocumentListToSelect,
  selectDocumentListToSign,
  selectIsFetchingDocumentListToSelect,
  selectDocumentListToView,
  selectHaveDocumentPendingToSign
};
