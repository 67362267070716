import React from 'react';

import { SubTitle } from 'components/common/Text';
import { LogoContainer } from 'components/common/Content';

// import logoImg from 'images/logo2x.png';
import logoImg from 'images/logo.svg';
import logoImgMobile from 'images/mobile_variant.svg';
import { ROUTES } from 'consts/routes';
import { replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useIsMobile } from 'hooks/responsive';

const Logo = ({ ...props }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const width = isMobile ? '160px' : '200px';

  function handleOnClick() {
    dispatch(replace(ROUTES.HOME));
  }

  return (
    <LogoContainer>
      <img
        className="logo"
        src={isMobile ? logoImgMobile : logoImg}
        alt="Service Finance Company Logo"
        style={{ width }}
        onClick={handleOnClick}
      />
      <SubTitle className="logo-subtitle">
        Document signing tool with strong security and confidence.
      </SubTitle>
    </LogoContainer>
  );
};

export default Logo;
