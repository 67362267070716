import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckSquareOutlined, FileTextOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { Steps } from 'components/common/Steps';
import Spin from 'components/common/Spin';
import PDFVisor from 'components/common/PDFVisor';

import { setDocumentCurrentStep } from 'redux/assistant/actions';
import {
  getDocumentContentReaded,
  getDocumentsContent,
  toggleChooseDocumentsContainer
} from 'redux/app/actions';

import {
  selectDocumentData,
  selectHasMultipleDocuments,
  selectIsLoadingDocumentsContent,
  selectIsStatusSigned
} from 'redux/app/selectors';
import { selectAssistantCurrentDocumentStep } from 'redux/assistant/selectors';

import * as documentModel from 'models/document';
import documents from 'api/mocks/documents';
import { useEffectReducer } from 'components/common/FormattedCode';

const Step = Steps.Step;

function getActiveDocumentContent(currentDocumentStep, documentData) {
  if (
    !documentData ||
    !documentData.documents ||
    documentData.documents.length === 0
  ) {
    return [null, false, false];
  }

  let activeDocument = documentData.documents[currentDocumentStep];
  activeDocument.fileContent = documentModel.getContent(
    activeDocument.documentId
  );

  const isLoadingActiveDocument = activeDocument && activeDocument.isLoading;
  const activeDocumentHasError = activeDocument && activeDocument.isLoading;

  return [activeDocument, isLoadingActiveDocument, activeDocumentHasError];
}

function DocumentsList() {
  const dispatch = useDispatch();
  const documentData = useSelector(selectDocumentData);
  const currentDocumentStep = useSelector(selectAssistantCurrentDocumentStep);

  const handleOnChange = useCallback(
    current => {
      dispatch(setDocumentCurrentStep(current));
      dispatch(toggleChooseDocumentsContainer(false));
    },
    [dispatch]
  );

  return (
    <Steps
      className="documents-steps"
      direction="vertical"
      current={currentDocumentStep}
      onChange={handleOnChange}
    >
      {documentData.documents.map((item, index) => (
        <Step
          key={`doc-${index}`}
          icon={item.readed ? <CheckSquareOutlined /> : <FileTextOutlined />}
          title={item?.description || 'unknown document'}
        />
      ))}
    </Steps>
  );
}

const Viewer = memo(function ({ printable }) {
  const dispatch = useDispatch();
  const documentData = useSelector(selectDocumentData);
  const currentDocumentStep = useSelector(selectAssistantCurrentDocumentStep);
  const hasMultipleDocuments = useSelector(selectHasMultipleDocuments);

  const [
    activeDocument,
    isLoadingActiveDocument,
    activeDocumentHasError
  ] = getActiveDocumentContent(currentDocumentStep, documentData);

  const handleDocumentFinish = e => {
    dispatch(getDocumentContentReaded({ index: currentDocumentStep }));
  };

  if (isLoadingActiveDocument) {
    return <Spin spinning style={{ width: '100%' }} />;
  }

  if (isLoadingActiveDocument) {
    return <Spin spinning />;
  }

  return (
    activeDocument &&
    !isLoadingActiveDocument &&
    !activeDocumentHasError &&
    (activeDocument.fileContent ? (
      <PDFVisor
        file={activeDocument.fileContent}
        loading={isLoadingActiveDocument}
        // siderComponent={hasMultipleDocuments ? <DocumentsList /> : null}
        siderComponent={<DocumentsList />}
        handleDocumentFinish={handleDocumentFinish}
      />
    ) : null)
  );
});

function DocumentViewer() {
  const dispatch = useDispatch();
  const documentData = useSelector(selectDocumentData);
  const isStatusSigned = useSelector(selectIsStatusSigned);

  const isLoadingDocumentsContent = useSelector(
    selectIsLoadingDocumentsContent
  );

  useEffect(() => {
    // make sure documents are cleared when it mounts
    documentModel.clearDocuments();
  }, []);

  const isPrintable =
    documentData && documentData.canSaveDocuments && isStatusSigned;

  useEffect(() => {
    if (
      documentData &&
      isEmpty(documentModel.getAll()) &&
      !isLoadingDocumentsContent
    ) {
      dispatch(getDocumentsContent());
    }
  }, [documentData, isLoadingDocumentsContent, dispatch]);

  return <Viewer printable={isPrintable} />;
}

export default memo(DocumentViewer);
