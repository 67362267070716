const STATE_KEY = 'state';
const TOKEN_KEY = 'token';

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem(STATE_KEY);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(STATE_KEY, serializedState);
  } catch (error) {
    // Ignore errors
  }
};

export function saveLoginData(token) {
  sessionStorage.setItem(TOKEN_KEY, token);
}

export function clearLoginData() {
  sessionStorage.removeItem(TOKEN_KEY);
}

export function getAuthToken() {
  return sessionStorage.getItem(TOKEN_KEY);
}
