let documentContents = {};

/**
 * Clears all the documents content
 */
export function clearDocuments() {
  documentContents = {};
}

/**
 * Sets the blob content for the given documentId
 * @param {string} documentId
 * @param {Blob} content
 */
export function setContent(documentId, content) {
  if (!documentContents[documentId]) {
    documentContents[documentId] = {};
  }

  documentContents[documentId].content = content;
}

/**
 * Sets error for the given documentId
 * @param {string} documentId
 * @param {*} error
 */
export function setError(documentId, error) {
  if (!documentContents[documentId]) {
    documentContents[documentId] = {};
  }

  documentContents[documentId].error = error;
}

/**
 * Gets the content for the given documentId
 * @param {string} documentId
 */
export function getContent(documentId) {
  if (!documentContents[documentId]) {
    return null;
  }
  return documentContents[documentId].content;
}

/**
 * Gets the error for the given documentId
 * @param {string} documentId
 */
export function getError(documentId) {
  if (!documentContents[documentId]) {
    return null;
  }

  return documentContents[documentId].error;
}

/**
 * Gets all documents content
 */
export function getAll() {
  return documentContents;
}

/**
 * Removes the content for the given documentId
 * @param {string} documentId
 */
export function removeContent(documentId) {
  delete documentContents[documentId];
}
