import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { FormItem } from 'components/common/Form';
import { SubTitle } from 'components/common/Text';
import { SpacedMaskedInput } from 'components/common/Inputs';

import {
  SECURITY_NUMBER_TYPE_NOMENCLATURE,
  FEIN_TYPE_NOMENCLATURE,
  CHECK_TYPE_NOMENCLATURE,
  DECIMAL_TYPE_NOMENCLATURE,
  INPUT_TEXT_TYPE_NOMENCLATURE,
  GROUP_TYPE_NOMENCLATURE,
  SIMPLE_CHOICE_AS_SELECT_TYPE_NOMENCLATURE,
  SIMPLE_CHOICE_AS_RADIO_TYPE_NOMENCLATURE,
  RANDOM_SSN_TYPE_NOMENCLATURE
} from 'redux/verifyUser/constants';
import {
  RenderInput,
  RenderInputNumber,
  RenderCodeComponent,
  RenderSelectInput
} from './components';
import classNames from 'classnames';
import { Checkbox } from 'antd';
import { getKey } from './utils';

function RenderItemsGroup({ item, ref }) {
  const { childs = [] } = item;

  if (isEmpty(childs)) return null;

  return (
    <>
      <FormItem label={item.title} className="group" />
      {item.description && <SubTitle>{item.description}</SubTitle>}
      {childs.map((subItem, index) => {
        const classes = classNames(
          'items-group',
          index === childs.length - 1 && 'last-one'
        );

        return (
          <RenderItems
            key={getKey(subItem, index)}
            item={subItem}
            reference={ref}
            className={classes}
          />
        );
      })}
    </>
  );
}

function RenderFormItem({
  item,
  index,
  children,
  subTitle,
  validateTrigger,
  ...props
}) {
  if (!item.propertyPath) {
    return null;
  }

  let rules = [
    { required: item.isRequire, message: 'This field is required.' }
  ];

  let validateTriggerEvent = 'onChange';

  if (item.regex) {
    validateTriggerEvent = 'onBlur';
    rules.push({
      pattern: item.regex,
      message: 'There are validation problems.'
    });
  }

  return (
    <FormItem
      name={item.propertyPath}
      label={item.title}
      rules={rules}
      initialValue={item.defaultValue}
      validateTrigger={validateTrigger ? validateTriggerEvent : null}
      {...props}
    >
      {children}
    </FormItem>
  );
}

function RenderItems({ item, index, reference, form, ...props }) {
  // if (item.mask) {
  //   return (
  //     <RenderFormItem
  //       item={item}
  //       index={index}
  //       validateTrigger={true}
  //       {...props}
  //     >
  //       <SpacedMaskedInput
  //         autoComplete="off"
  //         mask={item.mask.replaceAll('1', '0')}
  //         name={item.name}
  //         ref={reference}
  //       />
  //     </RenderFormItem>
  //   );
  // }

  switch (item.typeId) {
    case RANDOM_SSN_TYPE_NOMENCLATURE:
      return (
        <RenderFormItem
          item={item}
          index={index}
          extra={item.description}
          {...props}
        >
          <RenderCodeComponent item={item} form={form} />
        </RenderFormItem>
      );
    case CHECK_TYPE_NOMENCLATURE:
      return (
        <div key={item.propertyPath}>
          <RenderFormItem
            item={item}
            index={index}
            initialValue={item.value || false}
            extra={item.description}
            label={null}
            valuePropName="checked"
            {...props}
          >
            <Checkbox>{item.title}</Checkbox>
          </RenderFormItem>
        </div>
      );
    case DECIMAL_TYPE_NOMENCLATURE:
      return (
        <RenderFormItem
          item={item}
          index={index}
          validateTrigger={true}
          {...props}
        >
          <RenderInputNumber item={item} ref={reference} />
        </RenderFormItem>
      );
    case INPUT_TEXT_TYPE_NOMENCLATURE:
    case SECURITY_NUMBER_TYPE_NOMENCLATURE:
    case FEIN_TYPE_NOMENCLATURE:
      return (
        <RenderFormItem
          item={item}
          index={index}
          validateTrigger={true}
          {...props}
        >
          <RenderInput
            item={item}
            // ref={reference}
            maxLength={item.typeId !== INPUT_TEXT_TYPE_NOMENCLATURE ? 4 : null}
          />
        </RenderFormItem>
      );
    case SIMPLE_CHOICE_AS_SELECT_TYPE_NOMENCLATURE:
      return (
        <RenderFormItem item={item} index={index} extra={item.description}>
          <RenderSelectInput item={item} form={form} />
        </RenderFormItem>
      );
    case SIMPLE_CHOICE_AS_RADIO_TYPE_NOMENCLATURE:
      return (
        <RenderFormItem item={item} index={index} extra={item.description}>
          <RenderSelectInput item={item} form={form} />
        </RenderFormItem>
      );
    case GROUP_TYPE_NOMENCLATURE:
      return <RenderItemsGroup item={item} ref={reference} />;
    default:
      return null;
  }
}

export default RenderItems;
