/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import tokensReducer from './tokens/reducer';
import messagesReducer from './messages/reducer';
import appReducer from './app/reducer';
import assistantReducer from './assistant/reducer';
import signerReducer from './signer/reducer';
import verifyUserReducer from './verifyUser/reducer';
import activeDocumentReducer from './activeDocument/reducer';
import agreementsReducer from './agreements/reducer';
import documentListReducer from './documentList/reducer';
import settingsReducer from './settings/reducer';

import history from 'utils/history';

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  return combineReducers({
    router: connectRouter(history),
    tokens: tokensReducer,
    messages: messagesReducer,
    app: appReducer,
    assistant: assistantReducer,
    signer: signerReducer,
    verifyUser: verifyUserReducer,
    activeDocument: activeDocumentReducer,
    agreements: agreementsReducer,
    documentList: documentListReducer,
    settings: settingsReducer,
    ...asyncReducers,
  });
}
