export const ROUTES = {
  HOME: '/',
  CLIENT_SELECTION: '/client-selection',
  MAIN: '/documents',
  ENTRY_POINT: '/tk/:token',
  VERIFY: '/verify',
  VERIFY_USER: '/verify/user',
  VERIFY_LOAN: '/verify/loan',
  VERIFY_DOCUMENT: '/verify/documents',
  DOCUMENTS_LIST: '/user/documents',
  DOCUMENTS_PENDING_SIGNATURE: '/user/pending-signature',
  AGREEMENTS: '/agreements',
  AGREEMENTS_DOCUMENT: '/agreements/document',
  FAQ: '/faq',
  ABOUTUS: '/about-us',
  PRIVACY_NOTES: '/privacy-notes',
  MESSAGE: '/message',
  NOT_AUTHORIZED: '/403',
  NOT_FOUND: '/404',
};
