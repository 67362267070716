import { combineReducers } from 'redux-immutable';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ASSISTANT_STEP_SIGN,
  ASSISTANT_STEP_REVIEW,
  SET_ASSISTANT_STEP,
  SET_DOCUMENTS_STEP,
  CLOSE_SIGNER,
} from './constants';
import {
  LOGOUT_USER,
  RESET_APP,
  SIGN_DOCUMENT_SUCCESS,
} from 'redux/app/constants';

function currentStepReducer(state = 0, action) {
  switch (action.type) {
    case SET_ASSISTANT_STEP:
      return action.payload;
    case CLOSE_SIGNER:
      return ASSISTANT_STEP_REVIEW;
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return 0;
    default:
      return state;
  }
}

function currentDocumentStepReducer(state = 0, action) {
  switch (action.type) {
    case SET_DOCUMENTS_STEP:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return 0;
    default:
      return state;
  }
}

function isSignerOpenReducer(state = false, action) {
  switch (action.type) {
    case SET_ASSISTANT_STEP:
      return action.payload === ASSISTANT_STEP_SIGN;
    case CLOSE_SIGNER:
    case SIGN_DOCUMENT_SUCCESS:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  currentStep: currentStepReducer,
  currentDocumentStep: currentDocumentStepReducer,
  isSignerOpen: isSignerOpenReducer,
});
