import React, { memo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

import MessageDetails from 'components/views/Messages/MessageDetails';
import Centered from 'components/layouts/Centered';
import TwoColumns from 'components/common/Content/TwoColumns';
import { Title } from 'components/common/Text';
import { Space } from 'antd';
import { SIZE_BETWEEN_ELEMENTS } from 'theme/variables';
import { Button } from 'components/common/Buttons';
import Spin from 'components/common/Spin';

import {
  setAgreementsVerified,
  setConsentReadStatus,
  setDataResumeVerified
} from 'redux/agreements/actions';

import {
  selectAgreementsVerified,
  selectDataResumeVerified,
  selectReadedConsentStatus
} from 'redux/agreements/selectors';
import {
  selectDataResume,
  selectGetResourceIsFetching,
  selectIsLoadingData
} from 'redux/app/selectors';

import Consent from './Consent';
import DataResume from './DataResume';
import {
  LogOutUserButton,
  GoPreviousVerificationStepButton
} from '../Main/utils';

import { ROUTES } from 'consts/routes';
import { Notes } from 'components/common/List';
import {
  selectDisclosureAlert,
  selectInformationAlert
} from 'redux/settings/selectors';
import { RESOURCE_DISCLOSURE } from 'redux/agreements/constants';

function AgreementsVerification({ title, actions, children }) {
  const dispatch = useDispatch();
  const dataResumeVerified = useSelector(selectDataResumeVerified);

  const handleOnScrollBottom = useCallback(() => {
    if (dataResumeVerified) {
      dispatch(setConsentReadStatus(true));
    }
  }, [dataResumeVerified]);

  return (
    <Centered>
      <TwoColumns
        title={title}
        actions={actions}
        showSecondColumn={false}
        md={18}
        handleOnScrollBottomFunction={handleOnScrollBottom}
      >
        {children}
      </TwoColumns>
    </Centered>
  );
}

AgreementsVerification = memo(AgreementsVerification);

function VerifyAgreements() {
  const dispatch = useDispatch();
  const agreementsVerified = useSelector(selectAgreementsVerified);
  const dataResumeVerified = useSelector(selectDataResumeVerified);
  const isLoadingData = useSelector(selectIsLoadingData);
  const dataResume = useSelector(selectDataResume);

  const resource = sessionStorage.getItem(RESOURCE_DISCLOSURE);
  const getResourceIsFetching = useSelector(selectGetResourceIsFetching);
  const readedConsentStatus = useSelector(selectReadedConsentStatus);

  const informationAlert = useSelector(selectInformationAlert);
  const disclosureAlert = useSelector(selectDisclosureAlert);

  useEffect(() => {
    if (dataResumeVerified && agreementsVerified) {
      dispatch(replace(ROUTES.MAIN));
    }
  }, [dataResumeVerified, agreementsVerified, dispatch]);

  const acceptDataResume = () => {
    dispatch(setDataResumeVerified());
  };

  const acceptAgreements = () => {
    dispatch(setAgreementsVerified());
  };

  if (isLoadingData) {
    return (
      <AgreementsVerification title={<Title>Loading data...</Title>}>
        <Spin spinning />
      </AgreementsVerification>
    );
  }

  if (!dataResume && !dataResumeVerified) {
    return (
      <AgreementsVerification actions={<LogOutUserButton />}>
        <DataResume />
      </AgreementsVerification>
    );
  }

  if (dataResume && !dataResumeVerified) {
    return (
      <AgreementsVerification
        title={<Title>Please confirm the information below:</Title>}
        actions={
          <Space direction="vertical" size={SIZE_BETWEEN_ELEMENTS}>
            <Notes source={[informationAlert]} reduced={true} />
            <Space size={SIZE_BETWEEN_ELEMENTS}>
              <GoPreviousVerificationStepButton type="secondary" text="No" />
              <Button
                htmlType="button"
                type="primary"
                onClick={acceptDataResume}
              >
                Yes
              </Button>
            </Space>
          </Space>
        }
      >
        <DataResume />
      </AgreementsVerification>
    );
  }

  if (!agreementsVerified) {
    return (
      <AgreementsVerification
        actions={
          <Space direction="vertical" size={SIZE_BETWEEN_ELEMENTS}>
            <Notes source={[disclosureAlert]} reduced={true} />
            <Space size={SIZE_BETWEEN_ELEMENTS}>
              <GoPreviousVerificationStepButton
                type="secondary"
                text="No, I do not consent"
              />
              <Button
                htmlType="button"
                type="primary"
                onClick={acceptAgreements}
                disabled={
                  !readedConsentStatus || !resource || getResourceIsFetching
                }
              >
                Yes, I consent
              </Button>
            </Space>
          </Space>
        }
      >
        <Consent />
      </AgreementsVerification>
    );
  }

  return (
    <AgreementsVerification>
      <MessageDetails
        title="Something was wrong"
        subtitle="Sorry, this page needs require data to be shown."
        buttonText="Back to home"
      />
    </AgreementsVerification>
  );
}

export default memo(VerifyAgreements);
