import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useIsMobile } from 'hooks/responsive';
import styled from 'styled-components';

import Divider from 'components/common/Divider';
import Logo from 'components/common/Logo';

import { Notes } from 'components/common/List';
import LoanInfo from 'components/views/LoanInfo';
import Signer from 'components/views/ContentSigner';
import Viewer from 'components/views/Viewer';
import Footer from 'components/layouts/Footer';
import Layout, {
  LeftContent,
  LeftFooter,
  LeftHeader,
  LeftLayout
} from 'components/layouts/HeaderContentFooter';

import { selectCurrentToken } from 'redux/tokens/selectors';
import {
  selectDocumentData,
  selectErrorDescription,
  selectIsStatusError,
  selectIsStatusPending
} from 'redux/app/selectors';

import SignerSteps from './SignerSteps';

import { DOCUMENT_STATUS_ERROR_MESSAGE } from 'redux/app/constants';
import { ROUTES } from 'consts/routes';
import { Title } from 'components/common/Text';
import MobileAlert from './MobileAlert';

import SignerActions from './SignerActions';
import { BACKGROUND_PDF, WIDTH_BREAKPOINT_SM } from 'theme/variables';
import { selectSignatureAlert } from 'redux/settings/selectors';

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    flex-direction: column;
  }
`;

function PendingToSign({ onToggleView }) {
  const isMobile = useIsMobile();

  const currentToken = useSelector(selectCurrentToken);

  const isDocumentStatusError = useSelector(selectIsStatusError);
  const isDocumentStatusPending = useSelector(selectIsStatusPending);
  const errorDescription = useSelector(selectErrorDescription);

  const documentData = useSelector(selectDocumentData);

  const signatureAlert = useSelector(selectSignatureAlert);

  const StepsSize = isMobile ? '100%' : '30%';

  const errorInfoSourceText = errorDescription
    ? `Sorry, the document cannot be signed at this time. ${errorDescription}. For more information contact us.`
    : DOCUMENT_STATUS_ERROR_MESSAGE;

  const renderPendingDetails = () => {
    if (!documentData?.description) {
      return null;
    }

    const component = (
      <>
        <Notes source={[signatureAlert]} />
        <Title small style={{ marginBottom: '10px' }}>
          {documentData.description}
        </Title>
      </>
    );

    return (
      <>
        <MobileAlert content={component} />
        {!isMobile ? component : null}
      </>
    );
  };

  const renderDetails = () => {
    return (
      <>
        {isDocumentStatusError || (isDocumentStatusPending && !isMobile) ? (
          <Divider />
        ) : null}
        {isDocumentStatusError ? (
          <Notes source={[errorInfoSourceText]} />
        ) : null}
        {isDocumentStatusPending ? renderPendingDetails() : null}
      </>
    );
  };

  if (!currentToken) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return (
    <>
      <Layout style={{ height: '100%', overflow: 'hidden' }}>
        <LeftLayout>
          <LeftContent>
            <LeftLayout>
              <LeftHeader style={{ padding: '20px 20px 0' }}>
                <ContentHeader>
                  <Logo />
                  <SignerSteps />
                </ContentHeader>
                {renderDetails()}
              </LeftHeader>

              <LeftContent style={{ background: BACKGROUND_PDF }}>
                <Viewer />
              </LeftContent>

              <LeftFooter>
                <SignerActions onToggleView={onToggleView} />
              </LeftFooter>
            </LeftLayout>
          </LeftContent>
          <LoanInfo />
        </LeftLayout>
        <Footer />
      </Layout>

      <Signer />
    </>
  );
}

export default memo(PendingToSign);
