import React, { useRef, useState } from 'react';
import { Input, InputNumber, Select } from 'antd';
import { getKey } from './utils';
import isEmpty from 'lodash/isEmpty';
import { SubTitle } from 'components/common/Text';
import FormattedCode, { validCharacterRegExp } from 'components/common/FormattedCode';


export function RenderCodeComponent({ item, form, handleFinish, ...props }) {
  const codeLength = (item?.mask?.match(validCharacterRegExp) || []).length;

  const handleOnComplete = value => {
    if (item?.propertyPath) {
      form.setFieldsValue({ [item.propertyPath]: value });
    }

    if (handleFinish && typeof handleFinish === 'function') {
      handleFinish();
    }
  };

  const handleOnChange = value => {
    if (item?.propertyPath && value.length !== codeLength) {
      form.setFieldsValue({ [item.propertyPath]: '' });
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <FormattedCode
        mask={item.mask}
        validate={/^[0-9]$/}
        onComplete={handleOnComplete}
        onChange={handleOnChange}
      />
    </div>
  );
}

export function RenderInput({ item, ...props }) {
  return (
    <Input
      autoComplete="off"
      defaultValue={item.defaultValue || null}
      prefix={item.prefix || null}
      placeholder={item.description || ''}
      {...props}
    />
  );
}

export function RenderInputNumber({ item, ...props }) {
  return (
    <InputNumber
      defaultValue={item.defaultValue}
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      style={{ width: '100%' }}
      prefix={item.prefix ? item.prefix : null}
      {...props}
    />
  );
}

export function RenderSelectInput({ item, form, ...props }) {
  const renderDescription = description => {
    if (isEmpty(description)) return '';

    return <SubTitle style={{ display: 'inline' }}>({description})</SubTitle>;
  };

  const options =
    item?.childs?.map(child => ({
      key: child.name || getKey(),
      value: child.defaultValue || child.value,
      label:
        (
          <span>
            {child.title} {renderDescription(child.description)}
          </span>
        ) || 'Unknown'
    })) || [];

  const handleOnChange = value => {
    if (item?.propertyPath) {
      form.setFieldsValue({ [item.propertyPath]: value });
    }
  };

  return (
    <Select
      placeholder="Please select an option."
      defaultValue={item?.defaultValue !== '' ? item.defaultValue : null}
      options={options}
      onChange={handleOnChange}
    />
  );
}
