import styled from 'styled-components';

import { Steps as StepsAntd } from 'antd';

import {
  MAIN_COLOR,
  BACKGROUND_DEFAULT,
  BORDER_DEFAULT,
  WIDTH_BREAKPOINT_LG,
  FONT_COLOR,
  FONT_SIZE,
  FONT_SIZE_SMALL,
  FONT_WEIGHT,
  ACTION_COLOR,
  ACTION_COLOR_HOVER,
  TRANSITION_DEFAULT,
  GRAY_BACKGROUND,
  WIDTH_BREAKPOINT_MD,
  FONT_SIZE_SMALLEST,
  FONT_COLOR_LIGHT,
  WIDTH_BREAKPOINT_SM,
  BACKGROUND_ACTIVE
} from 'theme/variables';

const MAIN_STEPS_PROCESS_COLOR = FONT_COLOR;

export const SignerDecoration = styled.div`
  background: rgb(22, 150, 101);
  height: 5px;
  margin: -30px 0 10px;
  border-radius: 0 0 5px 5px;

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_MD}) {
    margin: 0 0 10px;
  }
`;

export const Steps = styled(StepsAntd)`
  /* ----------------------------------------- */
  /* MAIN STEPS */
  /* ----------------------------------------- */
  font-size: ${FONT_SIZE_SMALL};

  &.main-steps.success {
    background: #ecf7ee;
  }

  &.main-steps.center-aligned {
    & {
      padding: 10px;
    }
    .ant-steps-item,
    .ant-steps-item-icon,
    .ant-steps-item-content {
      vertical-align: middle;
    }

    .ant-steps-item-icon,
    .ant-steps-item-icon > .ant-steps-icon {
      display: inline;
    }
  }
  &.main-steps {
    padding: 10px;
    /* margin-top: 10px; */
    background: ${GRAY_BACKGROUND};
    border-radius: 5px;
    /* margin-bottom: -10px; */
    min-height: 72px;

    .ant-steps-item-title {
      line-height: 20px;
    }

    .ant-steps-item-title:after {
      height: 3px;
      top: 0;
    }

    .ant-steps-item-content {
      margin-top: 0;
    }

    .ant-steps-item-tail::after {
      height: 3px;
    }

    .ant-steps-item-icon,
    .ant-steps-item-icon > .ant-steps-icon {
      font-size: 24px;
      display: block;
    }

    .ant-steps-item-process,
    .ant-steps-item-finish,
    .ant-steps-item-wait {
      &
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        font-size: ${FONT_SIZE_SMALL};
        font-weight: ${FONT_WEIGHT};
      }
    }

    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
      color: rgba(0, 0, 0, 0.85);
    }

    .ant-steps-item-finish {
      &
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: rgba(0, 0, 0, 0.45);
      }
      .ant-steps-item-icon > .ant-steps-icon {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
      &.main-steps {
        min-height: auto;
      }
    }

    @media (max-width: 480px) {
      &.ant-steps-horizontal.ant-steps-label-horizontal {
        flex-direction: row;
      }

      &.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
        height: 20px;
      }

      &.ant-steps:not(.ant-steps-vertical)
        .ant-steps-item-custom
        .ant-steps-item-icon {
        width: 0;
      }
      &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        margin-right: 0;
      }

      &.ant-steps-horizontal.ant-steps-label-horizontal
        .ant-steps-item
        .ant-steps-item-container {
        display: flex;
        flex-direction: column;
      }

      &.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
        margin: 0;
        display: none;
      }

      &.ant-steps-horizontal.ant-steps-label-horizontal
        > .ant-steps-item
        > .ant-steps-item-container
        > .ant-steps-item-tail {
        position: absolute;
        top: -1px;
        left: 20px;
        width: 100%;
        height: 2px;
        padding: 10px 30px;
      }

      &.ant-steps-horizontal.ant-steps-label-horizontal
        > .ant-steps-item
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        width: 100%;
        height: 5px;
      }
    }
  }

  /* ----------------------------------------- */
  /* DOCUMENTS STEPS */
  /* ----------------------------------------- */

  &.documents-steps {
    padding-left: 5px;

    &.ant-steps-vertical .ant-steps-item-content {
      min-height: 20px;
    }

    .ant-steps-item-container {
      display: flex;
      align-items: center;
    }

    .ant-steps-item-title {
      font-size: 14px;
      padding-right: 0;
      line-height: 20px;
    }

    &.ant-steps-vertical .ant-steps-item {
      flex: 0;
    }

    .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
      font-size: 26px;
    }

    &.ant-steps-vertical .ant-steps-item-icon {
      margin-right: 0;
    }
    &.ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      display: none;
    }
    .ant-steps-item-custom {
      padding: 10px 15px;
      background: none;
      transition: ${TRANSITION_DEFAULT};
      border-left: 4px solid transparent;

      &:hover {
        background: white;
      }

      border-left: 2px solid transparent;

      &.ant-steps-item-active {
        background: ${BACKGROUND_ACTIVE};
        border-left-color: ${MAIN_COLOR};

        .ant-steps-item-container
          > .ant-steps-item-content
          > .ant-steps-item-title,
        .ant-steps-item-icon > .ant-steps-icon {
          color: ${FONT_COLOR};
        }
      }
    }

    .ant-steps-item-custom
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: ${FONT_COLOR_LIGHT};
      font-size: ${FONT_SIZE_SMALL};
      font-weight: ${FONT_WEIGHT};
    }

    .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
      color: ${FONT_COLOR_LIGHT};
    }

    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
      > .ant-steps-item-container[role='button']:hover
      .ant-steps-item-icon
      .ant-steps-icon,
    .ant-steps-item:not(.ant-steps-item-active)
      > .ant-steps-item-container[role='button']:hover
      .ant-steps-item-title,
    .ant-steps-item:not(.ant-steps-item-active)
      > .ant-steps-item-container[role='button']:hover
      .ant-steps-item-subtitle,
    .ant-steps-item:not(.ant-steps-item-active)
      > .ant-steps-item-container[role='button']:hover
      .ant-steps-item-description {
      cursor: pointer;
      color: ${ACTION_COLOR_HOVER};
    }
    .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
`;

export const StepsContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;

  &.main-assistant-container {
    padding: 10px 20px;
    border-radius: 0 0 10px 10px;
  }

  @media only screen and (min-width: ${WIDTH_BREAKPOINT_LG}) {
    width: 80%;
  }
`;

export const Step = StepsAntd.Step;

export default Steps;
