import React from 'react';
import { isEmpty } from 'lodash';

import { Button } from 'components/common/Buttons';

import { BackToHomeButton } from '../Main/utils';
import Result from 'components/common/Result';

function Actions({ actionsSet, showDefaultActionIfEmpty = true }) {
  if (isEmpty(actionsSet)) {
    return showDefaultActionIfEmpty && <BackToHomeButton />;
  }

  let buttons = [];
  actionsSet.map((item, index) => {
    buttons.push(
      <Button
        key={`action-${index}`}
        type="primary"
        onClick={item.buttonAction}
      >
        {item.buttonText}
      </Button>
    );
  });

  return (
    <>
      {buttons}
      <BackToHomeButton />
    </>
  );
}

function MessageDetails({ onlyText = true, image = null, ...props }) {
  return <Result className={onlyText && 'only-text'} {...props} />;
}

export default MessageDetails;
