import { makeActionCreator } from 'redux/utils';
import {
  GET_DOCUMENTS_CONTENT,
  GET_DOCUMENT_DATA_REQUEST,
  GET_DOCUMENT_DATA_SUCCESS,
  GET_DOCUMENT_DATA_FAILED,
  GET_DOCUMENT_CONTENT_REQUEST,
  GET_DOCUMENT_CONTENT_SUCCESS,
  GET_DOCUMENT_CONTENT_FAILED,
  SIGN_DOCUMENT_REQUEST,
  SIGN_DOCUMENT_SUCCESS,
  SIGN_DOCUMENT_FAILED,
  SELECT_NEXT_DOCUMENT,
  SELECT_PREV_DOCUMENT,
  APP_CLOSE_MODAL,
  SET_PREVIEW_PROPERTY,
  CLOSE_SIGNER,
  SHOW_SIGNER_INFORMATION_SIDER,
  GET_DOCUMENTS_CONTENT_FAILED,
  GET_DOCUMENTS_CONTENT_FINISHED,
  LOGOUT_USER,
  TOGGLE_SHOW_SIGNED_DOCUMENTS_AFTER_SIGN,
  RESET_JUST_SIGNED,
  RESET_APP,
  ENTRY_WITH_TOKEN,
  GO_TO_PREVIOUS_VERIFICATION_STEP,
  ACCEPT_DOCUMENTS_TO_SIGN,
  MOBILE_ALERT_MESSAGE_SHOWN,
  SMART_ACTION_RUNNING,
  GET_DOCUMENT_CONTENT_READED,
  TOGGLE_SELECT_DOCUMENTS_CONTAINER
} from './constants';

export const resetApp = makeActionCreator(RESET_APP);

export const getDocumentsContent = makeActionCreator(GET_DOCUMENTS_CONTENT);

export const getDocumentsContentFailed = makeActionCreator(
  GET_DOCUMENTS_CONTENT_FAILED,
  'error'
);
export const getDocumentsContentFinished = makeActionCreator(
  GET_DOCUMENTS_CONTENT_FINISHED,
  'error'
);

export const getDocumentData = makeActionCreator(
  GET_DOCUMENT_DATA_REQUEST,
  'payload'
);
export const getDocumentDataSuccess = makeActionCreator(
  GET_DOCUMENT_DATA_SUCCESS,
  'payload'
);
export const getDocumentDataFailed = makeActionCreator(
  GET_DOCUMENT_DATA_FAILED,
  'error'
);

export const getDocumentContentRequest = makeActionCreator(
  GET_DOCUMENT_CONTENT_REQUEST,
  'payload'
);
export const getDocumentContentReaded = makeActionCreator(
  GET_DOCUMENT_CONTENT_READED,
  'payload'
);
export const getDocumentContentSuccess = makeActionCreator(
  GET_DOCUMENT_CONTENT_SUCCESS,
  'payload'
);
export const getDocumentContentFailed = makeActionCreator(
  GET_DOCUMENT_CONTENT_FAILED,
  'error'
);

export const signDocument = makeActionCreator(SIGN_DOCUMENT_REQUEST, 'payload');
export const signDocumentSuccess = makeActionCreator(
  SIGN_DOCUMENT_SUCCESS,
  'payload'
);
export const signDocumentFailed = makeActionCreator(
  SIGN_DOCUMENT_FAILED,
  'error'
);
export const toggleShowSignedDocumentsAfterSign = makeActionCreator(
  TOGGLE_SHOW_SIGNED_DOCUMENTS_AFTER_SIGN,
  'payload'
);

export const nextDocument = makeActionCreator(SELECT_NEXT_DOCUMENT);
export const previousDocument = makeActionCreator(SELECT_PREV_DOCUMENT);

export const updatePreviewProperty = makeActionCreator(
  SET_PREVIEW_PROPERTY,
  'payload'
);

export const closeSigner = makeActionCreator(CLOSE_SIGNER, 'payload');
export const closeModalContent = makeActionCreator(APP_CLOSE_MODAL);

export const showSignerInformationSider = makeActionCreator(
  SHOW_SIGNER_INFORMATION_SIDER,
  'payload'
);

export const logoutUser = makeActionCreator(LOGOUT_USER);
export const resetJustSigned = makeActionCreator(RESET_JUST_SIGNED);
export const entryWithToken = makeActionCreator(ENTRY_WITH_TOKEN);

export const goToPreviousVerificationStep = makeActionCreator(
  GO_TO_PREVIOUS_VERIFICATION_STEP
);

export const acceptDocumentsToSign = makeActionCreator(
  ACCEPT_DOCUMENTS_TO_SIGN,
  'payload'
);

export const mobileAlertMessageShown = makeActionCreator(
  MOBILE_ALERT_MESSAGE_SHOWN
);

export const executeSmartAction = makeActionCreator(SMART_ACTION_RUNNING);

export const toggleChooseDocumentsContainer = makeActionCreator(
  TOGGLE_SELECT_DOCUMENTS_CONTAINER,
  'payload'
);
