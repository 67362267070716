const { createSelector } = require('reselect');

const selectSettingsDomain = () => state => state.get('settings');

const selectSettings = createSelector(selectSettingsDomain(), settings =>
  settings.toJS()
);

const selectInformationAlert = createSelector(
  selectSettings,
  settings => settings?.informationAlert
);

const selectDisclosureAlert = createSelector(
  selectSettings,
  settings => settings?.disclosureAlert
);

const selectDisclosureResourceName = createSelector(
  selectSettings,
  settings => settings?.disclosureResourceName
);

const selectRequireFAQ = createSelector(
  selectSettings,
  settings => settings?.requireFAQ
);

const selectAfterSigningMessage = createSelector(
  selectSettings,
  settings => settings?.afterSigningMessage
);

const selectAfterSigningContactInformation = createSelector(
  selectSettings,
  settings => settings?.afterSigningContactInformation
);

const selectCertifyMessage = createSelector(
  selectSettings,
  settings => settings?.certifyMessage
);
const selectSignatureAlert = createSelector(
  selectSettings,
  settings => settings?.signatureAlert
);

export default selectSettings;
export {
  selectInformationAlert,
  selectDisclosureAlert,
  selectDisclosureResourceName,
  selectRequireFAQ,
  selectAfterSigningMessage,
  selectAfterSigningContactInformation,
  selectCertifyMessage,
  selectSignatureAlert
};
