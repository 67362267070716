import { createSelector } from 'reselect';

const selectAgreementsDomain = () => state => state.get('agreements');
const selectAgreements = createSelector(selectAgreementsDomain(), agreements =>
  agreements.toJS()
);

const selectDataResumeVerified = createSelector(
  selectAgreements,
  agreements => agreements.dataResumeVerified
);

const selectAgreementsVerified = createSelector(
  selectAgreements,
  agreements => agreements.agreementsVerified
);

const selectReadedConsentStatus = createSelector(
  selectAgreements,
  agreements => agreements.readedConsentStatus
);

export default selectAgreements;
export {
  selectAgreementsVerified,
  selectDataResumeVerified,
  selectReadedConsentStatus
};
