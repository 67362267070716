import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { useIsMobile } from 'hooks/responsive';

import DocumentData from 'components/common/DocumentData';
import MessageDetails from 'components/views/Messages/MessageDetails';

import { selectDataResume, selectIsLoadingData } from 'redux/app/selectors';

function DataResumeAgreement() {
  const dataResume = useSelector(selectDataResume);
  const isMobile = useIsMobile();
  const isLoadingData = useSelector(selectIsLoadingData);
  const directionForPresent = isMobile ? 'column' : 'row';

  if (isEmpty(dataResume)) {
    return (
      <MessageDetails
        title="Something was wrong"
        subtitle="Sorry, this page needs require data to be shown."
        buttonText="Back to home"
      />
    );
  }

  return (
    <DocumentData
      data={dataResume}
      direction={directionForPresent}
      loading={isLoadingData}
    />
  );
}

export default memo(DataResumeAgreement);
