import { takeLatest, call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import {
  getDocumentDataToVerifyRequest,
  putDocumentDataToVerifyRequest
} from 'api/SignatureAPI';

import { saveCurrentToken } from 'redux/tokens/actions';
import {
  getDocumentDataToVerifySuccess,
  getDocumentDataToVerifyFailed,
  putDocumentDataToVerifyFailed,
  putDocumentDataToVerifySuccess,
  putDocumentDataToVerify,
  getDocumentDataToVerify
} from './actions';

import {
  selectAttemptsDocumentDataToVerify,
  selectDocumentIdSelected,
  selectDocumentTypeSelected
} from './selectors';
import { selectUserTypeSelected } from 'redux/verifyUser/selectors';

import {
  GET_DOCUMENT_DATA_TO_VERIFY_REQUEST,
  PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST,
  GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS,
  SET_DOCUMENT_SELECTED
} from './constants';

import { FAILED_NOTIFICATION_MSG_TITLE_BY_DEFAULT } from 'redux/messages/constants';
import { ROUTES } from 'consts/routes';

function* getDocumentDataToVerifySaga() {
  yield takeLatest(GET_DOCUMENT_DATA_TO_VERIFY_REQUEST, function* ({
    payload
  }) {
    try {
      const response = yield call(getDocumentDataToVerifyRequest, payload);

      if (response.isSuccess) {
        yield put(getDocumentDataToVerifySuccess(response.data));
      } else {
        yield put(getDocumentDataToVerifyFailed(response));
      }
      // TODO: if have something to verify then redirect to verify document form page
      // yield put(replace(ROUTES.VERIFY_DOCUMENT));
    } catch (error) {
      yield put(getDocumentDataToVerifyFailed(error));
      if (error.message) {
        yield put(getDocumentDataToVerifyFailed(error.message));
      }
    }
  });
}

function* getDocumentDataToVerifySuccessSaga() {
  yield takeLatest(GET_DOCUMENT_DATA_TO_VERIFY_SUCCESS, function* ({
    payload
  }) {
    const documentDataToVerify = payload;
    if (documentDataToVerify && documentDataToVerify.length === 0) {
      // If there isn't nothing to verify, call verify document empty to obtain the document token
      yield put(putDocumentDataToVerify(null));
    }
    yield put(replace(ROUTES.VERIFY_DOCUMENT));
  });
}

function* setDocumentSelectedFromListSaga() {
  yield takeLatest(SET_DOCUMENT_SELECTED, function* ({ payload }) {
    const documentData = {
      documentId: payload.document.documentId,
      documentType: payload.document.documentType
    };

    yield put(getDocumentDataToVerify(documentData));
  });
}

function* putDocumentDataToVerifySaga() {
  yield takeLatest(PUT_DOCUMENT_DATA_TO_VERIFY_REQUEST, function* ({
    payload
  }) {
    try {
      const attemptsDocumentDataToVerify = yield select(
        selectAttemptsDocumentDataToVerify
      );

      const documentIdSelected = yield select(selectDocumentIdSelected);
      const documentTypeSelected = yield select(selectDocumentTypeSelected);
      const userTypeSelected = yield select(selectUserTypeSelected);

      const data = {
        identify: {
          id: documentIdSelected,
          typeId: documentTypeSelected,
          signatoryTypeId: userTypeSelected
        },
        data: payload,
        attempt: attemptsDocumentDataToVerify
      };
      const response = yield call(putDocumentDataToVerifyRequest, data);

      if (response.isSuccess) {
        yield put(putDocumentDataToVerifySuccess(payload));
        yield put(saveCurrentToken(response.data));
      } else {
        yield put(putDocumentDataToVerifyFailed(response));
      }
    } catch (error) {
      let errorMsg = FAILED_NOTIFICATION_MSG_TITLE_BY_DEFAULT;

      if (error.message) {
        errorMsg = error.message;
      }

      yield put(putDocumentDataToVerifyFailed(errorMsg));
    }
  });
}

export default [
  getDocumentDataToVerifySaga,
  setDocumentSelectedFromListSaga,
  getDocumentDataToVerifySuccessSaga,
  putDocumentDataToVerifySaga
];
