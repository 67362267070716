// import { replace, getLocation } from 'connected-react-router';
// import { clearLoginData } from 'utils/storage';

import { NOT_FOUND_NOMENCLATURE } from './errorsNomenclature';

export const HTTP_STATUS = {
  NOT_AUTHORIZED: 401,
  ACCESS_DENIED: 403,
  NOT_FOUND: 404,
  WRONG_DATA: 400
};

/**
 * Request interceptor
 * @param {Object} config
 */

export const createRequestOk = user => config => {
  const newConfig = Object.assign({}, config);

  newConfig.headers.Authorization = `Bearer ${user.access_token}`;
  return newConfig;
};

/**
 * Success response interceptor
 * @param {Object} response
 */
export function responseOk(response) {
  response.isSuccess = true;
  return response; // No logic here
}

/**
 * Error response interceptor
 * @param {Object} store
 */
export const responseError = store => error => {
  const { response } = error;
  let errorInfoSummary = {};

  if (response && response.status) {
    let message = {
      title: 'An error occured',
      description: 'The operation could not be performed.'
    };

    switch (response.status) {
      case HTTP_STATUS.NOT_AUTHORIZED:
        return Promise.reject({
          ...response,
          isNotAuthorized: true,
          errorInfoSummary
        });

      case HTTP_STATUS.ACCESS_DENIED:
        return Promise.reject({
          ...response,
          isNotAuthorized: true,
          errorInfoSummary
        });

      case HTTP_STATUS.WRONG_DATA:
        if (response.data.showToUser) {
          message.title = response.data.title;
          message.description = response.data.message;
        }

        const errorData = JSON.stringify(response.data);
        response.data.errorData = errorData;
        response.data.errorMessage = message;

        return Promise.reject({
          ...response.data,
          errorData,
          errorMessage: message,
          errorInfoSummary
        });

      case HTTP_STATUS.NOT_FOUND:
        if (!response.data) {
          return Promise.reject({
            ...response,
            notFound: true,
            errorInfoSummary
          });
        }

        if (response.data.type === NOT_FOUND_NOMENCLATURE) {
          response.data.notFound = true;
        }

        return Promise.reject({
          ...response.data,
          notFound: true,
          errorInfoSummary
        });

      default:
        break;
    }
  }

  return Promise.reject(error);
};
