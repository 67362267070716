import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Form, FormErrorsMessage } from 'components/common/Form';
import { Title } from 'components/common/Text';
import Portal from 'components/common/Portal';

import { selectVerifyUserDataErrors } from 'redux/verifyUser/selectors';
import { selectVerifyDocumentDataErrors } from 'redux/activeDocument/selectors';
import RenderItems from './items';
import FormButtons from '../FormButtons';
import { getKey } from './utils';

function VerifyUserDataForm({ data, checkDataFunction, loading, title }) {
  const [form] = Form.useForm();
  const hasErrorsVerifyUserData = useSelector(selectVerifyUserDataErrors);
  const hasErrorsVerifyDocumentData = useSelector(
    selectVerifyDocumentDataErrors
  );
  const firstFieldRef = useRef();

  let errorMsg = '';

  if (hasErrorsVerifyUserData) {
    errorMsg = hasErrorsVerifyUserData.description;
  }

  if (hasErrorsVerifyDocumentData) {
    errorMsg = hasErrorsVerifyDocumentData;
  }

  useEffect(() => {
    if (firstFieldRef.current || (errorMsg && firstFieldRef.current)) {
      firstFieldRef.current.focus();
    }
  }, [errorMsg]);

  const handleOnFinish = useCallback(values => {
    if (typeof checkDataFunction === 'function') {
      checkDataFunction(values);
    }
  }, []);

  const handleOnSubmit = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <>
      {errorMsg && <FormErrorsMessage description={errorMsg} />}
      <Form layout="vertical" form={form} onFinish={handleOnFinish}>
        {title && <Title style={{ marginBottom: '20px' }}>{title}</Title>}

        {data.map((item, index) => (
          <RenderItems
            key={getKey(item, index)}
            item={item}
            index={index}
            reference={index === 0 ? firstFieldRef : null}
            form={form}
          />
        ))}
        <Portal id="navigation-actions">
          <FormButtons loading={loading} nextOnClick={handleOnSubmit} />
        </Portal>
      </Form>
    </>
  );
}

export default memo(VerifyUserDataForm);
