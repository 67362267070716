import styled from 'styled-components';

const NotFound = styled.div`
  background: repeating-linear-gradient(
      130deg,
      rgba(166, 157, 160, 0.1),
      rgba(166, 157, 160, 0.1) 0,
      rgba(166, 157, 160, 0.01) 5px,
      rgba(255, 255, 255, 0.05) 0,
      rgba(255, 255, 255, 0.05) 5px
    ),
    linear-gradient(
      273deg,
      rgba(255, 255, 255, 0.6) 13%,
      rgba(255, 255, 255, 0.6) 100%
    );
  border: 1px solid rgba(77, 77, 77, 0.15);
  font-size: 11pt;
  font-weight: normal;
  padding: 200px;
  text-align: center;
  color: rgba(77, 77, 77, 0.5);

  & > i {
    color: rgba(77, 77, 77, 0.5);
    font-size: 36px;
    text-align: center;
    display: block;
  }

  .anticon svg {
    display: inline;
  }

  @media (max-width: 575px) {
    padding: 50px;
  }
`;

export default NotFound;
