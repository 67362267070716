import { combineReducers } from 'redux-immutable';
import { LOGOUT_USER, RESET_APP } from 'redux/app/constants';

import { SIGNER_TYPE_NAME, ACTIVE_SIGNER_METHOD_TAB } from './constants';

function activeSignerMethodReducer(state = SIGNER_TYPE_NAME, action) {
  switch (action.type) {
    case ACTIVE_SIGNER_METHOD_TAB:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
      return SIGNER_TYPE_NAME;
    default:
      return state;
  }
}

export default combineReducers({
  activeSignerMethod: activeSignerMethodReducer,
});
