import React from 'react';

import ExceptionPage from '../ExceptionPage';

import notFoundImage from 'images/404.svg';
import IMAGE_MESSAGE from 'components/common/Image';

const NotFound = () => {
  return (
    <ExceptionPage
      icon={
        <IMAGE_MESSAGE
          className="not-found-image"
          alt="Not found"
          src={notFoundImage}
        />
      }
      titlePage="Sorry, page not found."
      message={'The resource you are trying to access does not exist.'}
    />
  );
};

export default NotFound;
