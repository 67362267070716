import React from 'react';
import isEqual from 'lodash/isEqual';

import Spin from 'components/common/Spin';

const Loadable = promise =>
  class LoadableComponent extends React.Component {
    mounted = false;

    state = {
      loading: true,
      component: null,
      error: null,
    };

    shouldComponentUpdate(nextProps, nextState) {
      return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    componentDidMount() {
      this.mounted = true;

      promise()
        .then(component => {
          if (this.mounted) {
            this.setState({ component, loading: false });
          }
        })
        .catch(error => {
          if (this.mounted) {
            this.setState({ error, loading: false });
          }
        });
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const { loading, component } = this.state;
      const Component = component;

      return loading ? <Spin spinning /> : <Component {...this.props} />;
    }
  };

export default Loadable;
