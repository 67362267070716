export const SAVE_SETTINGS = 'esign/settings/SAVE_SETTINGS';
export const SET_INFORMATION_ALERT = 'esign/settings/SET_INFORMATION_ALERT';
export const SET_DISCLOSURE_ALERT = 'esign/settings/SET_DISCLOSURE_ALERT';
export const REQUIRE_FAQ = 'esign/settings/REQUIRE_FAQ';
export const SET_DISCLOSURE_RESOURCE_NAME =
  'esign/settings/SET_DISCLOSURE_RESOURCE_NAME';
export const SET_AFTER_SIGNING_MESSAGE =
  'esign/settings/SET_AFTER_SIGNING_MESSAGE';
export const SET_CERTIFY_MESSAGE = 'esign/settings/SET_CERTIFY_MESSAGE';
export const SET_SIGNATURE_ALERT = 'esign/settings/SET_SIGNATURE_ALERT';
export const SET_AFTER_SIGNING_CONTACT_INFORMATION =
  'esign/settings/SET_AFTER_SIGNING_CONTACT_INFORMATION';
