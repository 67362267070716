import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Spin from 'components/common/Spin';
import TwoColumns from 'components/common/Content/TwoColumns';

import { putProvidedLoanWithDataToVerify } from 'redux/verifyUser/actions';

import {
  selectIsFetchingUserDataToVerify,
  selectUserTypeSelected,
  selectLoanDataToVerify,
  selectProvidedLoan,
  selectIsFetchingPutProvidedLoanWithDataToVerify
} from 'redux/verifyUser/selectors';
import {
  selectIsFetchingDocumentDataToVerify,
  selectIsFetchingPutDocumentDataToVerify
} from 'redux/activeDocument/selectors';

import VerifyLoanData from './VerifyLoanData';
import MessageDetails from 'components/views/Messages/MessageDetails';

import { LogOutUserButton } from 'components/views/Main/utils';
import {
  VERIFYING_ERROR_DESCRIPTION,
  VERIFYING_ERROR_MSG
} from 'redux/app/constants';

function LoanVerification({ imageSetting }) {
  const dispatch = useDispatch();
  const providedLoan = useSelector(selectProvidedLoan);
  const loanDataToVerify = useSelector(selectLoanDataToVerify);
  const isFetchingPutProvidedLoanWithDataToVerify = useSelector(
    selectIsFetchingPutProvidedLoanWithDataToVerify
  );
  const isFetchingUserDataToVerify = useSelector(
    selectIsFetchingUserDataToVerify
  );

  const isFetchingDocumentDataToVerify = useSelector(
    selectIsFetchingDocumentDataToVerify
  );

  const isFetchingPutDocumentDataToVerify = useSelector(
    selectIsFetchingPutDocumentDataToVerify
  );

  const isFetchingVerifyingDocument =
    isFetchingDocumentDataToVerify || isFetchingPutDocumentDataToVerify;

  const handleSubmitLoanDataToVerify = useCallback(values => {
    dispatch(putProvidedLoanWithDataToVerify(values));
  }, []);

  const renderTitle = () => {
    if (isFetchingUserDataToVerify) {
      return 'Verifying account...';
    }

    if (isFetchingVerifyingDocument) {
      return 'Verifying document...';
    }

    if (loanDataToVerify) {
      return `Certain information needs to be validated for account ${providedLoan}. Please complete the following
      data.`;
    }

    return null;
  };

  const renderActions = () => {
    if (isFetchingUserDataToVerify || isFetchingVerifyingDocument) {
      return false;
    }

    if (loanDataToVerify) {
      return true;
    }

    return <LogOutUserButton />;
  };

  const renderContent = () => {
    if (isFetchingUserDataToVerify) {
      return <Spin spinning />;
    }

    if (isFetchingVerifyingDocument) {
      return null;
    }
    if (loanDataToVerify) {
      return (
        <VerifyLoanData
          data={loanDataToVerify}
          checkDataFunction={handleSubmitLoanDataToVerify}
          loading={isFetchingPutProvidedLoanWithDataToVerify}
          loanNumber={providedLoan}
        />
      );
    }

    return (
      <MessageDetails
        status="error"
        title={VERIFYING_ERROR_MSG}
        subTitle={VERIFYING_ERROR_DESCRIPTION}
      />
    );
  };

  return (
    <TwoColumns
      title={renderTitle()}
      md={18}
      imageSrc={imageSetting.source}
      imageDirection={imageSetting.position}
      actions={renderActions()}
    >
      {renderContent()}
    </TwoColumns>
  );
}

export default memo(LoanVerification);
