import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { LOCATION_CHANGE } from 'connected-react-router';

import { GET_KEY_DOCUMENT_SUCCESS } from 'redux/tokens/constants';
import {
  GET_USER_TYPES_SUCCESS,
  GET_USER_TYPES_REQUEST,
  GET_USER_TYPES_FAILED,
  GET_USER_DATA_TO_VERIFY_REQUEST,
  GET_USER_DATA_TO_VERIFY_SUCCESS,
  GET_USER_DATA_TO_VERIFY_FAILED,
  PUT_USER_DATA_TO_VERIFY_REQUEST,
  PUT_USER_DATA_TO_VERIFY_FAILED,
  PUT_USER_DATA_TO_VERIFY_SUCCESS,
  SET_USER_TYPE_SELECTED,
  GET_LOAN_DATA_TO_VERIFY_REQUEST,
  GET_LOAN_DATA_TO_VERIFY_SUCCESS,
  GET_LOAN_DATA_TO_VERIFY_FAILED,
  SAVE_PROVIDED_LOAN,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS,
  PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_FAILED
} from './constants';
import { LOGOUT_USER, RESET_APP } from 'redux/app/constants';

const isFetchingLoanDataToVerifyReducer = (state = false, action) => {
  switch (action.type) {
    case GET_LOAN_DATA_TO_VERIFY_REQUEST:
      return true;
    case GET_LOAN_DATA_TO_VERIFY_SUCCESS:
    case GET_LOAN_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const loanDataToVerifyReducer = (state = null, action) => {
  switch (action.type) {
    case GET_LOAN_DATA_TO_VERIFY_SUCCESS:
      return fromJS(action.payload);
    case GET_LOAN_DATA_TO_VERIFY_REQUEST:
    case GET_LOAN_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const providedLoanReducer = (state = null, action) => {
  switch (action.type) {
    case SAVE_PROVIDED_LOAN:
      return fromJS(action.payload);
    case GET_LOAN_DATA_TO_VERIFY_REQUEST:
    case GET_LOAN_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const userTypeSelectedReducer = (state = null, action) => {
  switch (action.type) {
    case GET_KEY_DOCUMENT_SUCCESS:
      return fromJS(action.payload.signatoryTypeId);
    case SET_USER_TYPE_SELECTED:
      return fromJS(action.payload);
    case GET_LOAN_DATA_TO_VERIFY_SUCCESS:
      return fromJS(action.payload[1].signatoryTypeId);
    case GET_USER_TYPES_REQUEST:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const userVerifiedReducer = (state = false, action) => {
  switch (action.type) {
    case GET_KEY_DOCUMENT_SUCCESS:
      return fromJS(action.payload.isSignatoryVerified);
    case GET_USER_DATA_TO_VERIFY_REQUEST:
    case GET_USER_DATA_TO_VERIFY_FAILED:
    case GET_USER_DATA_TO_VERIFY_REQUEST:
    case GET_USER_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const userTypesDataReducer = (state = null, action) => {
  switch (action.type) {
    case GET_USER_TYPES_SUCCESS:
      return fromJS(action.payload);
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const isfetchingUserTypesDataReducer = (state = false, action) => {
  switch (action.type) {
    case GET_USER_TYPES_REQUEST:
      return true;
    case GET_USER_TYPES_SUCCESS:
    case GET_USER_TYPES_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const userDataToVerifyReducer = (state = null, action) => {
  switch (action.type) {
    case GET_USER_DATA_TO_VERIFY_SUCCESS:
      return fromJS(action.payload);
    case GET_USER_TYPES_REQUEST:
    case GET_USER_DATA_TO_VERIFY_REQUEST:
    case GET_USER_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const isFetchingUserDataToVerifyReducer = (state = false, action) => {
  switch (action.type) {
    case GET_USER_DATA_TO_VERIFY_REQUEST:
      return true;
    case GET_USER_DATA_TO_VERIFY_SUCCESS:
    case GET_USER_DATA_TO_VERIFY_FAILED:
    case PUT_USER_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const isFetchingPutUserDataToVerifyReducer = (state = false, action) => {
  switch (action.type) {
    case PUT_USER_DATA_TO_VERIFY_REQUEST:
      return true;
    case PUT_USER_DATA_TO_VERIFY_SUCCESS:
    case PUT_USER_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const attemptsUserDataToVerifyReducer = (state = 0, action) => {
  switch (action.type) {
    case PUT_USER_DATA_TO_VERIFY_REQUEST:
      return state + 1;
    case PUT_USER_DATA_TO_VERIFY_SUCCESS:
    case LOGOUT_USER:
    case RESET_APP:
      return 0;
    default:
      return state;
  }
};

const verifyUserDataErrorsReducer = (state = null, action) => {
  switch (action.type) {
    case PUT_USER_DATA_TO_VERIFY_FAILED:
      return fromJS(action.error);
    case PUT_USER_DATA_TO_VERIFY_SUCCESS:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
};

const attemptsProvidedLoanWithDataToVerifyReducer = (state = 0, action) => {
  switch (action.type) {
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST:
      return state + 1;
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS:
    case LOGOUT_USER:
    case RESET_APP:
      return 0;
    default:
      return state;
  }
};

const isFetchingPutProvidedLoanWithDataToVerifyReducer = (
  state = false,
  action
) => {
  switch (action.type) {
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_REQUEST:
      return true;
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS:
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return false;
    default:
      return state;
  }
};

const verifyLoanDataErrorsReducer = (state = null, action) => {
  switch (action.type) {
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_FAILED:
      return fromJS(action.error);
    case PUT_PROVIDED_LOAN_WITH_DATA_TO_VERIFY_SUCCESS:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
};

const verifyLoanErrorsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_LOAN_DATA_TO_VERIFY_FAILED:
      return fromJS(action.error);
    case GET_LOAN_DATA_TO_VERIFY_SUCCESS:
    case LOGOUT_USER:
    case RESET_APP:
    case LOCATION_CHANGE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  loanDataToVerify: loanDataToVerifyReducer,
  isFetchingLoanDataToVerify: isFetchingLoanDataToVerifyReducer,
  userTypeSelected: userTypeSelectedReducer,
  userVerified: userVerifiedReducer,
  userTypesData: userTypesDataReducer,
  isfetchingUserTypesData: isfetchingUserTypesDataReducer,
  userDataToVerify: userDataToVerifyReducer,
  isFetchingUserDataToVerify: isFetchingUserDataToVerifyReducer,
  isFetchingPutUserDataToVerify: isFetchingPutUserDataToVerifyReducer,
  attemptsUserDataToVerify: attemptsUserDataToVerifyReducer,
  providedLoan: providedLoanReducer,
  attemptsProvidedLoanWithDataToVerify: attemptsProvidedLoanWithDataToVerifyReducer,
  isFetchingPutProvidedLoanWithDataToVerify: isFetchingPutProvidedLoanWithDataToVerifyReducer,
  verifyLoanDataErrors: verifyLoanDataErrorsReducer,
  verifyLoanErrors: verifyLoanErrorsReducer,
  verifyUserDataErrors: verifyUserDataErrorsReducer
});
