import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Space, Avatar } from 'antd';
import TwoColumns from 'components/common/Content/TwoColumns';
import Centered from 'components/layouts/Centered';
import {
  GoToDocumentList,
  GoPreviousVerificationStepButton,
  LogOutUserButton
} from '../utils';
import List, { ListItem } from 'components/common/List';
import { Button, SelectFocusList } from 'components/common/Buttons';

import { selectDocuments, selectGeneralDescription } from 'redux/app/selectors';

import { FilePdfOutlined } from '@ant-design/icons';

import { FONT_SIZE_SMALL, SIZE_BETWEEN_ELEMENTS } from 'theme/variables';
import { selectIsDealer } from 'redux/verifyUser/selectors';
import {
  selectAfterSigningContactInformation,
  selectAfterSigningMessage
} from 'redux/settings/selectors';

const DocumentListItem = memo(({ item, index, onClick }) => {
  const handleOnClick = useCallback(
    e => {
      if (typeof onClick === 'function') {
        onClick(e, index);
      }
    },
    [index]
  );

  return (
    <ListItem key={item.id} onClick={handleOnClick}>
      <List.Item.Meta
        avatar={<Avatar shape="square" icon={<FilePdfOutlined />} />}
        title={item.description}
        description={
          item.status?.description ? (
            <div style={{ fontSize: FONT_SIZE_SMALL }}>
              <div style={{ display: 'block' }}>{item.status.description}</div>
              {item.description && <strong>{item.description}</strong>}
            </div>
          ) : null
        }
      />
    </ListItem>
  );
});

const renderListItem = (item, index, onClick) => {
  return item ? (
    <DocumentListItem item={item} index={index} onClick={onClick} />
  ) : null;
};

function SignedDocuments({ onToggleView }) {
  const data = useSelector(selectDocuments);
  const description = useSelector(selectGeneralDescription);
  const isDealer = useSelector(selectIsDealer);
  const afterSigningMessage = useSelector(selectAfterSigningMessage);
  const afterSigningContactInformation = useSelector(
    selectAfterSigningContactInformation
  );

  const handleViewDocuments = (e, documentIndex) => {
    onToggleView(documentIndex);
  };

  return (
    <Centered>
      <TwoColumns
        showSecondColumn={false}
        md={18}
        title="Thank you"
        actions={
          <Space size={SIZE_BETWEEN_ELEMENTS}>
            <Button type="secondary" onClick={handleViewDocuments}>
              Back
            </Button>
            {isDealer ? (
              <LogOutUserButton type="primary" />
            ) : (
              <GoToDocumentList type="primary" />
              // <GoPreviousVerificationStepButton type="primary" text="Close" />
            )}
          </Space>
        }
      >
        <p>{afterSigningMessage}</p>
        <p>{afterSigningContactInformation}</p>
        <SelectFocusList onKeyPress={handleViewDocuments}>
          <List
            dataSource={data}
            renderItem={(item, index) =>
              renderListItem(item, index, handleViewDocuments)
            }
          />
        </SelectFocusList>
      </TwoColumns>
    </Centered>
  );
}

export default memo(SignedDocuments);
