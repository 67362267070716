import React, { memo } from 'react';

import { Skeleton } from 'antd';
import { Item } from 'components/common/Text';

import { FONT_SIZE_SMALL } from 'theme/variables';

function DocumentData({ data, direction, loading }) {
  if (!data) {
    return null;
  }

  if (loading) {
    return <Skeleton />;
  }

  let properties = [];
  for (const item in data) {
    const isBooleanProperty = typeof data[item] === 'boolean';
    if (!isBooleanProperty || (isBooleanProperty && data[item])) {
      properties.push(
        <Item
          key={item}
          flexDirection={direction ? direction : 'column'}
          title={isBooleanProperty ? null : item}
          size={FONT_SIZE_SMALL}
        >
          {isBooleanProperty ? item : data[item]}
        </Item>
      );
    }
  }

  return properties;
}

export default memo(DocumentData);
