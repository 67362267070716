import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsExtraSmallDevice, useIsMobile } from 'hooks/responsive';
import classNames from 'classnames';

import { Space, Col, Row } from 'antd';
import { Checkbox } from 'components/common/Inputs';
import { Button } from 'components/common/Buttons';
import { Title } from 'components/common/Text';

import { setAssistantCurrentStep } from 'redux/assistant/actions';
import {
  GoToDocumentList,
  GoPreviousVerificationStepButton
} from 'components/views/Main/utils';

import {
  acceptDocumentsToSign,
  showSignerInformationSider
} from 'redux/app/actions';

import {
  selectAllDocumentsAreReaded,
  selectDocumentsToSignAccepted,
  selectIsStatusPending,
  selectJustSigned
} from 'redux/app/selectors';
import { selectIsSignerOpen } from 'redux/assistant/selectors';

import { SIGN_DOCUMENT_TEXT } from 'redux/signer/constants';
import { ASSISTANT_STEP_SIGN } from 'redux/assistant/constants';
import { ACTION_COLOR, SIZE_BETWEEN_ELEMENTS } from 'theme/variables';
import { selectCertifyMessage } from 'redux/settings/selectors';
import { Notes } from 'components/common/List';

function InformationButton({ disable = false }) {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(showSignerInformationSider(true));
  };

  return (
    <Button type="secondary" onClick={handleOnClick} disabled={disable}>
      Information
    </Button>
  );
}

function CloseDocumentsButton({ onToggleView, disable = false }) {
  const handleCloseDocuments = () => {
    onToggleView();
  };

  return (
    <Button type="primary" onClick={handleCloseDocuments} disabled={disable}>
      Close documents
    </Button>
  );
}

function SignDocumentsButton({ ...props }) {
  const dispatch = useDispatch();
  const isExtraSmallDevice = useIsExtraSmallDevice();

  const handleOnSignClick = useCallback(() => {
    dispatch(setAssistantCurrentStep(ASSISTANT_STEP_SIGN));
  }, []);

  return (
    <Button type="primary" onClick={handleOnSignClick} {...props}>
      {isExtraSmallDevice ? 'Sign' : SIGN_DOCUMENT_TEXT}
    </Button>
  );
}

function RulesToSign() {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isDocumentStatusPending = useSelector(selectIsStatusPending);
  const isSignerOpen = useSelector(selectIsSignerOpen);
  const documentsToSignAccepted = useSelector(selectDocumentsToSignAccepted);
  const pendingToReadDocumentsText =
    'To proceed with signing, you must scroll to the end of the documents.';

  const checkBoxClasses = classNames({
    blink_child: !documentsToSignAccepted
  });
  const checkBoxTextClasses = classNames({
    blink_item: !documentsToSignAccepted
  });
  const allDocumentsAreReaded = useSelector(selectAllDocumentsAreReaded);

  const certifyMessage = useSelector(selectCertifyMessage);

  const handleCertify = useCallback(() => {
    dispatch(acceptDocumentsToSign(!documentsToSignAccepted));
  }, [documentsToSignAccepted]);

  if (!(isDocumentStatusPending && !documentsToSignAccepted)) {
    return null;
  }

  return allDocumentsAreReaded ? (
    <Col
      xs={24}
      hidden={isMobile && documentsToSignAccepted}
      style={{ textAlign: 'center' }}
    >
      <Checkbox
        onChange={handleCertify}
        className={checkBoxClasses}
        checked={documentsToSignAccepted}
        defaultChecked={false}
        disabled={isSignerOpen}
      >
        <Title small soft display="inline" className={checkBoxTextClasses}>
          {certifyMessage}
        </Title>
      </Checkbox>
    </Col>
  ) : (
    <Notes source={[pendingToReadDocumentsText]} />
  );
}
// {isDocumentStatusPending &&
//     !documentsToSignAccepted &&
//     allDocumentsAreReaded ? (
//       <Col
//         xs={24}
//         hidden={isMobile && documentsToSignAccepted}
//         style={{ textAlign: 'center' }}
//       >
//         <Checkbox
//           onChange={handleCertify}
//           className={checkBoxClasses}
//           checked={documentsToSignAccepted}
//           defaultChecked={false}
//           disabled={isSignerOpen}
//         >
//           <Title small soft display="inline" className={checkBoxTextClasses}>
//             {certifyMessage}
//           </Title>
//         </Checkbox>
//       </Col>
//     ) : (
//       <Notes source={[errorInfoSourceText]} />
//     )}

function SignerActions({ onToggleView }) {
  const isSignedJustNow = useSelector(selectJustSigned);
  const documentsToSignAccepted = useSelector(selectDocumentsToSignAccepted);
  const isDocumentStatusPending = useSelector(selectIsStatusPending);
  const isSignerOpen = useSelector(selectIsSignerOpen);

  return (
    <Row gutter={[16, 8]} align="middle">
      <RulesToSign />
      <Col xs={24}>
        <Space size={SIZE_BETWEEN_ELEMENTS}>
          {isDocumentStatusPending && (
            <SignDocumentsButton
              disabled={!documentsToSignAccepted || isSignerOpen}
            />
          )}
          {<InformationButton disable={isSignerOpen} />}
          {/* Close documents when the document is pending to sign, 
        redirect to documents list but when the document is signed, 
        show document signed component with thank you */}
          {isSignedJustNow ? (
            <CloseDocumentsButton onToggleView={onToggleView} />
          ) : (
            <GoToDocumentList type="secondary" disabled={isSignerOpen} />
            // <GoPreviousVerificationStepButton
            //   type="secondary"
            //   text="Close"
            //   disable={isSignerOpen}
            // />
          )}
        </Space>
      </Col>
    </Row>
  );
}

export default memo(SignerActions);
