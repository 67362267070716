import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Drawer } from 'antd';

import { selectViewSignerInformationSider } from 'redux/app/selectors';
import { showSignerInformationSider } from 'redux/app/actions';

import ShowData from './ShowData';

function DrawerInfo() {
  const dispatch = useDispatch();
  const viewSignerInformationSider = useSelector(selectViewSignerInformationSider);

  const handleOnCloseClick = () => {
    dispatch(showSignerInformationSider(false));
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={handleOnCloseClick}
      open={viewSignerInformationSider}
      bodyStyle={{ padding: 0 }}
    >
      <ShowData />
    </Drawer>
  );
}
export default memo(DrawerInfo);
