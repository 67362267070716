import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import {
  GET_DOCUMENT_LIST_TO_SELECT_REQUEST,
  GET_DOCUMENT_LIST_TO_SELECT_SUCCESS,
  GET_DOCUMENT_LIST_TO_SELECT_FAILED,
} from './constants';
import { LOGOUT_USER, RESET_APP } from 'redux/app/constants';
import { LOCATION_CHANGE } from 'connected-react-router';

const documentListToSelectReducer = (state = null, action) => {
  switch (action.type) {
    case GET_DOCUMENT_LIST_TO_SELECT_SUCCESS:
      return fromJS(action.payload);
    case GET_DOCUMENT_LIST_TO_SELECT_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const isFetchingDocumentListToSelectReducer = (state = false, action) => {
  switch (action.type) {
    case GET_DOCUMENT_LIST_TO_SELECT_REQUEST:
      return true;
    case GET_DOCUMENT_LIST_TO_SELECT_SUCCESS:
    case GET_DOCUMENT_LIST_TO_SELECT_FAILED:
    case LOCATION_CHANGE:
    case LOGOUT_USER:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  documentListToSelect: documentListToSelectReducer,
  isFetchingDocumentListToSelect: isFetchingDocumentListToSelectReducer,
});
