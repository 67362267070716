import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button as ButtonAnt, Modal } from 'antd';
import { useIsMobile } from 'hooks/responsive';
import { InfoCircleOutlined } from '@ant-design/icons';
import { BACKGROUND_INFO, COLOR_ICON_INFO, FONT_WEIGHT } from 'theme/variables';
import { selectMobileAlertMessageShown } from 'redux/app/selectors';
import { mobileAlertMessageShown } from 'redux/app/actions';

const Button = styled(ButtonAnt)`
  background: ${BACKGROUND_INFO};
  border: none;
  float: left;
  margin-right: 5px;

  .anticon {
    color: ${COLOR_ICON_INFO};
    font-weight: ${FONT_WEIGHT};
  }

  .ant-btn:active,
  .ant-btn.active {
    color: ${COLOR_ICON_INFO};
    background-color: ${BACKGROUND_INFO};
    border-color: none;
  }

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    background-color: ${BACKGROUND_INFO};
  }
`;

const showInfo = content => {
  Modal.info({
    content,
    closable: true,
    footer: null,
    className: 'mobile-alert',
    icon: null
  });
};

function MobileAlert({ content }) {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const shown = useSelector(selectMobileAlertMessageShown);

  useEffect(() => {
    if (isMobile && !shown) {
      showInfo(content);
      dispatch(mobileAlertMessageShown());
    }
  }, [isMobile, shown]);

  return null;
}

export default MobileAlert;
