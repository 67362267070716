import { isEmpty } from 'lodash';
import { selectIsFetchingGettingKeyReducer } from 'redux/app/selectors';
import {
  GROUP_TYPE_NOMENCLATURE,
  SIMPLE_CHOICE_AS_RADIO_TYPE_NOMENCLATURE,
  SIMPLE_CHOICE_AS_SELECT_TYPE_NOMENCLATURE,
  SIMPLE_CHOICE_GROUP
} from 'redux/verifyUser/constants';
import { createSelector } from 'reselect';

const selectVerifyDocumentDomain = () => state => state.get('activeDocument');
const selectVerifyDocument = createSelector(
  selectVerifyDocumentDomain(),
  verifyDocument => verifyDocument.toJS()
);

const selectDocumentIdSelected = createSelector(
  selectVerifyDocument,
  verifyDocument => verifyDocument.documentIdSelected
);

const selectDocumentTypeSelected = createSelector(
  selectVerifyDocument,
  verifyDocument => verifyDocument.documentTypeSelected
);

const selectDocumentVerified = createSelector(
  selectVerifyDocument,
  verifyDocument => verifyDocument.documentVerified
);

const selectDocumentDataToVerify = createSelector(
  selectVerifyDocument,
  verifyDocument => verifyDocument.documentDataToVerify
);

const selectDocumentDataToVerifyInOrder = createSelector(
  selectDocumentDataToVerify,
  documentDataToVerify => {
    // Find the index of the "StatesGroup" object
    if (isEmpty(documentDataToVerify)) return [];

    let groups = [];
    let noGroups = [];
    let groupIds = [];
    let simpleChoiceGroup = [];

    documentDataToVerify.forEach(item => {
      switch (item.typeId) {
        case GROUP_TYPE_NOMENCLATURE:
          groups.push(item);
          groupIds.push(item.groupId);
          break;
        case SIMPLE_CHOICE_AS_SELECT_TYPE_NOMENCLATURE:
        case SIMPLE_CHOICE_AS_RADIO_TYPE_NOMENCLATURE:
          simpleChoiceGroup.push(item);
          groupIds.push(item.groupId);
          break;

        default:
          noGroups.push(item);
          break;
      }
    });

    groups.map(
      group =>
        (group.childs = noGroups.filter(item => item.groupId === group.groupId))
    );

    simpleChoiceGroup.map(
      group =>
        (group.childs = noGroups.filter(item => item.groupId === group.groupId))
    );

    const documentsWithoutGroup = documentDataToVerify.filter(
      data => !groupIds.includes(data.groupId)
    );

    return [...documentsWithoutGroup, ...groups, ...simpleChoiceGroup];
  }
);

const selectDocumentDataToVerifyError = createSelector(
  selectVerifyDocument,
  verifyDocument => verifyDocument.documentDataToVerifyError
);

const selectHaveDocumentDataToVerify = createSelector(
  selectDocumentDataToVerify,
  documentDataToVerify => !isEmpty(documentDataToVerify)
);

const selectIsFetchingDocumentDataToVerify = createSelector(
  selectVerifyDocument,
  selectIsFetchingGettingKeyReducer,
  (verifyDocument, isFetchingGettingKeyReducer) =>
    verifyDocument.isFetchingDocumentDataToVerify || isFetchingGettingKeyReducer
);

const selectIsFetchingPutDocumentDataToVerify = createSelector(
  selectVerifyDocument,
  verifyDocument => verifyDocument.isFetchingPutDocumentDataToVerify
);

const selectAttemptsDocumentDataToVerify = createSelector(
  selectVerifyDocument,
  verifyDocument => verifyDocument.attemptsDocumentDataToVerify
);

const selectVerifyDocumentDataErrors = createSelector(
  selectVerifyDocument,
  verifyDocument => verifyDocument.verifyDocumentDataErrors
);

export default selectVerifyDocument;
export {
  selectDocumentIdSelected,
  selectDocumentTypeSelected,
  selectDocumentVerified,
  selectDocumentDataToVerifyError,
  selectDocumentDataToVerify,
  selectDocumentDataToVerifyInOrder,
  selectIsFetchingDocumentDataToVerify,
  selectIsFetchingPutDocumentDataToVerify,
  selectAttemptsDocumentDataToVerify,
  selectHaveDocumentDataToVerify,
  selectVerifyDocumentDataErrors
};
