import { makeActionCreator } from 'redux/utils';
import {
  SET_ASSISTANT_STEP,
  SET_DOCUMENTS_STEP,
  CLOSE_SIGNER
} from './constants';

export const setAssistantCurrentStep = makeActionCreator(
  SET_ASSISTANT_STEP,
  'payload'
);

export const setDocumentCurrentStep = makeActionCreator(
  SET_DOCUMENTS_STEP,
  'payload'
);

export const closeSigner = makeActionCreator(CLOSE_SIGNER);
