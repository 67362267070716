import {
  regularAxios,
  authorizedAxios,
  userAxios,
  resourceAxios
} from '../axios';

import docData, {
  docContents,
  dataSigned,
  getVerifyData,
  putVerifyData,
  keyDocumentData,
  userTokenSimulated,
  documentList,
  documentTokenSimulated,
  userVerifyData,
  loanVerifyData
} from '../mocks/documentData';

// const KEY_URL = process.env.REACT_APP_KEY_MODEL_URL;
// const KEY_TEST = '';

/**
 * Get User types
 */
export function getUserTypesRequest() {
  return regularAxios.get(`/v1/ESign/User/Types`);
  // return new Promise(resolve =>
  //   setTimeout(
  //     () =>
  //       resolve({
  //         data: [],
  //         // data: userVerifyData,
  //         isSuccess: false
  //       }),
  //     500
  //   )
  // );
}

/**
 * Getting token disintegration
 */
export function getKeyDocumentRequest() {
  return authorizedAxios.get(`/v1/ESign/Key/Document`);
  // return new Promise(resolve =>
  //   setTimeout(
  //     () =>
  //       resolve({
  //         // data: [],
  //         data: keyDocumentData,
  //         isSuccess: true
  //       }),
  //     500
  //   )
  // );
}

/**
 * Get User Data to Verify
 * @params {string} UserId
 */
export function getUserDataToVerifyRequest(userType) {
  return authorizedAxios.get(`/v1/ESign/User/Verify?UserType=${userType}`);
  // return new Promise(resolve =>
  //   setTimeout(
  //     () =>
  //       resolve({
  //         data: [],
  //         data: userVerifyData,
  //         isSuccess: true
  //       }),
  //     500
  //   )
  // );
}

/**
 * Put User Data to Verify
 * @params {obj} data
 * {
 *    "identify": {
 *    "userId": "string"
 *  },
 *  "data": {
 *    "additionalProp1": "string",
 *    "additionalProp2": "string",
 *    "additionalProp3": "string"
 *  },
 *    "attempt": 0
 *  }
 */
export function putUserDataToVerifyRequest(data) {
  return authorizedAxios.put(`/v1/ESign/User/Verify`, data);
}
// export function putUserDataToVerifyRequest() {
//   return new Promise(resolve =>
//     setTimeout(
//       () =>
//         resolve({
//           data: userTokenSimulated,
//           isSuccess: true
//         }),
//       500
//     )
//   );
// }

/**
 * Get Data to Verify
 * @param {string} token
 */
export function getDocumentDataToVerifyRequest({ documentId, documentType }) {
  return userAxios.get(
    `/v1/ESign/Document/Verify?Id=${documentId}&&TypeId=${documentType}`
  );
  // return new Promise(resolve =>
  //   setTimeout(
  //     () =>
  //       resolve({
  //         data: getVerifyData,
  //         isSuccess: false
  //       }),
  //     500
  //   )
  // );
}

/**
 * Put Verify Data
 * @param {object} data
 */
export function putDocumentDataToVerifyRequest(payload) {
  return userAxios.put(`/v1/ESign/Document/Verify`, payload);
  // return new Promise(resolve =>
  //   setTimeout(
  //     () =>
  //       resolve({
  //         data: putVerifyData,
  //         isSuccess: false
  //       }),
  //     500
  //   )
  // );
}

/**
 * Get Document List to Select by userTK
 */
export function getDocumentListToSelectRequest() {
  return userAxios.get(`/v1/ESign/User/Documents`);
  // return new Promise(resolve =>
  //   setTimeout(
  //     () =>
  //       resolve({
  //         data: documentList,
  //         isSuccess: true,
  //       }),
  //     500
  //   )
  // );
}

/**
 * Gets document's data from given key
 */
export function getDocumentDataRequest() {
  return authorizedAxios.get('/v1/ESign/Document/Data');
  // return new Promise(resolve =>
  //   setTimeout(
  //     () =>
  //       resolve({
  //         data: docData,
  //         isSuccess: true
  //       }),
  //     500
  //   )
  // );
}

/**
 * Gets document's content
 * @param {string} documentId
 */
export function getDocumentContent(documentId) {
  return authorizedAxios.get(`/v1/ESign/Document?DocumentId=${documentId}`, {
    responseType: 'arraybuffer'
  });
}

/**
 * Signs documents
 * @param {object} data
 */
export function signDocuments(data) {
  return authorizedAxios.put(`/v1/ESign/Document`, data);
}

/**
 * Get Loan Data to Verify
 * @params {string} loanNumber
 */
export function getLoanDataToVerifyRequest(loanNumber) {
  return authorizedAxios.get(
    `/v1/ESignInitialDocuments/User/Verify/${loanNumber}`
  );

  // return new Promise(resolve =>
  //   setTimeout(
  //     () =>
  //       resolve({
  //         data: loanVerifyData,
  //         isSuccess: true
  //       }),
  //     200
  //   )
  // );
}

/**
 * Gets disclosure resource from public server
 */
export function getDisclosureConsent(resourceName) {
  return resourceAxios.get(`Disclosure/${resourceName}.html`);
  // return new Promise(resolve =>
  //   setTimeout(
  //     () =>
  //       resolve({
  //         data: docData,
  //         isSuccess: true
  //       }),
  //     500
  //   )
  // );
}
