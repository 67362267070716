import React, { memo } from 'react';
import parse from 'html-react-parser';

import styled from 'styled-components';

import Centered from 'components/layouts/Centered';
import { GoBackButton } from 'components/views/Main/utils';
import TwoColumns from 'components/common/Content/TwoColumns';
import { Title } from 'components/common/Text';

import faq from 'assets/Faq.html';
import { Space } from 'antd';
import { SIZE_BETWEEN_ELEMENTS } from 'theme/variables';

function Faq() {
  return (
    <Centered>
      <TwoColumns
        title={<Title>Frequently asked questions (FAQs)</Title>}
        showSecondColumn={false}
        actions={<GoBackButton type="secondary" />}
        md={18}
      >
        {parse(`${faq}`)}
      </TwoColumns>
    </Centered>
  );
}

export default memo(Faq);
