import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { Button as ButtonAnt } from 'antd';

import { closeSigner } from 'redux/assistant/actions';

import {
  ACTION_COLOR,
  ACTION_COLOR_HOVER,
  ICON_SIZE,
  BUTTON_DISABLED_COLOR,
  FONT_SIZE,
  WIDTH_BREAKPOINT_LG,
  DISABLE_COLOR,
  DISABLE_BORDER,
  FONT_SIZE_BUTTON,
  BORDER_BLACK,
  FONT_COLOR
} from 'theme/variables';

export const SelectFocus = styled(ButtonAnt)`
  color: ${ACTION_COLOR};
  padding: 0;
  border: none;
  height: auto;
  box-shadow: none;
  &:focus {
    border: auto;
    outline: 1px solid ${BORDER_BLACK};
  }
  text-align: left;
`;

export const SelectFocusList = styled(ButtonAnt)`
  border: none;
  text-align: left;
  height: auto;
  box-shadow: none;
  &:focus {
    border: auto;
    outline: 1px solid ${BORDER_BLACK};
  }
`;

const ButtonClose = styled(ButtonAnt)`
  background: none;
  border: none;
  margin: -5px 0;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  transition: none;
  opacity: 0.8;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    color: #ffffff;
    text-decoration: none;
    background: none;
    box-shadow: none;
    outline: none;
    transition: none;
  }
`;

const SignButtonStyled = styled(ButtonAnt)`
  &.ant-btn {
    width: 50px;
    height: 50px;
    padding: 0;
    position: absolute;
    margin: 80px 20px;
    right: 0;
    z-index: 1000;
    font-size: 24px;
    border-radius: 100%;
    color: #ffffff;
    color: ${props => props.text};
    background: ${ACTION_COLOR};
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    &.anticon {
      position: absolute;
    }

    &:hover,
    &:focus {
      color: ${ACTION_COLOR};
      background-color: white;
      border-color: ${ACTION_COLOR};
    }

    ${props =>
      props.text &&
      css`
        &:after {
          content: '${props.text}';
          font-size: ${FONT_SIZE};
        }
      `}

    @media only screen and (max-width: ${WIDTH_BREAKPOINT_LG}) {
      & {
        margin: 130px 20px;
      }
    }
  }
`;

export function ButtonCloseSign({ ...props }) {
  const dispatch = useDispatch();

  const handleCloseClick = useCallback(
    e => {
      dispatch(closeSigner());
    },
    [dispatch]
  );

  return (
    <Button
      {...props}
      key="close-sign"
      htmlType="button"
      type="secondary"
      onClick={handleCloseClick}
    >
      Back
    </Button>
  );
}

export const Button = styled(ButtonAnt)`
  &.ant-btn-primary-disabled,
  &.ant-btn-primary.disabled,
  &.ant-btn-primary[disabled],
  &.ant-btn-primary-disabled:hover,
  &.ant-btn-primary.disabled:hover,
  &.ant-btn-primary[disabled]:hover,
  &.ant-btn-primary-disabled:focus,
  &.ant-btn-primary.disabled:focus,
  &.ant-btn-primary[disabled]:focus,
  &.ant-btn-primary-disabled:active,
  &.ant-btn-primary.disabled:active,
  &.ant-btn-primary[disabled]:active,
  &.ant-btn-primary-disabled.active,
  &.ant-btn-primary.disabled.active,
  &.ant-btn-primary[disabled].active[disabled] {
    background: ${DISABLE_COLOR};
    border: ${DISABLE_BORDER};
  }

  &.ant-btn-primary {
    background: ${ACTION_COLOR};
    border-color: ${ACTION_COLOR};
    &:focus {
      border: 2px solid ${BORDER_BLACK};
    }
  }

  &:not([disabled]).ant-btn-secondary.ant-btn {
    color: ${ACTION_COLOR};
    border-color: ${ACTION_COLOR};

    &:hover,
    &:focus {
      background: ${ACTION_COLOR};
      color: white;
    }
    &:focus {
      outline: auto;
      outline-color: ${BORDER_BLACK};
    }
  }
`;

const ButtonLinkStyled = styled(ButtonAnt)`
  &.ant-btn {
    font-size: ${FONT_SIZE_BUTTON};
    background: none;
    border: none;
    padding: 0;
    color: ${ACTION_COLOR};
    box-shadow: none;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      color: ${ACTION_COLOR_HOVER};
    }

    .ant-btn-loading-icon .anticon {
      font-size: 14px;
    }

    &[disabled] {
      color: ${BUTTON_DISABLED_COLOR};
      cursor: not-allowed;
    }

    .anticon {
      font-size: ${ICON_SIZE};
      cursor: pointer;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.ant-btn-disabled,
  &.ant-btn.disabled,
  &.ant-btn[disabled],
  &.ant-btn-disabled:hover,
  &.ant-btn.disabled:hover,
  &.ant-btn[disabled]:hover,
  &.ant-btn-disabled:focus,
  &.ant-btn.disabled:focus,
  &.ant-btn[disabled]:focus,
  &.ant-btn-disabled:active,
  &.ant-btn.disabled:active,
  &.ant-btn[disabled]:active,
  &.ant-btn-disabled.active,
  &.ant-btn.disabled.active,
  &.ant-btn[disabled].active {
    background: none;
  }
`;

export const ButtonLink = ({ onClick, params, ...props }) => {
  const handleClick = e => {
    if (onClick && typeof onClick === 'function') {
      onClick(e, params);
    }
  };

  return <ButtonLinkStyled onClick={handleClick} {...props} />;
};

export const CustomButton = ({ onClick, params, ...props }) => {
  const handleClick = e => {
    if (onClick && typeof onClick === 'function') {
      onClick(e, params);
    }
  };

  return <ButtonAnt onClick={handleClick} {...props} />;
};

export const CloseButton = ({ onClick, ...props }) => {
  const handleClick = e => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <ButtonClose
      onClick={handleClick}
      type="secondary"
      icon={<CloseOutlined {...props} />}
    />
  );
};

export const Linked = styled(Link)`
  &,
  &:hover {
    color: ${ACTION_COLOR};
  }
`;

export default ButtonLink;
