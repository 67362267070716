import React, { memo } from 'react';
import parse from 'html-react-parser';

import styled from 'styled-components';

import Centered from 'components/layouts/Centered';
import TwoColumns from 'components/common/Content/TwoColumns';
import { GoBackButton } from 'components/views/Main/utils';
import { Title } from 'components/common/Text';

import privacyNotices from 'assets/PrivacyNotices.html';

function PrivacyNotice() {
  return (
    <Centered>
      <TwoColumns
        title={<Title>Privacy notices</Title>}
        showSecondColumn={false}
        md={18}
        actions={<GoBackButton type="secondary" />}
      >
        {parse(`${privacyNotices}`)}
      </TwoColumns>
    </Centered>
  );
}

export default memo(PrivacyNotice);
