import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import { useGetData } from 'hooks/data';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { SubTitle } from 'components/common/Text';

import { getDocumentListToSelect } from 'redux/documentList/actions';
import {
  selectDocumentListToSelect,
  selectDocumentListToSign,
  selectHaveDocumentPendingToSign,
  selectIsFetchingDocumentListToSelect
} from 'redux/documentList/selectors';

import { DocumentListContainer } from '.';
import { ROUTES } from 'consts/routes';
import { logoutUser } from 'redux/app/actions';

function CheckPendingSignature() {
  const dispatch = useDispatch();
  const [
    documentListToSign,
    isFetchingDocumentListToSelect,
    actionCalled
  ] = useGetData(
    selectDocumentListToSign,
    selectIsFetchingDocumentListToSelect,
    getDocumentListToSelect,
    [{}],
    true
  );

  const haveDocumentPendingToSign = useSelector(
    selectHaveDocumentPendingToSign
  );
  const documentListToSelect = useSelector(selectDocumentListToSelect);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (actionCalled && !isFetchingDocumentListToSelect) {
      if (haveDocumentPendingToSign && documentListToSelect?.length > 1) {
        dispatch(replace(ROUTES.DOCUMENTS_LIST));
      } else {
        dispatch(logoutUser());
        dispatch(replace(ROUTES.HOME));
      }
    }
  }, [documentListToSelect]);

  return (
    <DocumentListContainer>
      <Spin indicator={antIcon} />
      <SubTitle style={{ display: 'inline', marginLeft: '10px' }}>
        Determining if there are any other pending documents to be signed...
      </SubTitle>
    </DocumentListContainer>
  );
}

export default CheckPendingSignature;
