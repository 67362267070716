import { combineReducers } from 'redux-immutable';

import {
  SET_AGREEMENTS_VERIFIED,
  SET_DATA_RESUME_VERIFIED,
  READED_CONSENT_STATUS
} from './constants';
import {
  ENTRY_WITH_TOKEN,
  GET_DOCUMENT_DATA_REQUEST,
  GET_DOCUMENT_DATA_SUCCESS,
  GO_TO_PREVIOUS_VERIFICATION_STEP,
  LOGOUT_USER
} from 'redux/app/constants';
import {
  RESET_DOCUMENT_ID_SELECTED,
  SET_DOCUMENT_SELECTED
} from 'redux/activeDocument/constants';

const dataResumeVerifiedReducer = (state = false, { payload, type }) => {
  switch (type) {
    case SET_DATA_RESUME_VERIFIED:
      return true;
    //It is not necessary to show informed consent. Goes directly to see the document after document verification
    case SET_DOCUMENT_SELECTED:
      return !payload.showInformedConsent;
    case RESET_DOCUMENT_ID_SELECTED:
    case ENTRY_WITH_TOKEN:
    case LOGOUT_USER:
      return false;
    default:
      return state;
  }
};

const agreementsVerifiedReducer = (state = false, { payload, type }) => {
  switch (type) {
    case SET_AGREEMENTS_VERIFIED:
      return true;
    //It is not necessary to show informed consent. Goes directly to see the document after document verification
    case SET_DOCUMENT_SELECTED:
      return !payload.showInformedConsent;
    case RESET_DOCUMENT_ID_SELECTED:
    case ENTRY_WITH_TOKEN:
    case LOGOUT_USER:
      return false;
    default:
      return state;
  }
};

const readedConsentStatusReducer = (state = false, { payload, type }) => {
  switch (type) {
    case READED_CONSENT_STATUS:
      return payload;
    case RESET_DOCUMENT_ID_SELECTED:
    case GET_DOCUMENT_DATA_REQUEST:
    case ENTRY_WITH_TOKEN:
    case LOGOUT_USER:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  dataResumeVerified: dataResumeVerifiedReducer,
  agreementsVerified: agreementsVerifiedReducer,
  readedConsentStatus: readedConsentStatusReducer
});
