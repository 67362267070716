import React, { memo, useState } from 'react';

import Spin from 'components/common/Spin';
import Layout, { Content } from 'components/layouts/HeaderContentFooter';

import { pdfjs } from 'react-pdf';

import Visor from './Visor';
import { BACKGROUND_ACTIVE } from 'theme/variables';
import { useIsMobile } from 'hooks/responsive';
import { DrawerComponent, SiderComponent } from './components';
import { FileTextOutlined } from '@ant-design/icons';
import { Button } from '../Buttons';
import { selectDocumentsVisibility } from 'redux/app/selectors';
import { toggleChooseDocumentsContainer } from 'redux/app/actions';
import { useDispatch, useSelector } from 'react-redux';
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf-viewer.worker.min.js`;

function PdfVisor({ file, loading, siderComponent, handleDocumentFinish }) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const documentsVisibility = useSelector(selectDocumentsVisibility);

  const Component = isMobile ? DrawerComponent : SiderComponent;

  const handleDrawerVisibility = () => {
    dispatch(toggleChooseDocumentsContainer(false));
  };

  const handleOpenChooseDocumentsVisibility = () => {
    dispatch(toggleChooseDocumentsContainer(true));
  };

  const openDocumentsTool = (
    <Button
      type="secondary"
      onClick={handleOpenChooseDocumentsVisibility}
      style={{ marginRight: 10, padding: 5, lineHeight: 1 }}
    >
      <FileTextOutlined />
    </Button>
  );

  return loading ? (
    <Spin spinning />
  ) : (
    <Layout>
      {siderComponent ? (
        <Component
          title="Documents"
          visible={isMobile && documentsVisibility}
          onClose={isMobile ? handleDrawerVisibility : null}
        >
          {siderComponent}
        </Component>
      ) : null}
      <Content style={{ paddingLeft: 10, background: BACKGROUND_ACTIVE }}>
        <Visor
          fileUrl={file}
          handleDocumentFinish={handleDocumentFinish}
          toolbarTools={isMobile ? openDocumentsTool : null}
        />
      </Content>
    </Layout>
  );
}

export default memo(PdfVisor);
