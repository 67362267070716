import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import history from 'utils/history';
import store from 'redux/store';
import enUS from 'antd/es/locale/en_US';
import { configAxiosInstances } from 'api/axios';

import GlobalStyles from 'theme/global-styles';

import { ConfigProvider } from 'antd';
import Home from 'components/views/Home';
import Main from 'components/views/Main';
import EntryPoint from 'components/views/EntryPoint';
import VerifyContainer from 'components/views/Verify';
import DocumentsList from 'components/views/DocumentsList';
import Agreements from 'components/views/Agreements';
import MessagePage from 'components/views/Messages';
import { MainContainer } from 'components/layouts';
import NotFound from 'components/common/Pages/NotFound';
import NotAuthorized from 'components/common/Pages/NotAuthorized';
import TermsAndConditions from 'components/views/TermsAndConditions';
import PrivacyNotes from 'components/views/Information/PrivacyNotes';
import Faq from 'components/views/Information/Faq';
import AboutUs from 'components/views/Information/AboutUs';
import { ServiceWorkerProvider } from 'components/services/ServiceWorker';
import NewVersion from 'components/services/ServiceWorker/NewVersion';

import { ROUTES } from 'consts/routes';
import ClientSelection from './views/ClientSelection';
import CheckPendingSignature from './views/DocumentsList/CheckPendingSignature';
// Import axios init function

configAxiosInstances(store);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ConfigProvider locale={enUS}>
            <>
              <GlobalStyles />
              <ServiceWorkerProvider>
                <NewVersion />
              </ServiceWorkerProvider>
              <MainContainer>
                <Switch>
                  <Route exact path={ROUTES.HOME}>
                    <Home />
                  </Route>
                  <Route exact path={ROUTES.ENTRY_POINT}>
                    <EntryPoint />
                  </Route>
                  <Route exact path={ROUTES.CLIENT_SELECTION}>
                    <ClientSelection />
                  </Route>
                  <Route path={ROUTES.VERIFY}>
                    <VerifyContainer />
                  </Route>
                  <Route path={ROUTES.DOCUMENTS_LIST}>
                    <DocumentsList />
                  </Route>
                  <Route path={ROUTES.DOCUMENTS_PENDING_SIGNATURE}>
                    <CheckPendingSignature />
                  </Route>
                  <Route exact path={ROUTES.AGREEMENTS}>
                    <Agreements />
                  </Route>
                  <Route exact path={ROUTES.AGREEMENTS_DOCUMENT}>
                    <TermsAndConditions />
                  </Route>
                  <Route exact path={ROUTES.MAIN}>
                    <Main />
                  </Route>
                  <Route exact path={ROUTES.MESSAGE}>
                    <MessagePage />
                  </Route>
                  <Route exact path={ROUTES.FAQ}>
                    <Faq />
                  </Route>
                  <Route exact path={ROUTES.PRIVACY_NOTES}>
                    <PrivacyNotes />
                  </Route>
                  <Route exact path={ROUTES.ABOUTUS}>
                    <AboutUs />
                  </Route>
                  <Route exact path={ROUTES.NOT_AUTHORIZED}>
                    <NotAuthorized />
                  </Route>
                  <Route component={NotFound} />
                </Switch>
              </MainContainer>
            </>
          </ConfigProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
