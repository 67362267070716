import styled from 'styled-components';

import { Divider as DividerAnt } from 'antd';
import { WIDTH_BREAKPOINT_SM } from 'theme/variables';

const Divider = styled(DividerAnt)`
  margin: ${props => (props.margin ? props.margin : '10px 0')};

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_SM}) {
    margin: 5px 0;
  }
`;

export default Divider;
