// ----------- Assistant step constants -----------
export const ASSISTANT_STEP_REVIEW = 0;
export const ASSISTANT_STEP_SIGN = 1;
export const ASSISTANT_STEP_DONE = 2;

export const SET_ASSISTANT_STEP = 'esign/Assistant/SET_ASSISTANT_STEP';

export const SET_DOCUMENTS_STEP = 'esign/Assistant/SET_DOCUMENTS_STEP';

// ---------- Signer constants -----------------------
export const CLOSE_SIGNER = 'esign/Assistant/CLOSE_SIGNER';
