import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { printPlugin } from '@react-pdf-viewer/print';

import { ERROR_COLOR, opacityColor } from 'theme/variables';

import { SubTitle } from '../Text';
import { useIsMobile } from 'hooks/responsive';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';

const workerUrl = `${process.env.PUBLIC_URL}/pdf-viewer.worker.min.js`;

const Visor = ({
  fileUrl,
  onDocumentLoad,
  handleDocumentFinish,
  toolbarTools,
  ...props
}) => {
  const [numPages, setNumPages] = useState(0);
  const isMobile = useIsMobile();

  const onFinishDocument = () => {
    if (handleDocumentFinish && typeof handleDocumentFinish === 'function') {
      handleDocumentFinish();
    }
  };

  const handlePageChange = e => {
    if (numPages && e.currentPage === numPages - 1) {
      onFinishDocument();
    }
  };

  const onDocumentLoadSuccess = e => {
    if (e.doc.numPages === 1) {
      onFinishDocument();
    } else setNumPages(e.doc.numPages);
  };
  // TOOLBAR CONF
  // -------------------

  const zoomPluginInstance = zoomPlugin();
  const { CurrentScale } = zoomPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const printPluginInstance = printPlugin();
  const { PrintButton } = printPluginInstance;

  const renderToolbar = props => (
    <Toolbar style={{ background: 'red' }}>
      {props => {
        const {
          CurrentPageLabel,
          EnterFullScreen,
          NumberOfPages,
          Zoom,
          ZoomIn,
          ZoomOut
        } = props;
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: isMobile ? 0 : '0 10px'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {toolbarTools}
              <SubTitle>
                <CurrentPageLabel /> of <NumberOfPages /> pages
              </SubTitle>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <SubTitle>
                <Zoom />
              </SubTitle>
              <ZoomOut />
              <ZoomIn />
              <EnterFullScreen />
              <PrintButton />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  // LAYOUT CONF
  // -------------------

  const renderSideBar = defaultTabs => {
    let sidebarTools = [
      // defaultTabs[0]
      // defaultTabs[1]
      // ...defaultTabs
    ];

    return sidebarTools;
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: defaultTabs => renderSideBar(defaultTabs)
  });

  const renderPage = ({ canvasLayer, annotationLayer, textLayer, scale }) => {
    return (
      <>
        {canvasLayer.children}
        {props.watermark ? (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%'
            }}
          >
            <div
              style={{
                color: opacityColor(ERROR_COLOR, 0.3),
                fontSize: `${4 * scale}rem`,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                transform: 'rotate(-45deg)',
                userSelect: 'none',
                textAlign: 'center'
              }}
            >
              {props.watermark}
            </div>
          </div>
        ) : null}
        {annotationLayer.children}
        {textLayer.children}
      </>
    );
  };

  // -------------------

  return (
    <Worker workerUrl={workerUrl}>
      <div style={{ height: '100%' }}>
        <Viewer
          fileUrl={fileUrl}
          defaultScale={1}
          plugins={[
            defaultLayoutPluginInstance,
            toolbarPluginInstance,
            pageNavigationPluginInstance,
            zoomPluginInstance,
            printPluginInstance
          ]}
          // onLoadSuccess={onDocumentLoadSuccess}
          onDocumentLoad={onDocumentLoadSuccess}
          onPageChange={handlePageChange}
          renderPage={renderPage}
        />
      </div>
    </Worker>
  );
};

Visor.propTypes = {
  fileUrl: PropTypes.any.isRequired
};

export default Visor;
