import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';

import Result from 'components/common/Result';
import Centered from 'components/layouts/Centered';
import TwoColumns from 'components/common/Content/TwoColumns';
import NotFound from 'components/common/Pages/NotFound';

import {
  selectShowMessage,
  selectRetryFailuresFetching
} from 'redux/messages/selectors';
import { LogOutUserButton } from '../Main/utils';
import { executeSmartAction } from 'redux/app/actions';
import { Button } from 'components/common/Buttons';
import { Space } from 'antd';
import { SIZE_BETWEEN_ELEMENTS } from 'theme/variables';

import rejectedImage from 'images/rejected.svg';
import IMAGE_MESSAGE from 'components/common/Image';

function Redirection({ retryOption = true }) {
  const dispatch = useDispatch();
  const retryFailuresFetching = useSelector(selectRetryFailuresFetching);

  const handleSmartAction = () => {
    dispatch(executeSmartAction());
  };

  return (
    <Space size={SIZE_BETWEEN_ELEMENTS}>
      {retryOption ? (
        <Button
          type="primary"
          onClick={handleSmartAction}
          loading={retryFailuresFetching}
        >
          Retry
        </Button>
      ) : null}
      <LogOutUserButton type="secondary" />
    </Space>
  );
}

function MessagePage() {
  const showMessage = useSelector(selectShowMessage);

  if (showMessage?.status === 'error') {
    showMessage.image = rejectedImage;
    showMessage.status = null;
  }

  return showMessage ? (
    <Centered>
      <TwoColumns showSecondColumn={false} md={18}>
        <Result
          icon={
            showMessage?.image ? (
              <IMAGE_MESSAGE src={showMessage.image} />
            ) : (
              <InfoCircleOutlined />
            )
          }
          status={showMessage?.status || null}
          title={showMessage?.title}
          subTitle={showMessage?.message}
          extra={<Redirection retryOption={showMessage.retryOption} />}
        />
      </TwoColumns>
    </Centered>
  ) : (
    <NotFound />
  );
}

export default memo(MessagePage);
