import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import {
  GET_KEY_DOCUMENT_REQUEST,
  GET_KEY_DOCUMENT_SUCCESS,
  GET_KEY_DOCUMENT_FAILED,
  SAVE_CURRENT_TOKEN,
  SAVE_USER_TOKEN,
} from './constants';
import { LOGOUT_USER, RESET_APP } from 'redux/app/constants';
import {
  GET_USER_TYPES_REQUEST,
  GET_VERIFY_DATA_REQUEST,
} from 'redux/verifyUser/constants';

const isFetchingValidatingCurrentTokenReducer = (state = null, action) => {
  switch (action.type) {
    case GET_KEY_DOCUMENT_REQUEST:
      return true;
    case GET_KEY_DOCUMENT_SUCCESS:
    case GET_KEY_DOCUMENT_FAILED:
      return false;
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};
const currentTokenReducer = (state = null, action) => {
  switch (action.type) {
    case SAVE_CURRENT_TOKEN:
      return fromJS(action.token);
    case GET_USER_TYPES_REQUEST:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};
const userTokenReducer = (state = null, action) => {
  switch (action.type) {
    case SAVE_USER_TOKEN:
      return fromJS(action.token);
    case GET_USER_TYPES_REQUEST:
    case GET_VERIFY_DATA_REQUEST:
    case LOGOUT_USER:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  isFetchingValidatingCurrentToken: isFetchingValidatingCurrentTokenReducer,
  currentToken: currentTokenReducer,
  userToken: userTokenReducer,
});
