import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Centered from 'components/layouts/Centered';

import VerifyUser from './User';
import VerifyDocument from './Document';
import VerifyLoan from './Loan';

import { ROUTES } from 'consts/routes';
import { USER_TYPE_DEALER } from 'redux/verifyUser/constants';

import borrowerImg from 'images/background/borrower.jpg';
import dealerImg from 'images/background/dealer.jpg';
import { selectUserTypeSelected } from 'redux/verifyUser/selectors';

function Container() {
  const userType = useSelector(selectUserTypeSelected);

  let imageSetting = {
    source: borrowerImg,
    position: '10% 30%'
  };

  switch (userType) {
    case USER_TYPE_DEALER:
      imageSetting.source = dealerImg;
      imageSetting.position = '50% 30%';
      break;
    default:
      break;
  }

  return (
    <Centered>
      <Switch>
        <Route exact path={ROUTES.VERIFY_LOAN}>
          <VerifyLoan imageSetting={imageSetting} />
        </Route>
        <Route exact path={ROUTES.VERIFY_USER}>
          <VerifyUser imageSetting={imageSetting} />
        </Route>
        <Route exact path={ROUTES.VERIFY_DOCUMENT}>
          <VerifyDocument />
        </Route>
        <Redirect
          to={{
            pathname: ROUTES.NOT_FOUND
          }}
        />
      </Switch>
    </Centered>
  );
}

export default Container;
