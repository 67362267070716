import { createSelector } from 'reselect';
import {
  DOCUMENT_STATUS_PENDING,
  DOCUMENT_STATUS_SIGNED,
  DOCUMENT_STATUS_ERROR
} from './constants';

const selectAppDomain = () => state => state.get('app');

const selectApp = createSelector(selectAppDomain(), app => app.toJS());

const selectIsLoadingData = createSelector(selectApp, app => app.isLoadingData);

const selectDocumentData = createSelector(selectApp, app => app.documentData);
const selectIsLoadingDocumentsContent = createSelector(
  selectApp,
  app => app.isLoadingDocumentsContent
);

const selectShowSignedDocumentsAfterSign = createSelector(
  selectApp,
  app => app.showSignedDocumentsAfterSign
);

const selectPublicTk = createSelector(selectApp, app => app.publicTK);
const selectPrivateTk = createSelector(selectApp, app => app.privateTK);

const selectDataInfo = createSelector(selectDocumentData, documentData =>
  documentData && documentData.data ? documentData.data : null
);

const selectDataResume = createSelector(
  selectDocumentData,
  documentData => documentData?.dataResume
);

const selectDocumentStatus = createSelector(
  selectDocumentData,
  documentData => documentData && documentData.documentStatus
);

const selectErrorDescription = createSelector(
  selectDocumentData,
  documentData =>
    documentData && documentData.errorDescription
      ? documentData.errorDescription
      : null
);

const selectIsStatusPending = createSelector(selectDocumentStatus, status =>
  status ? status === DOCUMENT_STATUS_PENDING : false
);

const selectIsStatusSigned = createSelector(selectDocumentStatus, status =>
  status ? status === DOCUMENT_STATUS_SIGNED : false
);

const selectIsStatusError = createSelector(selectDocumentStatus, status =>
  status ? status === DOCUMENT_STATUS_ERROR : false
);

const selectGeneralDescription = createSelector(selectDocumentData, data =>
  data ? data.description : null
);

const selectDocuments = createSelector(selectDocumentData, data =>
  data ? data.documents : []
);

const selectHasMultipleDocuments = createSelector(
  selectDocuments,
  documents => documents?.length > 1
);

const selectAllDocumentsAreReaded = createSelector(
  selectDocuments,
  documents =>
    documents.length &&
    documents.filter(document => !document.readed).length === 0
);

const selectSignature = createSelector(selectDocumentData, data =>
  data ? data.signature : null
);

const selectSignatureStyles = createSelector(selectSignature, signature =>
  signature ? signature.styles : []
);

const selectSignatoryId = createSelector(selectSignature, signature =>
  signature ? signature.signatoryTypeId : null
);

const selectSignatoryTypeName = createSelector(selectSignature, signature =>
  signature ? signature.signatoryTypeName : null
);

const selectActiveDocumentIndex = createSelector(
  selectApp,
  app => app.activeDocumentIndex
);

const selectGetDocuments = createSelector(selectApp, app => app.getDocuments);

const selectCurrentStep = createSelector(selectApp, app => app.currentStep);

const selectSignatureData = createSelector(selectApp, app => app.signatureData);

const selectIsSigningLoading = createSelector(
  selectApp,
  app => app.isSigningLoading
);

const selectViewSignerInformationSider = createSelector(
  selectApp,
  app => app.viewSignerInformationSider
);

const selectVerifiedDocumentData = createSelector(
  selectApp,
  app => app.verifiedDocumentData
);
const selectVerifiedUserData = createSelector(
  selectApp,
  app => app.verifiedUserData
);

const selectVerifiedData = createSelector(
  selectVerifiedDocumentData,
  selectVerifiedUserData,
  (verifiedDocumentData, verifiedUserData) => {
    if (!verifiedDocumentData && !verifiedUserData) {
      return null;
    }
    return Object.assign({}, verifiedDocumentData, verifiedUserData);
  }
);

const selectSignatoryFullName = createSelector(
  selectSignature,
  selectVerifiedUserData,
  selectVerifiedDocumentData,
  (signature, verifiedUserData, verifiedDocumentData) => {
    return (
      signature?.signatoryFullName ||
      (verifiedUserData && verifiedUserData['SignatoryFullName']) ||
      (verifiedDocumentData && verifiedDocumentData['SignatoryFullName'])
    );
  }
);

const selectSignatureOptions = createSelector(selectDocumentData, data =>
  data ? data.signatureOptions : null
);

const selectEntryWithToken = createSelector(
  selectApp,
  app => app.entryWithToken
);

const selectJustSigned = createSelector(
  selectIsStatusSigned,
  selectApp,
  (isStatusSigned, app) => isStatusSigned && app.justSigned
);

const selectIsFetchingGettingKeyReducer = createSelector(
  selectApp,
  app => app.isFetchingGettingKey
);

const selectDocumentsToSignAccepted = createSelector(
  selectApp,
  app => app.documentsToSignAccepted
);

const selectMobileAlertMessageShown = createSelector(
  selectApp,
  app => app.mobileAlertMessageShown
);

const selectGetResourceIsFetching = createSelector(
  selectApp,
  app => app.getResourceIsFetching
);

const selectDocumentsVisibility = createSelector(
  selectApp,
  app => app.chooseDocumentsVisibility
);

export default selectApp;
export {
  selectIsLoadingData,
  selectDocumentData,
  selectDataResume,
  selectPublicTk,
  selectPrivateTk,
  selectDataInfo,
  selectDocumentStatus,
  selectErrorDescription,
  selectIsStatusPending,
  selectIsStatusError,
  selectGeneralDescription,
  selectDocuments,
  selectSignature,
  selectSignatureStyles,
  selectSignatoryFullName,
  selectSignatoryId,
  selectSignatoryTypeName,
  selectActiveDocumentIndex,
  selectGetDocuments,
  selectCurrentStep,
  selectSignatureData,
  selectIsSigningLoading,
  selectViewSignerInformationSider,
  selectIsLoadingDocumentsContent,
  selectVerifiedData,
  selectSignatureOptions,
  selectShowSignedDocumentsAfterSign,
  selectJustSigned,
  selectEntryWithToken,
  selectIsStatusSigned,
  selectIsFetchingGettingKeyReducer,
  selectDocumentsToSignAccepted,
  selectMobileAlertMessageShown,
  selectGetResourceIsFetching,
  selectAllDocumentsAreReaded,
  selectHasMultipleDocuments,
  selectDocumentsVisibility
};
