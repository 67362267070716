import { takeLatest, put, select, call } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import {
  GET_RESOURCE,
  RESOURCE_DISCLOSURE,
  SET_AGREEMENTS_VERIFIED,
  SET_DATA_RESUME_VERIFIED
} from './constants';
import { ROUTES } from 'consts/routes';
import { selectDisclosureResourceName } from 'redux/settings/selectors';
import { getResourceFailed, getResourceSuccess } from './actions';
import { getDisclosureConsent } from 'api/SignatureAPI';
import { showMessage } from 'redux/messages/actions';

function* setAgreementsVerifiedSaga() {
  yield takeLatest(SET_AGREEMENTS_VERIFIED, function* () {
    yield put(replace(ROUTES.MAIN));
  });
}

function* setDataResumeVerifiedSaga() {
  yield takeLatest(SET_DATA_RESUME_VERIFIED, function* () {
    const disclosureResourceName = yield select(selectDisclosureResourceName);
    let resource = sessionStorage.getItem(RESOURCE_DISCLOSURE);

    if (!resource) {
      const notification = {
        title:
          "We had an application problem, imposible to continue with the document's signature.",
        message:
          "Due an error showing the disclosure legal documents we need to stop the document's signature.",
        status: 'error',
        retryOption: false
      };

      yield put(showMessage(notification));
      return;
    }

    if (!disclosureResourceName) {
      yield put(replace(ROUTES.MAIN));
      return;
    }
  });
}

function* getResourceSaga() {
  yield takeLatest(GET_RESOURCE, function* () {
    let retryOnErrorCount = 2;
    do {
      const disclosureResourceName = yield select(selectDisclosureResourceName);

      try {
        const response = yield call(
          getDisclosureConsent,
          disclosureResourceName
        );

        if (!response?.data) {
          yield put(getResourceFailed('Imposible to get the resource'));
          retryOnErrorCount--;
        } else {
          yield put(getResourceSuccess());
          sessionStorage.setItem(RESOURCE_DISCLOSURE, response.data);
          retryOnErrorCount = 0;
        }
      } catch (error) {
        yield put(getResourceFailed(error));
        retryOnErrorCount--;
      }
    } while (retryOnErrorCount > 0);
  });
}

export default [
  setAgreementsVerifiedSaga,
  setDataResumeVerifiedSaga,
  getResourceSaga
];
