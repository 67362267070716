import React from 'react';
import styled from 'styled-components';

import { List as ListAnt } from 'antd';

import {
  ACTION_COLOR,
  BORDER_ACTION,
  FONT_SIZE_SMALL,
  BACKGROUND_INFO,
  COLOR_ICON_INFO
} from 'theme/variables';
import { InfoCircleOutlined } from '@ant-design/icons';

const NotesContainer = styled.div`
  background: ${BACKGROUND_INFO};
  margin-bottom: ${props => (props.reduced ? '0' : '10px')};
  padding: 0 10px;
  display: flex;
  align-items: flex-start;

  .anticon {
    display: inline;
    margin: 5px 10px 0 0;
    color: ${COLOR_ICON_INFO};
  }
`;
const CustomListItem = ListAnt.Item;

export const NotesList = styled(ListAnt)`
  &.ant-list-sm .ant-list-item {
    padding: 0;
  }

  &.ant-list-split .ant-list-item {
    border-bottom: none;
  }
`;

export function Notes({ source, reduced = false }) {
  return (
    <NotesContainer reduced={reduced}>
      <InfoCircleOutlined />
      <NotesList
        size="small"
        dataSource={source}
        renderItem={item => (
          <NotesList.Item style={{ fontSize: FONT_SIZE_SMALL }}>
            {item}
          </NotesList.Item>
        )}
      />
    </NotesContainer>
  );
}

const List = styled(ListAnt)`
  & .ant-list-item {
    padding: 10px;
    border-top: 1.5px solid transparent;
    border-bottom: 1.5px solid transparent;

    &:last-child {
      border-bottom: 1.5px solid transparent;
    }

    .ant-avatar {
      background: ${ACTION_COLOR};
    }
  }
  & .ant-list-item:hover {
    cursor: pointer;
    border-top: ${BORDER_ACTION};
    border-bottom: ${BORDER_ACTION};
    padding: 10px;
  }

  .ant-list-item-meta-title {
    margin-bottom: 0;
  }
`;

export const ListItem = ({ onClick, params, children, ...props }) => {
  const handleClick = e => {
    if (onClick && typeof onClick === 'function') {
      onClick(e, params);
    }
  };

  return (
    <CustomListItem onClick={handleClick} {...props}>
      {children}
    </CustomListItem>
  );
};

export default List;
