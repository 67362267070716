import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { replace } from 'connected-react-router';

import { Result } from 'antd';
import Centered from 'components/layouts/Centered';
import TwoColumns from 'components/common/Content/TwoColumns';
import Spin from 'components/common/Spin';

import { saveCurrentToken } from 'redux/tokens/actions';

import { ROUTES } from 'consts/routes';
import { entryWithToken, resetApp } from 'redux/app/actions';

function EntryPoint() {
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    if (!token) {
      dispatch(replace(ROUTES.HOME));
    }

    if (token) {
      dispatch(resetApp());
      dispatch(entryWithToken());
      dispatch(saveCurrentToken(token));
    }
  }, [token, dispatch]);

  return (
    <Centered footerLinks={false}>
      <TwoColumns md={18} showSecondColumn={false}>
        <Result
          icon={
            <Spin
              size="large"
              tip="Processing your request, please stand by..."
            />
          }
        />
      </TwoColumns>
    </Centered>
  );
}

export default memo(EntryPoint);
