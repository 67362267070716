import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Sider } from 'components/layouts';

import { selectViewSignerInformationSider } from 'redux/app/selectors';

import ShowData from './ShowData';

function SiderInfo({ loading }) {
  const viewSignerInformationSider = useSelector(
    selectViewSignerInformationSider
  );

  return (
    <Sider width={300} show={viewSignerInformationSider}>
      <ShowData />
    </Sider>
  );
}
export default memo(SiderInfo);
