import { combineReducers } from 'redux-immutable';
import { GET_RESOURCE_FAILED } from 'redux/agreements/constants';
import { ENTRY_WITH_TOKEN, LOGOUT_USER, RESET_APP } from 'redux/app/constants';
import {
  REQUIRE_FAQ,
  SET_AFTER_SIGNING_CONTACT_INFORMATION,
  SET_AFTER_SIGNING_MESSAGE,
  SET_CERTIFY_MESSAGE,
  SET_DISCLOSURE_ALERT,
  SET_DISCLOSURE_RESOURCE_NAME,
  SET_INFORMATION_ALERT,
  SET_SIGNATURE_ALERT
} from './constants';

const initialState = {
  agreement: {
    disclosure: {
      resource: process.env.REACT_APP_AGREEMENT_DISCLOSURE_RESOURCE,
      alert: process.env.REACT_APP_AGREEMENT_DISCLOSURE_ALERT
    },
    information: {
      alert: process.env.REACT_APP_AGREEMENT_INFORMATION_ALERT
    }
  },
  signature: {
    description: '',
    certifyMessage: process.env.REACT_APP_SIGNATURE_CERTIFY_MESSAGE,
    alert: process.env.REACT_APP_SIGNATURE_ALERT,
    afterSigning: {
      message: process.env.REACT_APP_SIGNATURE_AFTER_SIGNING_MESSAGE,
      contactInformation: process.env.REACT_APP_SIGNATURE_AFTER_SIGNING_CONTACT
    }
  },
  FAQ: {
    hideLink: false
  }
};

const informationAlertReducer = (
  state = initialState.agreement.information.alert,
  action
) => {
  switch (action.type) {
    case SET_INFORMATION_ALERT:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
      return initialState.agreement.information.alert;
    default:
      return state;
  }
};

const disclosureAlertReducer = (
  state = initialState.agreement.disclosure.alert,
  action
) => {
  switch (action.type) {
    case SET_DISCLOSURE_ALERT:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
      return initialState.agreement.disclosure.alert;
    default:
      return state;
  }
};

const disclosureResourceNameReducer = (
  state = initialState.agreement.disclosure.resource,
  action
) => {
  switch (action.type) {
    case SET_DISCLOSURE_RESOURCE_NAME:
      return action.payload;
    case GET_RESOURCE_FAILED:
    case LOGOUT_USER:
    case RESET_APP:
      return initialState.agreement.disclosure.resource;
    default:
      return state;
  }
};

const requireFAQReducer = (state = true, action) => {
  switch (action.type) {
    case REQUIRE_FAQ:
      return action.payload;
    case ENTRY_WITH_TOKEN:
      return false;
    case LOGOUT_USER:
    case RESET_APP:
      return true;
    default:
      return state;
  }
};

const afterSigningMessageReducer = (
  state = initialState.signature.afterSigning.message,
  action
) => {
  switch (action.type) {
    case SET_AFTER_SIGNING_MESSAGE:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
      return initialState.signature.afterSigning.message;
    default:
      return state;
  }
};

const afterSigningContactInformationReducer = (
  state = initialState.signature.afterSigning.contactInformation,
  action
) => {
  switch (action.type) {
    case SET_AFTER_SIGNING_CONTACT_INFORMATION:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
      return initialState.signature.afterSigning.contactInformation;
    default:
      return state;
  }
};

const certifyMessageReducer = (
  state = initialState.signature.certifyMessage,
  action
) => {
  switch (action.type) {
    case SET_CERTIFY_MESSAGE:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
      return initialState.signature.certifyMessage;
    default:
      return state;
  }
};

const signatureAlertReducer = (
  state = initialState.signature.alert,
  action
) => {
  switch (action.type) {
    case SET_SIGNATURE_ALERT:
      return action.payload;
    case LOGOUT_USER:
    case RESET_APP:
      return initialState.signature.alert;
    default:
      return state;
  }
};

export default combineReducers({
  informationAlert: informationAlertReducer,
  disclosureAlert: disclosureAlertReducer,
  disclosureResourceName: disclosureResourceNameReducer,
  requireFAQ: requireFAQReducer,
  afterSigningMessage: afterSigningMessageReducer,
  afterSigningContactInformation: afterSigningContactInformationReducer,
  certifyMessage: certifyMessageReducer,
  signatureAlert: signatureAlertReducer
});
