import React, { memo, useRef, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { useIsMobile } from 'hooks/responsive';

import { Row, Col } from 'antd';
import { Title } from '../Text';
import Background from 'components/common/Content/Background';
import Divider from 'components/common/Divider';
import Logo from 'components/common/Logo';

import {
  BACKGROUND_COLOR_LIGHT,
  BORDER_RADIUS,
  MAIN_COLOR
} from 'theme/variables';
import {
  LeftLayout,
  LeftHeader,
  LeftContent,
  LeftFooter
} from 'components/layouts/HeaderContentFooter';

const TwoColumnsRow = styled(Row)`
  height: 100%;
  margin: 0;
  position: relative;
`;

const TwoColumnsLeftCol = styled(Col)`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
`;

const TwoColumnsRightCol = styled(Col)``;

const RowOutsideBackground = styled(Row)`
  height: 0;
  position: relative;
`;

const ColOutsideBackground = styled(Col)`
  &::before {
    content: '';
    position: absolute;
    height: 100vh;
    width: 100%;
    background: ${MAIN_COLOR};
    top: ${props => (props.top ? `-${props.top}px` : 0)};
    z-index: -1;
  }
`;

const TopRightContainer = styled.div`
  background: ${BACKGROUND_COLOR_LIGHT};
  height: 10%;
  margin-bottom: 10px;
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
`;

const TwoColumnsImage = styled.div`
  height: calc(90% - 10px);
`;

function TwoColumns({
  title,
  children,
  imageSrc,
  imageDirection,
  md = 16,
  xs = 24,
  topRightContent,
  showOutsideBackground = true,
  showSecondColumn = true,
  actions,
  handleOnScrollBottomFunction = () => {},
  ...props
}) {
  const isMobile = useIsMobile();
  const rowRef = useRef(null);
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (rowRef) {
      setOffsetTop(rowRef.current.offsetTop);
    }
  }, [rowRef]);

  useEffect(() => {
    const resizeListener = () => {
      if (rowRef && offsetTop !== rowRef.current.offsetTop) {
        setOffsetTop(rowRef.current.offsetTop);
      }
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, [rowRef, offsetTop]);

  const scrollContainerRef = useBottomScrollListener(
    handleOnScrollBottomFunction,
    { triggerOnNoScroll: true, offset: 80 }
  );


  return (
    <>
      <div ref={rowRef}></div>
      {showOutsideBackground && (
        <RowOutsideBackground>
          <Col md={md} xs={xs}></Col>
          <ColOutsideBackground
            flex="auto"
            top={offsetTop}
          ></ColOutsideBackground>
        </RowOutsideBackground>
      )}
      <TwoColumnsRow>
        <TwoColumnsLeftCol
          md={showSecondColumn ? md : 24}
          xs={showSecondColumn ? xs : 24}
          {...props}
        >
          <LeftLayout>
            <LeftHeader>
              <Logo />
              <Divider margin={'10px 0'} />
              {title && <Title>{title}</Title>}
            </LeftHeader>
            <LeftContent className="left-content" style={{ overflowY: 'auto' }}>
              <div
                ref={scrollContainerRef}
                style={{
                  height: '100%',
                  overflowY: 'auto',
                  paddingRight: '1rem'
                }}
              >
                {children}
              </div>
            </LeftContent>
            {actions && (
              <LeftFooter className="inset">
                <Row gutter={[16, 24]}>
                  <Col id="navigation-actions">{actions}</Col>
                </Row>
              </LeftFooter>
            )}
          </LeftLayout>
        </TwoColumnsLeftCol>
        {!isMobile && showSecondColumn && (
          <TwoColumnsRightCol flex="auto" top={offsetTop}>
            <TopRightContainer>{topRightContent}</TopRightContainer>
            <TwoColumnsImage>
              <Background
                backgroundSrc={imageSrc}
                backgroundDirection={imageDirection}
              />
            </TwoColumnsImage>
          </TwoColumnsRightCol>
        )}
      </TwoColumnsRow>
    </>
  );
}

export default memo(TwoColumns);
