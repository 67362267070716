import axios from 'axios';
import store from 'redux/store';
import { BASE_URL, RESOURCE_BASE_URL } from 'config/variables';
import { responseOk, responseError } from './interceptors';
// import { sleep } from 'redux/utils';

const TIME_OUT = 60000;

axios.defaults.headers.post['Content-Type'] = 'application/json';

const regularAxios = axios.create({ baseURL: BASE_URL, timeout: TIME_OUT });
const authorizedAxios = axios.create({ baseURL: BASE_URL, timeout: TIME_OUT });
const userAxios = axios.create({ baseURL: BASE_URL, timeout: TIME_OUT });

const resourceAxios = axios.create({
  baseURL: RESOURCE_BASE_URL,
  timeout: TIME_OUT
});

regularAxios.defaults.headers.common['Content-Type'] = 'application/json';
authorizedAxios.defaults.headers.common['Content-Type'] = 'application/json';
userAxios.defaults.headers.common['Content-Type'] = 'application/json';

resourceAxios.defaults.headers.common['Content-Type'] = 'text/html';

// resourceAxios.interceptors.response.use(async response => {
//   // add artificial delay for dev env
//   if (process.env.NODE_ENV === 'devF') {
//     await sleep();
//   }
//   return response.data;
// });

authorizedAxios.interceptors.request.use(config => {
  const currentToken = store.getState().getIn(['tokens', 'currentToken']);

  config.headers.Authorization = `Bearer ${currentToken}`;

  return config;
});

userAxios.interceptors.request.use(config => {
  const userToken = store.getState().getIn(['tokens', 'userToken']);

  config.headers.Authorization = `Bearer ${userToken}`;

  return config;
});

export const configAxiosInstances = store => {
  // Axios interceptors for all API request as an unauthenticated user
  regularAxios.interceptors.response.use(responseOk, responseError(store));
  authorizedAxios.interceptors.response.use(responseOk, responseError(store));
  userAxios.interceptors.response.use(responseOk, responseError(store));
};

export { authorizedAxios, regularAxios, userAxios, resourceAxios };
