import styled from 'styled-components';
import { BORDER_COLOR, FONT_COLOR } from 'theme/variables';

const FormattedInput = styled.input`
  box-sizing: border-box;
  margin: 0 2px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 30px;
  padding: 0px 10px;
  color: ${FONT_COLOR};
  font-size: 16px;
  line-height: 0.2;
  background-color: #fff;
  background-image: none;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 30px;

  &[disabled] {
    background: #eaeaea;

    &[placeholder='·'] {
      font-size: 40px;
    }
  }

  &:focus {
    border-color: #ff3029;
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
    border-right-width: 1px;
    outline: 0;
  }

  &:nth-of-type(1) {
    margin-left: 0;
  }
`;

export default FormattedInput;
