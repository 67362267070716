import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Centered from 'components/layouts/Centered';
import TwoColumns from 'components/common/Content/TwoColumns';

import { selectIsUserLogged } from 'redux/verifyUser/selectors';

import Properties from './Properties';
import SignedIn from './SignedIn';

import homeImg from 'images/background/home.jpg';
import LoanNumberForm from './LoanNumberForm';
import { Divider } from 'antd';
import { selectShowNotification } from 'redux/messages/selectors';
import { PlainNotification } from 'components/common/Alert';

function Home() {
  const isUserLogged = useSelector(selectIsUserLogged);
  const notification = useSelector(selectShowNotification);

  if (isUserLogged) {
    return <SignedIn />;
  }

  return (
    <Centered>
      <TwoColumns imageSrc={homeImg} imageDirection="left" md={14}>
        {isUserLogged ? (
          <SignedIn />
        ) : (
          <>
            <PlainNotification notification={notification} />
            <LoanNumberForm />
            <Divider />
            <Properties />
          </>
        )}
      </TwoColumns>
    </Centered>
  );
}

export default memo(Home);
