import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserTypes } from 'redux/verifyUser/actions';
import { selectUserTypesData } from 'redux/verifyUser/selectors';

import UserSelection from './UserSelection';

function UserTypeList() {
  const dispatch = useDispatch();
  const userTypesData = useSelector(selectUserTypesData);

  useEffect(() => {
    if (!userTypesData) {
      dispatch(getUserTypes());
    }
  }, [dispatch]);

  return <UserSelection />;
}

export default memo(UserTypeList);
