import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef
} from 'react';

import uuidv4 from 'uuid/v4';
import { noop, range, omit } from './utils';
import keyboardEventPolyfill from './polyfills';
import { EffectReducer, StateReducer, useBireducer } from 'react-use-bireducer';
import {
  DISABLE_CHAR_IN_MASK,
  HANDLER_KEYS,
  IGNORED_META_KEYS,
  NO_EFFECTS,
  NUMBER_CHAR_IN_MASK,
  PROP_KEYS,
  TEXT_CHAR_IN_MASK
} from './constants.js';
import FormattedInput from './FormattedInput';

export const defaultProps = {
  ref: { current: [] },
  length: 3,
  validate: /^[a-zA-Z0-9]$/,
  mask: '',
  format: key => key,
  formatAriaLabel: (idx, codeLength) => `pin code ${idx} of ${codeLength}`,
  onResolveKey: noop,
  onRejectKey: noop,
  onChange: noop,
  onComplete: noop,
  debug: false
};

export function defaultState(props) {
  return {
    focusIdx: 0,
    codeLength: props.codeLength,
    isKeyAllowed: isKeyAllowed(props.validate),
    fallback: null
  };
}

export function getPrevFocusIdx(currFocusIdx) {
  return Math.max(0, currFocusIdx - 1);
}

export function getNextFocusIdx(currFocusIdx, lastFocusIdx) {
  if (lastFocusIdx === 0) return 0;
  return Math.min(currFocusIdx + 1, lastFocusIdx - 1);
}

export function isKeyAllowed(predicate) {
  return key => {
    if (!key) return false;
    if (key.length > 1) return false;
    if (typeof predicate === 'string') return predicate.split('').includes(key);
    if (predicate instanceof Array) return predicate.includes(key);
    if (predicate instanceof RegExp) return predicate.test(key);
    return predicate(key);
  };
}

export function pasteReducer(state, idx, val) {
  const areAllKeysAllowed = val
    .split('')
    .slice(0, state.codeLength)
    .every(state.isKeyAllowed);

  if (!areAllKeysAllowed) {
    return [
      state,
      [
        { type: 'set-input-val', idx: state.focusIdx, val: '' },
        { type: 'reject-key', idx, key: val },
        { type: 'handle-code-change' }
      ]
    ];
  }

  const pasteLen = Math.min(val.length, state.codeLength - state.focusIdx);
  const nextFocusIdx = getNextFocusIdx(
    pasteLen + state.focusIdx - 1,
    state.codeLength
  );
  const effects = range(0, pasteLen).flatMap(idx => [
    {
      type: 'set-input-val',
      idx: idx + state.focusIdx,
      val: val[idx]
    },
    {
      type: 'resolve-key',
      idx: idx + state.focusIdx,
      key: val[idx]
    }
  ]);

  if (state.focusIdx !== nextFocusIdx) {
    effects.push({ type: 'focus-input', idx: nextFocusIdx });
  }

  effects.push({ type: 'handle-code-change' });

  return [{ ...state, focusIdx: nextFocusIdx }, effects];
}

export const stateReducer = (state, action) => {
  switch (action.type) {
    case 'handle-key-down': {
      switch (action.key) {
        case 'Unidentified':
        case 'Process': {
          return [
            { ...state, fallback: { idx: state.focusIdx, val: action.val } },
            NO_EFFECTS
          ];
        }

        case 'Dead': {
          return [
            state,
            [
              { type: 'set-input-val', idx: state.focusIdx, val: '' },
              { type: 'reject-key', idx: state.focusIdx, key: action.key },
              { type: 'handle-code-change' }
            ]
          ];
        }

        case 'ArrowLeft': {
          const prevFocusIdx = getPrevFocusIdx(state.focusIdx);
          return [
            { ...state, focusIdx: prevFocusIdx },
            [{ type: 'focus-input', idx: prevFocusIdx }]
          ];
        }

        case 'ArrowRight': {
          const nextFocusIdx = getNextFocusIdx(
            state.focusIdx,
            state.codeLength
          );
          return [
            { ...state, focusIdx: nextFocusIdx },
            [{ type: 'focus-input', idx: nextFocusIdx }]
          ];
        }

        case 'Delete':
        case 'Backspace': {
          return [
            state,
            [
              { type: 'handle-delete', idx: state.focusIdx },
              { type: 'handle-code-change' }
            ]
          ];
        }

        default: {
          if (!state.isKeyAllowed(action.key)) {
            return [
              state,
              [{ type: 'reject-key', idx: state.focusIdx, key: action.key }]
            ];
          }

          const nextFocusIdx = getNextFocusIdx(
            state.focusIdx,
            state.codeLength
          );
          return [
            { ...state, focusIdx: nextFocusIdx },
            [
              { type: 'set-input-val', idx: state.focusIdx, val: action.key },
              { type: 'resolve-key', idx: state.focusIdx, key: action.key },
              { type: 'focus-input', idx: nextFocusIdx },
              { type: 'handle-code-change' }
            ]
          ];
        }
      }
    }

    case 'handle-key-up': {
      if (!state.fallback) {
        return [state, NO_EFFECTS];
      }

      const nextState = { ...state, fallback: null };
      const effects = [];
      const { idx, val: prevVal } = state.fallback;
      const val = action.val;

      if (prevVal === '' && val === '') {
        effects.push(
          { type: 'handle-delete', idx },
          { type: 'handle-code-change' }
        );
      } else if (val !== '') {
        return pasteReducer(nextState, idx, val);
      }

      return [nextState, effects];
    }

    case 'handle-paste': {
      return pasteReducer(state, action.idx, action.val);
    }

    case 'focus-input': {
      return [
        { ...state, focusIdx: action.idx },
        [{ type: 'focus-input', idx: action.idx }]
      ];
    }

    default: {
      return [state, NO_EFFECTS];
    }
  }
};

export function useEffectReducer({ refs, codeLength, ...props }) {
  return useCallback(
    effect => {
      switch (effect.type) {
        case 'focus-input': {
          refs.current[effect.idx].focus();
          break;
        }

        case 'set-input-val': {
          const val = props.format(effect.val);
          refs.current[effect.idx].value = val;
          break;
        }

        case 'resolve-key': {
          refs.current[effect.idx].setCustomValidity('');
          props.onResolveKey(effect.key, refs.current[effect.idx]);
          break;
        }

        case 'reject-key': {
          refs.current[effect.idx].setCustomValidity('Invalid key');
          props.onRejectKey(effect.key, refs.current[effect.idx]);
          break;
        }

        case 'handle-delete': {
          const prevVal = refs.current[effect.idx].value;
          refs.current[effect.idx].setCustomValidity('');
          refs.current[effect.idx].value = '';

          if (!prevVal) {
            const prevIdx = getPrevFocusIdx(effect.idx);
            refs.current[prevIdx].focus();
            refs.current[prevIdx].setCustomValidity('');
            refs.current[prevIdx].value = '';
          }

          break;
        }

        case 'handle-code-change': {
          const dir = (
            props.dir ||
            document.documentElement.getAttribute('dir') ||
            'ltr'
          ).toLowerCase();
          const codeArr = refs.current.map(r => r.value.trim());
          const code = (dir === 'rtl' ? codeArr.reverse() : codeArr).join('');
          props.onChange(code);

          if (code.length === codeLength) {
            const maskIndexes = [...props.mask.matchAll(validCharacterRegExp)].map(e => e.index);
            const completeResult = props.mask.split('');
            code.split('').forEach((element, index) => {
              completeResult[maskIndexes[index]] = element;
            });

            props.onComplete(completeResult.join(''));
          }

          break;
        }

        default: {
          break;
        }
      }
    },
    [props, refs]
  );
}

export const validCharacterRegExp = new RegExp(
  `${TEXT_CHAR_IN_MASK}|${NUMBER_CHAR_IN_MASK}`,
  'g'
);

const FormattedCode = forwardRef((customProps, fwdRef) => {
  const props = { ...defaultProps, ...customProps };
  const { autoFocus, formatAriaLabel, mask } = props;
  const codeLength = (mask.match(validCharacterRegExp) || []).length;

  const inputProps = omit([...PROP_KEYS, ...HANDLER_KEYS], props);
  const refs = useRef([]);
  const effectReducer = useEffectReducer({ refs, codeLength, ...props });
  const dispatch = useBireducer(
    stateReducer,
    effectReducer,
    defaultState({ ...props, codeLength })
  )[1];

  useImperativeHandle(fwdRef, () => refs.current, [refs]);

  const handleFocus = idx => {
    return function () {
      dispatch({ type: 'focus-input', idx });
    };
  };

  const handleKeyDown = idx => {
    return function (evt) {
      const key = keyboardEventPolyfill.getKey(evt.nativeEvent);
      if (
        !IGNORED_META_KEYS.includes(key) &&
        !evt.ctrlKey &&
        !evt.altKey &&
        !evt.metaKey &&
        evt.nativeEvent.target instanceof HTMLInputElement
      ) {
        evt.preventDefault();
        dispatch({
          type: 'handle-key-down',
          idx,
          key,
          val: evt.nativeEvent.target.value
        });
      }
    };
  };

  const handleKeyUp = idx => {
    return function (evt) {
      if (evt.nativeEvent.target instanceof HTMLInputElement) {
        dispatch({
          type: 'handle-key-up',
          idx,
          val: evt.nativeEvent.target.value
        });
      }
    };
  };

  const handlePaste = idx => {
    return function (evt) {
      evt.preventDefault();
      const val = evt.clipboardData.getData('Text');
      dispatch({ type: 'handle-paste', idx, val });
    };
  };

  const setRefAtIndex = idx => {
    return function (ref) {
      if (ref) {
        refs.current[idx] = ref;
      }
    };
  };

  const hasAutoFocus = idx => {
    return Boolean(idx === 0 && autoFocus);
  };

  const renderInput = ({
    idx,
    maskItem,
    type = 'text',
    total,
    ...customProps
  }) => {
    return (
      <FormattedInput
        type={type}
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="off"
        inputMode="text"
        ariaDisabled={customProps.disabled ? 'true' : undefined}
        ariaLabel={formatAriaLabel(idx + 1, total)}
        ariaReadonly={customProps.readOnly ? 'true' : undefined}
        ariaRequired="true"
        {...customProps}
        key={`input-${idx}`}
        data-index={idx}
        data-mask={maskItem}
        ref={setRefAtIndex(idx)}
        autoFocus={hasAutoFocus(idx)}
        onFocus={handleFocus(idx)}
        onKeyDown={handleKeyDown(idx)}
        onKeyUp={handleKeyUp(idx)}
        onPaste={handlePaste(idx)}
      />
    );
  };

  if (!mask) {
    return (
      <span style={{ color: 'red' }}>
        Missing mask property to render code component successfully.
      </span>
    );
  }

  let maskIndex = -1;
  let maskItems = mask.split('');

  return (
    <>
      {maskItems.map(item => {
        switch (item) {
          case DISABLE_CHAR_IN_MASK:
            return (
              <FormattedInput
                type="password"
                key={uuidv4()}
                disabled
                placeholder="·"
              />
            );
          case TEXT_CHAR_IN_MASK:
          case NUMBER_CHAR_IN_MASK:
            if (maskIndex < codeLength) {
              maskIndex++;
            }

            return renderInput({
              idx: maskIndex,
              maskItem: item,
              total: codeLength
            });
          default:
            return (
              <span key={uuidv4()} style={{ margin: '0 8px' }}>
                {item}
              </span>
            );
        }
      })}
    </>
  );
});

export default FormattedCode;
