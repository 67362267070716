/// When an exception is thrown
export const EXCEPTION_NOMENCLATURE = 0;

/// When a validation error is thrown
export const VALIDATION_NOMENCLATURE = 1;

/// When there is an error in the data; but not detected in the validation; and the action cannot run correctly.
export const WRONG_DATA_NOMENCLATURE = 2;

/// When the action cannot be executed because the data is locked.
export const LOCKING_NOMENCLATURE = 3;

/// When any property of an object make impossible the execution of an action
export const WRONG_PROPERTIES_NOMENCLATURE = 4;

export const DUPLICATE_FOUND_NOMENCLATURE = 5;

/// Error
export const TEXT_ERROR_NOMENCLATURE = 6;

/// Unknown Error
export const UNKNOWN_ERROR_NOMENCLATURE = 7;

/// Not Found
export const NOT_FOUND_NOMENCLATURE = 8;

/// File lost
export const FILE_LOST_NOMENCLATURE = 9;

/// Status Not Allowed
export const STATUS_NOT_ALLOWED_NOMENCLATURE = 10;

/// Unstable Status
export const UNSTABLE_STATUS_NOMENCLATURE = 11;

/// Unstable Status
export const FILE_ERROR_NOMENCLATURE = 12;

/// Unstable Status
export const DATA_BASE_ERROR_NOMENCLATURE = 13;

/// Unstable Status
export const NO_CONTENT_NOMENCLATURE = 14;

/// Unstable Status
export const PROPERTIES_MISMATCH_NOMENCLATURE = 15;

/// mistmatch error
export const MULTIPLE_NOMENCLATURE = 16;
