import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { Spin } from 'antd';

import { selectGetResourceIsFetching } from 'redux/app/selectors';

import { FONT_SIZE_SMALL } from 'theme/variables';
import { RESOURCE_DISCLOSURE } from 'redux/agreements/constants';

const ConsentMainContainer = styled.div`
  font-size: ${FONT_SIZE_SMALL};
  width: 100%;

  p {
    text-align: justify;
  }
`;

function Consent() {
  const getResourceIsFetching = useSelector(selectGetResourceIsFetching);

  const resource = sessionStorage.getItem(RESOURCE_DISCLOSURE);

  return (
    <ConsentMainContainer>
      <Spin spinning={getResourceIsFetching} tip="Loading legal disclosure...">
        {parse(resource)}
      </Spin>
    </ConsentMainContainer>
  );
}

export default memo(Consent);
