import React from 'react';
import styled from 'styled-components';

import { Menu as MenuAnt } from 'antd';
import {
  FONT_SIZE_SMALL,
  FONT_COLOR,
  FONT_WEIGHT,
  ACTION_COLOR
} from 'theme/variables';

const Menu = styled(MenuAnt)`
  display: flex;
  justify-content: space-between;
  background: transparent;
  color: ${FONT_COLOR};
  font-size: ${FONT_SIZE_SMALL};
  border: none;

  &.ant-menu-horizontal {
    line-height: 23px;
  }

  &.ant-menu-horizontal > .ant-menu-item,
  &.ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: none;
  }

  &.ant-menu-inline.ant-menu-root.navbar-links .ant-menu-item {
    text-align: center;
    padding: 0;
    margin: 0;
  }
`;

export default Menu;
