import React, { memo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

import { Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { LogOutUserButton } from 'components/views/Main/utils';
import { Button } from 'components/common/Buttons';

import Centered from 'components/layouts/Centered';
import TwoColumns from 'components/common/Content/TwoColumns';

import {
  selectIsDealer,
  selectUserTypeSelected
} from 'redux/verifyUser/selectors';

import { SIZE_BETWEEN_ELEMENTS } from 'theme/variables';
import { USER_TYPE_LABELS } from 'redux/verifyUser/constants';
import { ROUTES } from 'consts/routes';
import homeImg from 'images/background/home.jpg';
import { SubTitle } from 'components/common/Text';

function SignedIn() {
  const dispatch = useDispatch();
  const userTypeSelected = useSelector(selectUserTypeSelected);
  const isDealer = useSelector(selectIsDealer);

  const handleSeeDocuments = () => {
    dispatch(replace(ROUTES.DOCUMENTS_LIST));
  };

  const DOCUMENT_LIST_LABEL = 'View documents';
  const userSelected = userTypeSelected
    ? USER_TYPE_LABELS[userTypeSelected]
    : null;

  const exitText = 'If you press Exit, the session will be closed.';

  return (
    <Centered>
      <TwoColumns
        imageSrc={homeImg}
        imageDirection="left"
        md={14}
        actions={
          <Space size={SIZE_BETWEEN_ELEMENTS}>
            {!isDealer && (
              <Button type="primary" onClick={handleSeeDocuments}>
                {DOCUMENT_LIST_LABEL}
              </Button>
            )}
            <LogOutUserButton type={isDealer ? 'primary' : 'secondary'} />
          </Space>
        }
      >
        <Text>
          {userSelected
            ? `You have been verified as ${USER_TYPE_LABELS[userTypeSelected]}.`
            : 'You have been verified.'}

          <SubTitle>
            {!isDealer &&
              `By selecting ${DOCUMENT_LIST_LABEL}, you can see the status of your
            documents. `}
            {exitText}
          </SubTitle>
        </Text>
      </TwoColumns>
    </Centered>
  );
}

export default memo(SignedIn);
