import { createSelector } from 'reselect';

const selectTokensDomain = () => state => state.get('tokens');
const selectToken = createSelector(selectTokensDomain(), tk => tk.toJS());

const selectIsFetchingValidatingCurrentToken = createSelector(
  selectToken,
  tk => tk.isFetchingValidatingCurrentToken
);
const selectCurrentToken = createSelector(selectToken, tk => tk.currentToken);
const selectUserToken = createSelector(selectToken, tk => tk.userToken);

export default selectToken;
export {
  selectIsFetchingValidatingCurrentToken,
  selectUserToken,
  selectCurrentToken,
};
