import React from 'react';
import { goBack, replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

import ButtonLink, { Button } from 'components/common/Buttons';

import { goToPreviousVerificationStep, logoutUser } from 'redux/app/actions';
import { resetDocumentSelected } from 'redux/activeDocument/actions';

import { ROUTES } from 'consts/routes';
import { BACK_TO_HOME } from 'redux/app/constants';
import {
  selectLoanDataToVerify,
  selectUserDataToVerify,
  selectUserTypeSelected
} from 'redux/verifyUser/selectors';
import { USER_TYPE_DEALER } from 'redux/verifyUser/constants';

import { selectDocumentDataToVerify } from 'redux/activeDocument/selectors';

import { isEmpty } from 'lodash';

export function BackToHomeButton({
  type = 'primary',
  text = BACK_TO_HOME,
  isLink = false
}) {
  const Component = isLink ? ButtonLink : Button;
  const dispatch = useDispatch();

  function handleOnClick() {
    dispatch(replace(ROUTES.HOME));
  }

  return (
    <Component type={type} onClick={handleOnClick}>
      {text}
    </Component>
  );
}

export function GoBackButton({
  type = 'secondary',
  text = 'Go back',
  isLink = false
}) {
  const Component = isLink ? ButtonLink : Button;
  const dispatch = useDispatch();

  function handleOnClick() {
    dispatch(goBack());
  }

  return (
    <Component type={type} onClick={handleOnClick}>
      {text}
    </Component>
  );
}

export function GoToDocumentList({
  type = 'primary',
  text = 'Close',
  isLink = false,
  disabled = false
}) {
  const Component = isLink ? ButtonLink : Button;
  const dispatch = useDispatch();

  function handleOnClick() {
    // dispatch(resetDocumentSelected());
    // dispatch(replace(ROUTES.DOCUMENTS_LIST));
    dispatch(replace(ROUTES.DOCUMENTS_PENDING_SIGNATURE));
  }

  return (
    <Component type={type} onClick={handleOnClick} disabled={disabled}>
      {text}
    </Component>
  );
}

export function LogOutUserButton({
  type = 'primary',
  text = 'Exit',
  isLink = false
}) {
  const Component = isLink ? ButtonLink : Button;
  const dispatch = useDispatch();

  function handleOnClick() {
    dispatch(logoutUser());
  }

  return (
    <Component type={type} onClick={handleOnClick}>
      {text}
    </Component>
  );
}

export function GoPreviousVerificationStepButton({
  type = 'secondary',
  text,
  isLink = false,
  disable = false
}) {
  const dispatch = useDispatch();

  const documentDataToVerify = useSelector(selectDocumentDataToVerify);
  const userDataToVerify = useSelector(selectUserDataToVerify);
  const loanDataToVerify = useSelector(selectLoanDataToVerify);

  const Component = isLink ? ButtonLink : Button;

  function handleOnClick() {
    dispatch(goToPreviousVerificationStep());
  }

  if (
    isEmpty(documentDataToVerify) &&
    isEmpty(userDataToVerify) &&
    isEmpty(loanDataToVerify) &&
    isEmpty(text)
  ) {
    text = 'Go home';
  } else {
    if (isEmpty(text)) {
      text = 'Back';
    }
  }

  return (
    <Component type={type} onClick={handleOnClick} disabled={disable}>
      {text}
    </Component>
  );
}
