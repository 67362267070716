import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { FilePdfOutlined } from '@ant-design/icons';

import { Avatar, Empty } from 'antd';
import Spin from 'components/common/Spin';
import List, { ListItem } from 'components/common/List';
import { Title, SubTitle, Item } from 'components/common/Text';
import Divider from 'components/common/Divider';
import {
  GoPreviousVerificationStepButton,
  LogOutUserButton
} from 'components/views/Main/utils';
import TwoColumns from 'components/common/Content/TwoColumns';
import Centered from 'components/layouts/Centered';

import { useGetData } from 'hooks/data';

import { getDocumentListToSelect } from 'redux/documentList/actions';
import { setDocumentSelected } from 'redux/activeDocument/actions';

import {
  selectDocumentListToSign,
  selectDocumentListToView,
  selectIsFetchingDocumentListToSelect
} from 'redux/documentList/selectors';

import documentsImg from 'images/background/documents.jpg';
import { replace } from 'connected-react-router';
import { ROUTES } from 'consts/routes';

function RenderList({ title, subtitle, data, showInformedConsent }) {
  const dispatch = useDispatch();

  const handleSelectItem = (e, document) => {
    dispatch(setDocumentSelected({ showInformedConsent, document }));
    dispatch(replace(ROUTES.VERIFY_DOCUMENT));
  };

  return (
    <List
      header={
        <>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </>
      }
      dataSource={data}
      renderItem={item =>
        item ? (
          <ListItem
            key={item.id}
            onClick={handleSelectItem}
            params={{ documentId: item.id, documentType: item.type }}
          >
            <List.Item.Meta
              avatar={<Avatar shape="square" icon={<FilePdfOutlined />} />}
              title={
                item.name ? (
                  <Item style={{ padding: 0 }}>{item.name}</Item>
                ) : null
              }
              description={
                item.description
                  ? item.description && <SubTitle>{item.description}</SubTitle>
                  : null
              }
            />
          </ListItem>
        ) : null
      }
    />
  );
}

export function DocumentListContainer({ title, actions, children }) {
  return (
    <Centered>
      <TwoColumns
        imageSrc={documentsImg}
        md={18}
        title={title}
        actions={actions}
        imageDirection="30% 30%"
      >
        {children}
      </TwoColumns>
    </Centered>
  );
}

DocumentListContainer = memo(DocumentListContainer);

function DocumentList() {
  const [
    documentListToSign,
    isFetchingDocumentListToSelect
  ] = useGetData(
    selectDocumentListToSign,
    selectIsFetchingDocumentListToSelect,
    getDocumentListToSelect,
    [{}]
  );

  const documentListToView = useSelector(selectDocumentListToView);

  const haveDocumentListToSign = !isEmpty(documentListToSign);
  const haveDocumentListToView = !isEmpty(documentListToView);

  if (isFetchingDocumentListToSelect) {
    return (
      <DocumentListContainer>
        <Spin spinning />
      </DocumentListContainer>
    );
  }

  if (!haveDocumentListToSign && !haveDocumentListToView) {
    return (
      <DocumentListContainer
        actions={<LogOutUserButton type="primary" text="Close" />}
      >
        <Empty description={'You do not have documents to be signed.'} />
      </DocumentListContainer>
    );
  }

  return (
    <DocumentListContainer
      actions={<LogOutUserButton type="secondary" text="Close" />}
    >
      {haveDocumentListToSign && (
        <RenderList
          title="Documents that are pending signature."
          subtitle="Select a document from the following list to proceed to signing."
          data={documentListToSign}
          showInformedConsent={true}
        />
      )}
      {haveDocumentListToSign && haveDocumentListToView && <Divider />}
      {haveDocumentListToView && (
        <RenderList
          title="Signed documents."
          subtitle="You can view the signed documents by selecting them from the list."
          data={documentListToView}
          showInformedConsent={false}
        />
      )}
    </DocumentListContainer>
  );
}

export default memo(DocumentList);
