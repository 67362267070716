import React from 'react';
import styled from 'styled-components';

import {
  FONT_COLOR,
  FONT_COLOR_SOFT,
  FONT_SIZE,
  FONT_SIZE_SMALL,
  FONT_WEIGHT,
  MAIN_COLOR,
  LABEL_DARK_THEME,
  VALUE_DARK_THEME,
  BORDER_LIGHT,
  LABEL_FONT_COLOR,
  FONT_COLOR_LIGHT,
  FONT_SIZE_SMALLEST
} from 'theme/variables';

export const Colored = styled.span`
  color: ${props => (props.color ? props.color : MAIN_COLOR)};
  font-weight: ${props => (props.lighter ? 'normal' : FONT_WEIGHT)};
`;

export const Title = styled.span`
  font-size: ${props => (props.small ? FONT_SIZE_SMALL : FONT_SIZE)};
  font-weight: ${props => (props.soft ? 'normal' : FONT_WEIGHT)};
  text-align: ${props => (props.align ? props.align : 'left')};
  display: ${props => (props.display ? props.display : 'block')};
  color: ${props => (props.color ? props.color : FONT_COLOR)};
  white-space: normal;
`;

export const Copyright = styled.span`
  font-size: ${FONT_SIZE_SMALLEST};
  text-align: center;
  color: ${FONT_COLOR_LIGHT};
  display: block;
`;

export const SubTitle = styled.span`
  font-size: ${FONT_SIZE_SMALL};
  color: ${FONT_COLOR_SOFT};
  font-weight: normal;
  text-align: left;
  display: block;
  white-space: normal;
`;

export const HeadingTitle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  width: 100%;

  &.borderless {
    position: relative;
    padding: 0 0 10px;
    text-align: left;

    .heading-title-name {
      float: none;
    }
  }

  &.sider-head {
    position: relative;
    padding: 0 0 20px;
    margin-bottom: 20px;
    text-align: left;

    .anticon-close {
      position: absolute;
      right: 0;
      top: 2px;
      color: ${MAIN_COLOR};
      cursor: pointer;
      padding: 10px;
    }

    .link {
      display: block;
      text-align: left;
    }

    .heading-title-name {
      float: none;
    }
  }

  .heading-title-name {
    font-weight: ${FONT_WEIGHT};
    color: rgba(77, 77, 77, 0.8);
    float: right;
    word-break: break-word;
    width: 30%;
    text-align: right;
    font-size: ${FONT_SIZE};
  }

  .heading-subtitle {
    display: block;
    font-size: 11px;
    color: rgba(77, 77, 77, 0.9);
    font-weight: normal;
  }

  @media (max-width: 767px) {
    .heading-title-name {
      float: none;
      width: 100%;
      text-align: left;
      display: block;
    }
  }
`;

export const ContentTitle = styled.div`
  font-weight: ${FONT_WEIGHT};
  color: rgba(77, 77, 77, 0.8);
  font-size: ${FONT_SIZE};

  .heading-title-name {
    font-weight: ${FONT_WEIGHT};
    color: rgba(77, 77, 77, 0.8);
    float: right;
    word-break: break-word;
    width: 30%;
    text-align: right;
    font-size: ${FONT_SIZE};
  }

  .heading-subtitle {
    display: block;
    font-size: 11px;
    color: rgba(77, 77, 77, 0.9);
    font-weight: normal;
  }

  @media (max-width: 767px) {
    .heading-title-name {
      float: none;
      width: 100%;
      text-align: left;
      display: block;
    }
  }
`;

export const PropertyContainer = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  align-items: baseline;
  color: ${props =>
    props.theme && props.theme === 'dark'
      ? LABEL_DARK_THEME
      : LABEL_FONT_COLOR};
  font-size: ${props => (props.size ? props.size : FONT_SIZE)};
  margin-bottom: 5px;
  padding-top: 5px;

  & + & {
    border-top: ${BORDER_LIGHT};
  }
`;

export const Yield = styled.div`
  margin-left: ${props =>
    props.title && props.flexDirection === 'row' ? '5px' : 0};
  color: ${props =>
    props.theme && props.theme === 'dark' ? VALUE_DARK_THEME : FONT_COLOR};
  font-size: ${props => (props.size ? props.size : { FONT_SIZE })};
  max-width: ${props => (props.limited ? '300px' : 'auto')};
  font-weight: ${FONT_WEIGHT};
`;

export const Item = ({ children, ...props }) => {
  return (
    <PropertyContainer {...props}>
      {props.title
        ? `${props.title}${
            children && props.flexDirection === 'row' ? ':' : ''
          }`
        : ''}
      <Yield {...props}>{children}</Yield>
    </PropertyContainer>
  );
};

export const InformationTitle = styled.div`
  color: ${props => props.color || LABEL_FONT_COLOR};
  font-weight: ${props => (props.color ? 'normal' : FONT_WEIGHT)};
  font-size: ${FONT_SIZE_SMALL};
  text-align: justify;
`;

export default HeadingTitle;
